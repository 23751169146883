import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import background from '../../../assets/img/bg/01.jpg'

class Clothingtype extends Component {
  render() {
    return (
        <section className="room-type-section pt-115 pb-115" style={{backgroundImage: `url(${ background})` }}>
          <Tab.Container defaultActiveKey="relex">
              <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-lg-left text-center">
                  <span className="title-tag">clothing type</span>
                  <h2>Inspired Looks</h2>
                </div>
              </div>
              <div className="col-lg-6">
              <Nav variant="pills" className="room-filter nav nav-pills justify-content-center justify-content-lg-end">

                <Nav.Item>
                <Nav.Link eventKey="shirt">Shirt</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="jackets">Jackets</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="womens">Womens</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link eventKey="mens">Mens</Nav.Link>
                </Nav.Item>

                </Nav>
                </div>
            </div>
            <Tab.Content className="mt-65">
                <Tab.Pane eventKey="shirt">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-8">
                        <div className="row">
                            <div className="col-12">
                            <div className="room-box extra-wide">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/04.jpg)'}} />
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />03 Shirts</span>
                                <h3><Link disabled to="/room-details">Shirt Hall Of Fame</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/01.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />05 Shirts</span>
                                <h3><Link to="/room-details">Pendora Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/02.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />10 Shirts</span>
                                <h3><Link to="/room-details">Pacific Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="room-box extra-height">
                            <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/03.jpg)'}}>
                            </div>
                            <div className="room-content">
                            <span className="room-count"><i className="fal fa-th" />12 Shirts</span>
                            <h3><Link to="/room-details">Junior Line</Link></h3>
                            </div>
                            <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </Tab.Pane>

                <Tab.Pane eventKey="jackets">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-4">
                        <div className="room-box extra-height">
                            <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/03.jpg)'}}>
                            </div>
                            <div className="room-content">
                            <span className="room-count"><i className="fal fa-th" />12 Jackets</span>
                            <h3><Link to="/room-details">Junior Line</Link></h3>
                            </div>
                            <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                        </div>
                        </div>
                        <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/02.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />10 Jackets</span>
                                <h3><Link to="/room-details">Pacific Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/01.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />05 Jackets</span>
                                <h3><Link to="/room-details">Pendora Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="room-box extra-wide">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/04.jpg)'}} />
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />03 Jackets</span>
                                <h3><Link to="/room-details">Jackets Hall Of Fame</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </Tab.Pane>

                <Tab.Pane eventKey="womens">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-4">
                        <div className="room-box extra-height">
                            <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/03.jpg)'}}>
                            </div>
                            <div className="room-content">
                            <span className="room-count"><i className="fal fa-th" />12 Womens</span>
                            <h3><Link to="/room-details">Junior Line</Link></h3>
                            </div>
                            <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                        </div>
                        </div>
                        <div className="col-lg-8">
                        <div className="row">
                            <div className="col-12">
                            <div className="room-box extra-wide">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/04.jpg)'}} />
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />03 Womens</span>
                                <h3><Link to="/room-details">Womens Hall Of Fame</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/01.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />05 Womens</span>
                                <h3><Link to="/room-details">Pendora Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/02.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />10 Womens</span>
                                <h3><Link to="/room-details">Pacific Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </Tab.Pane>
                <Tab.Pane eventKey="mens">
                    <div className="room-items">
                    <div className="row">
                        <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/01.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />05 Mens</span>
                                <h3><Link to="/room-details">Pendora Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                            <div className="room-box">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/02.jpg)'}}>
                                </div>
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />10 Mens</span>
                                <h3><Link to="/room-details">Pacific Line</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="room-box extra-wide">
                                <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/04.jpg)'}} />
                                <div className="room-content">
                                <span className="room-count"><i className="fal fa-th" />03 Mens</span>
                                <h3><Link to="/room-details">Mens Hall Of Fame</Link></h3>
                                </div>
                                <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="room-box extra-height">
                            <div className="room-bg" style={{backgroundImage: 'url(assets/img/room-type/03.jpg)'}}>
                            </div>
                            <div className="room-content">
                            <span className="room-count"><i className="fal fa-th" />12 Mens</span>
                            <h3><Link to="/room-details">Junior Line</Link></h3>
                            </div>
                            <Link to="/room-details" className="room-link"><i className="fal fa-arrow-right" /></Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </Tab.Pane>

            </Tab.Content>
            </div>
            </Tab.Container>
        </section>
    );
  }
}

export default Clothingtype;
