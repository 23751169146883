import React, { useState, useEffect } from 'react'
import Layout from '../core/Layout'
// import Sidebar from "../components/sidebar/Sidebar";
// import ProductCreate from './ProductCreate'
import ProductRead from './ProductRead'
import ProductUpdate from './ProductUpdate'
import ProductDelete from './ProductDelete'
import ProductInventory from './ProductInventory'
import schema from './schema'

const Product = ({ match }, ...props) => {
    const [productUrl, setProductUrl] = useState("")
    const [rawProductResult, setRawProductResult] = useState([])
    const [values, setValues] = useState({
        text: "",
        error: "",
        success: "",
        loading: false,
        showText: false
    })

    let {
        text,
        error,
        success,
        loading,
        showText
    } = values

    const getProductData = async (productUrl) => {
        console.log(`Made it to getProductData!  Expecting an ebay url...`, productUrl)
        if(!productUrl) { console.log(`Null detected! getProductData productUrl...`); return ""}
        try {
            let endpoint = `${process.env.REACT_APP_API}/product/getproductbyurl`
            let headers = { Accept: "application/json", "Content-Type": "application/json"}
            let body = JSON.stringify( {url: productUrl} )
            let method = `POST`
            let result = await fetch(endpoint, { method, headers, body })
            result = await result.json()
            setRawProductResult(result)
        } catch (e) { console.log(`Got an error in getProductData...`, e)}
    }
    
    // init and useEffect
    const init = productUrl => {
        console.log(`Product page productUrl...`, productUrl)
        setProductUrl(productUrl)
        getProductData(productUrl)
        // getMemberSellerLimits(memberId)
        // getUserData(memberId)
        // getMemberInventory(memberId)
    };


    // useEffect(() => {
    //     init(match.params.productUrl ? decodeURIComponent(match.params.productUrl) : "");
    // }, []);

    const handleChange = name => e => {
        console.log(`Made it to handleChange!`)
        let value = e.target.value
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleClick = e => {
        e.preventDefault()
        setValues({
            ...values,
            showText: true
        })
    }

    const handleClear = e => {
        e.preventDefault()
        setValues({
            ...values,
            showText: false,
            text: ""
        })
    }

    const showProcessedText = text => {
        return(
            <>
                <div className="container">
                    <p>Here is your text</p>
                    <pre>
                        <code>
                            {JSON.stringify(values.text)}
                        </code>
                    </pre>
                </div>
            </>
        )
    }

    const exampleForm = () => {
        return(
            <>
                <form>
                    <div className="form-group">
                        <input
                            className="form-control" 
                            value={text}
                            onChange={handleChange("text")}
                        />
                    </div>
                    <div className="form-group">
                        <div className="btn-group">
                            <button className="btn btn-primary" onClick={handleClick}>Submit</button>
                            <button className="btn btn-danger" onClick={handleClear}>Clear</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    const introText = () => {
        return(
            <>
                <div className="container-fluid" style={{}}>
                    <div className="row">
                        <div className="col-md-2 col-sm-12 col-xs-12">
                            <div style={{ overflowY: "auto" }}>
                            {/* <Sidebar /> */}
                            </div>
                        </div>
                        <ProductInventory />
                        
                    </div>
                </div>
            </>
        )
    }

    return(
        <>
            <Layout>
                <div className="container-fluid" style={{}}>
                    <div className="row">
                        <div className="col-12">
                            <h1>Product page!</h1>
                            {introText()}
                        </div>
                    </div>
                    <div className="row">
                        
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Product