import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { Link, Redirect } from "react-router-dom";
import { read, 
    listRelated, 
    processSubscriptionPayment,
    processPayment,
    getBraintreeClientToken,
    createOrder,
    getOnlyJuices,
    getOnlySmoothies,
    getOnlyHerbalTeas
 } from "./apiCore";
 import { emptyCart } from "./cartHelpers";
import Card from "./Card";
import { 
    Button, 
    Form,
    FormGroup, 
    Label, 
    Input, 
    FormText, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Container,
    Row,
    Col,
    CardBody
 } from 'reactstrap';
import Image from 'react-bootstrap/Image'
import { isAuthenticated } from "../api/auth"
import DropIn from "braintree-web-drop-in-react"

const Membership = props => {
    const [selectedJuices, setSelectedJuices] = useState([])
    const [selectedSmoothies, setSelectedSmoothies] = useState([])
    const [selectJuiceValue, setSelectJuiceValue] = useState({})
    const [selectSmoothieValue, setSelectSmoothieValue] = useState({})
    const [selectedTeas, setSelectedTeas] = useState([])
    const [selectTeaValue, setSelectTeaValue] = useState({})
    const [products, setProducts] = useState({})
    const [memberStatus, setMemberStatus] = useState({})
    const [relatedProduct, setRelatedProduct] = useState([])
    const [error, setError] = useState(false)
    const [plan, setPlan] = useState(0)
    const [values, setValues] = useState({
        weekly: '', 
        juice: '', 
        smoothie: '', 
        quantity: '',
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        email: "",
        password: "",
        error0: "",
        success: false
    })
    const [data, setData] = useState({
        loading: false,
        success: false,
        clientToken: null,
        error: "",
        instance: {},
        address: ""
    });

    const { 
        firstName, 
        lastName, 
        address1, 
        address2, 
        city, 
        state, 
        zipcode, 
        email, 
        password, 
        error0,
        weekly, 
        juice, 
        smoothie, 
        quantity
    } = values;

    const {
        clientToken
    } = data
    
  const [modal, setModal] = useState(false);
  const [modal0, setModal0] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [redirectUser, setRedirectUser] = useState(false)

  // for juices only
  const [juices, setJuices] = useState([])

  // for smoothies only
  const [smoothies, setSmoothies] = useState([])

  // for herbal teas only
  const [herbalTeas, setHerbalTeas] = useState([])

  const toggle = () => setModal(!modal);
  const toggle0 = () => setModal0(!modal0);
  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);

    const userId = isAuthenticated() && isAuthenticated().user._id;
    const token = isAuthenticated() && isAuthenticated().token;
    const user = isAuthenticated() && isAuthenticated().user



    const loadJuices = () => {
        getOnlyJuices()
        .then(data => {
            setJuices(data)
        })
        .catch((err) => console.log(err))
    }

    const loadSmoothies = () => {
        getOnlySmoothies()
        .then(data => {
            setSmoothies(data)
        })
        .catch((err) => console.log(err))
    }

    const loadHerbalTeas = () => {
        getOnlyHerbalTeas()
        .then(data => {
            setHerbalTeas(data)
        })
        .catch((err) => console.log(err))
    }

    const loadSingleProduct = productId => {
        read(productId).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setProducts(data);
                // fetch related products
                listRelated(data._id).then(data => {
                    if (data.error) {
                        setError(data.error0);
                    } else {
                        setRelatedProduct(data);
                    }
                });
            }
        });
    };

    // useEffect(() => {
    //     const productId = props.match.params.productId;
    //     loadSingleProduct(productId);
    // }, [props]);

    
    const getToken = (userId, token) => {
        getBraintreeClientToken(userId, token).then(data => {
            if (data.error) {
                setData({ ...data, error: data.error }); 
            } else {
                setData({ clientToken: data.clientToken });
            }
        });
    };

    useEffect(() => {
        loadJuices()
        loadSmoothies()
        loadHerbalTeas()
        getToken(userId, token);
        setData({
            firstName: user.firstName,
            lastName: user.lastName,
            address1: user.address1,
            address2: user.address2,
            city: user.city,
            state: user.state,
            zipcode: user.zipcode,
        })
    }, []);

    const handleAddress = event => {
        setData({ ...data, address: event.target.value });
    };

    const getTotal = () => {
        return products.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.price;
        }, 0);
    };

    const showCheckout = () => {
        return isAuthenticated() ? (
            <div>{showDropIn()}</div>
        ) : (
            <Link to="/signin">
                <button className="btn btn-primary">Sign in to subscribe</button>
            </Link>
        );
    };

    let deliveryAddress = data.address;

    const subscribe = () => {
        setData({ loading: true });
        // send the nonce to your server
        // nonce = data.instance.requestPaymentMethod()
        let nonce;
        let getNonce = data.instance
            .requestPaymentMethod()
            .then(data => {
                // console.log(data);
                nonce = data.nonce;
                // once you have nonce (card type, card number) send nonce as 'paymentMethodNonce'
                // and also total to be charged
                let amount
                if(plan === 0){ amount = 252 }
                if(plan === 1){ amount = 200 }
                if(plan === 2){ amount = 280 }
                if(plan === 3){ amount = 220 }

                console.log(`amount: `, amount)
                
                let address = `${user.firstName} ${user.lastName}
${user.address1}, ${user.address2}
${user.city}, ${user.state} ${user.zipcode}`

                console.log(`address: `, address)
                
                let creditCard = {
                    billingAddress: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    streetAddress: user.address1 + ', ' + user.address2,
                    locality: user.city,
                    region: user.state,
                    postalCode: user.zipcode
                    }
                }

                const paymentData = {
                    paymentMethodNonce: nonce,
                    amount: amount,
                    address: address,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    creditCard: creditCard,
                    planNumber: plan,
                    subscriptionAdminData: [...selectedJuices, ...selectedSmoothies, ...selectedTeas]

                };

                console.log(`paymentData: `, paymentData)

                processSubscriptionPayment(userId, token, paymentData)
                    .then(response => {
                        console.log(response);
                        setRedirectUser(true)
                        // empty cart
                        // create order
                        // redirect
                        

                    })
                    .catch(error => {
                        console.log(error);
                        
                        setData({ loading: false });
                    });
            })
            .catch(error => {
                // console.log("dropin error: ", error);
                setData({ ...data, error: error.message });
            });
    };

    
    const redirectUserFunction = () => {
        if(redirectUser === true) {
            return <Redirect to="/user/dashboard" />
        }
    }


    const showDropIn = () => (
        <div onBlur={() => setData({ ...data, error: "" })}>
            {data.clientToken !== null ? (
                <div>
                    {showSuccess()}
                    {/* {`current plan: ${plan}`} */}
                    <Row form>
            <Col md="12">
              <FormGroup>
                <Input                                     
                // onChange={handleChange("firstName")}
                className="form-control" 
                type="text" 
                // placeholder={`${user.firstName}`}
                value={user.firstName}
                disabled
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Input 
                className="form-control" 
                type="text" 
                placeholder="Last Name"
                // onChange={handleChange("lastName")}
                value={user.lastName}
                disabled
                />
              </FormGroup>
            </Col>
            
          </Row>
            <Row form>
              <Col md={7}>
                <FormGroup>
                    <Input 
                    type="text" 
                    value={user.address1}
                    // onChange={handleChange("address1")}
                    name="address1" 
                    id="address1"
                    placeholder="Address"
                    disabled
                    />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                    <Input 
                    type="text" 
                    value={user.address2}
                    // onChange={handleChange("address2")}
                    name="address2" 
                    id="address2"
                    placeholder="Apt."
                    disabled
                    />
                </FormGroup>
              </Col>
            </Row>
            
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={user.city}
                        // onChange={handleChange("city")}
                        name="city" 
                        id="city"                                            
                        placeholder="City"
                        disabled
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={user.state}
                        // onChange={handleChange("state")}
                        name="state" 
                        id="state"                                            
                        placeholder="State"
                        disabled
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={user.zipcode}
                        // onChange={handleChange("zipcode")}
                        name="zipcode" 
                        id="zipcode"
                        placeholder="Zip"
                        disabled
                        />
                    </FormGroup>
                </Col>
          </Row>

                    <DropIn
                        options={{
                            authorization: data.clientToken,
                            paypal: {
                                flow: "vault"
                            }
                        }}
                        onInstance={instance => (data.instance = instance)}
                    />
                    <button onClick={
                        subscribe} className="btn btn-success btn-block">
                        Pay
                    </button>
                </div>
            ) : null}
        </div>
    );

    const showError = error => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = success => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
            Thanks! Your payment was successful!
        </div>
    );

    const showLoading = loading =>
        loading && <h2 className="text-danger">Loading...</h2>;
          
    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const handleMembership = name => {
        console.log(`Pressed on subscription $${name}`)
        if(name === '252') {
            console.log(`inside 252 if statement`)
            // toggle() 
        }
        if(name === '200') {
            console.log(`inside 200 if statement`)
        }
        if(name === '280') {
            console.log(`inside 280 if statement`)
        }
        if(name === '220') {
            console.log(`inside 220 if statement`)
        }
        // // get from state the membership options...
        // // state vars... weekly, juice, smoothie, quantity
        // processSubscriptionPayment(userId, token, paymentData)
        // .then(response => {
        //     console.log(`handleMembership response: `, response)
        //     setMemberStatus(response)
        // })
        // .catch(err => console.log(err))

    }

    const subscriptionFirstGroup = () => {
        return(
            <>
               <div className="card-deck justify-content-center">
                    <div 
                    className="card  bg-secondary" 
                    style={{color: "white", width: "100%", height: "210"}}
                    height="210"
                    >
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-col">
                                <img 
                                className="card-img-top" 
                                src="membershipRow1.png" 
                                alt="Card image cap "
                                width="195"
                                height="210"
                                style={{width: "100%", height: "210" }}
                                 />        
                            </div>
                            <div className="d-flex flex-col">
                             <div 
                             className="card-body"
                             style={{fontSize: 10}}
                              >
                                    <h6 className="card-title">WEEKLY OPTION<br />$252</h6>
                                    <p className="card-text">-9 JUICES<br />OR<br/>-6 JUICES AND 3 SMOOTHIES</p>
                                    
                                    {modalSubscription0()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card  bg-secondary" style={{color: "white"}}>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-col">
                                <img 
                                className="card-img-top" 
                                src="membershipRow1.png" 
                                alt="Card image cap "
                                width="195"
                                height="210"
                                style={{width: "100%", height: "210" }}
                                 />        
                            </div>
                            <div className="d-flex flex-col">
                             <div 
                             className="card-body"
                             style={{fontSize: 10}}
                              >
                                    <h6 className="card-title">BI-WEEKLY OPTION<br />$200</h6>
                                    <p className="card-text">-12 JUICES/SMOOTHIE<br />(MIX/MATCH)</p>

                                    {modalSubscription1()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const plan0 = () => setPlan(0)
    const plan1 = () => setPlan(1)
    const plan2 = () => setPlan(2)
    const plan3 = () => setPlan(3)

    // const [selectedJuices, setSelectedJuices] = useState([])
    // const [selectedSmoothies, setSelectedSmoothies] = useState([])
    // const [selectJuiceValue, setSelectJuiceValue] = useState({})
    // const [selectSmoothieValue, setSelectSmoothieValue] = useState({})
    // const [selectedTeas, setSelectedTeas] = useState([])
    // const [selectTeaValue, setSelectTeaValue] = useState({})

    
        // more Juice related...
        const handleSelectJuices = (e) => {
            // let value = JSON.stringify(e.target.value)

            // the order
            // 1. Select event
            // 2. handleSelectJuices
            // 3. setSelectJuiceValue // probably needs to be deleted
            // 4. get prior array from selectedJuices


            let value = e.target.value
            // setSelectJuiceValue(value)
            console.log(`from handleSelectJuices, here is value: `, value)
            e.preventDefault()
            let priorArray = selectedJuices
            let newSelection = [value]
            let finalWrite = priorArray.concat(newSelection)
            console.log('inside handleSelectJuices... here is finalWrite: ', finalWrite)
            setSelectedJuices(finalWrite)
    
            // remove from prior list the selected item
        }
        const handleSelectSmoothies = (e) => {
            // let value = JSON.stringify(e.target.value)
            let value = e.target.value
            // setSelectSmoothieValue(value)
            console.log(`from handleSelectSmoothies, here is value: `, value)
            e.preventDefault()
            let priorArray = selectedSmoothies
            let newSelection = [value]
            let finalWrite = priorArray.concat(newSelection)
            console.log('inside handleSelectSmoothies... here is finalWrite: ', finalWrite)
            setSelectedSmoothies(finalWrite)
    
            // remove from prior list the selected item
        }
        const handleSelectTeas = (e) => {
            // let value = JSON.stringify(e.target.value)
            let value = e.target.value
            // setSelectTeaValue(value)
            console.log(`from handleSelectTeas, here is value: `, value)
            e.preventDefault()
            let priorArray = selectedTeas
            let newSelection = [value]
            let finalWrite = priorArray.concat(newSelection)
            console.log('inside handleSelectTeas... here is finalWrite: ', finalWrite)
            setSelectedTeas(finalWrite)
    
            // remove from prior list the selected item
        }
    
    
    const removeSelectedSmoothie = (smoothieStr) => {
        // let { juiceName, variationName } = juiceStr
        console.log('smoothieStr: ', smoothieStr)
        let oldArray = selectedSmoothies
        let newArray = oldArray.filter((item, i) => {
            if(item != smoothieStr) {
                return item
            }
        })
        setSelectedSmoothies(newArray)
    }
    
    const removeSelectedJuice = (juiceStr) => {
        // let { juiceName, variationName } = juiceStr
        console.log('juiceStr: ', juiceStr)
        let oldArray = selectedJuices
        let newArray = oldArray.filter((item, i) => {
            if(item != juiceStr) {
                return item
            }
        })
        setSelectedJuices(newArray)
    }
    
    const removeSelectedTea = (teaStr) => {
        // let { juiceName, variationName } = juiceStr
        console.log('teaStr: ', teaStr)
        let oldArray = selectedTeas
        let newArray = oldArray.filter((item, i) => {
            if(item != teaStr) {
                return item
            }
        })
        setSelectedTeas(newArray)
    }

    const showChosenJuices = () => {
        if(selectedJuices.length > 0) {
            return(
            <>
            {/* {selectedJuices.map((item, i) => {
                return(<p>{item.juiceName}</p>)
            })} */}
                <ul>
                    {selectedJuices.map((item, i) => {
                        console.log('what on earth is this value: ', item)
                        return(
                        // <p key={i}>{`${item.juiceName} - ${item.variationName}`}
                        <p key={i}>{item}
                            <span>{` `}
                                <button onClick={() => removeSelectedJuice(item)}>x</button>
                            </span>
                        </p>
                        )
                    })}
                </ul>
            </>
        )}
    }

    const showChosenSmoothies = () => {
        if(selectedSmoothies) {
            return(
            <>
            {/* {selectedJuices.map((item, i) => {
                return(<p>{item.juiceName}</p>)
            })} */}
                <ul>
                    {selectedSmoothies.map((item, i) => {
                        // console.log('what on earth is this value: ', item)
                        return(
                        // <p key={i}>{`${item.juiceName} - ${item.variationName}`}
                        <p key={i}>{item}
                            <span>{` `}
                                <button onClick={() => removeSelectedSmoothie(item)}>x</button>
                            </span>
                        </p>
                        )
                    })}
                </ul>
            </>
        )}
    }

    const showChosenTeas = () => {
        if(selectedTeas) {
            return(
            <>
            {/* {selectedJuices.map((item, i) => {
                return(<p>{item.juiceName}</p>)
            })} */}
                <ul>
                    {selectedTeas.map((item, i) => {
                        // console.log('what on earth is this value: ', item)
                        return(
                        // <p key={i}>{`${item.juiceName} - ${item.variationName}`}
                        <p key={i}>{item}
                            <span>{` `}
                                <button onClick={() => removeSelectedTea(item)}>x</button>
                            </span>
                        </p>
                        )
                    })}
                </ul>
            </>
        )}
    }


    const juiceComponent = () => (
        <div>
            <select 
            name="juicesSelect" 
            id="juicesSelect" 
            style={{
                minWidth: "240px",
                maxWidth: "240px"
            }}
            onChange={handleSelectJuices}
            value={0}
            >
                <option key={0} value={0} >Choose here</option>
                {juices.map((item, i) => {
                    // console.log('item inside juices map: ', item)
                    
                    return (
                        <option 
                        key={i + 1} 
                        value={`${item.juiceName} - ${item.variationName}`} 
                        >
                            {`${item.juiceName} - ${item.variationName}`}
                        </option>
                        )
                    }
                )}
            </select>
        </div>
        )


        const smoothieComponent = () => (
            <div>
                <select name="smoothiesSelect" id="smoothiesSelect" 
                style={{
                    minWidth: "240px",
                    maxWidth: "240px",
                    left: "10px"
                }}
                onChange={handleSelectSmoothies}
                value={0}
                >
                <option>Choose here</option>
                    {smoothies.map((item, i) => {
                        // console.log('item inside smoothies map: ', item)
                        return (
                        <option 
                        key={i} 
                        value={`${item.smoothieName} - ${item.variationName}`} >
                            {`${item.smoothieName} - ${item.variationName}`}
                            </option>
                            )
                        }
                    )}
                    </select>
            </div>
        )


        const herbalTeaComponent = () => (
            <div>
                <select 
                    name="herbalTeasSelect" 
                    id="herbalTeasSelect"
                    style={{
                        minWidth: "240px",
                        maxWidth: "240px"
                    }}    
                    onChange={handleSelectTeas}
                    value={0}
                >
                <option>Choose here</option>
                    {herbalTeas.map((item, i) => {
                    // console.log('item inside herbal teas map: ', item)
                        return (
                        <option key={i} 
                        value={`${item.herbalTeaName} - ${item.variationName}`} >
                            {`${item.herbalTeaName} - ${item.variationName}`}
                            </option>
                            )
                        }
                    )}
                    </select>
            </div>
        )


    const modalSubscription0 = (props) => {
        return (
          <div>
            <Button 
            className="btn btn-outline-light"
            id="subscription0"
            onClick={() => {
                plan0()
                toggle0()
                }
            }
            >Subscribe</Button>
            <Modal 
            isOpen={modal0} 
            toggle={toggle0} 
            size="lg"
            >
              <ModalHeader toggle={toggle0}>WEEKLY OPTION</ModalHeader>
              <ModalBody>
                <div className="row">
                    <div id="inner-first" className="col-md-4">
                        <h5 className="card-title">WEEKLY OPTION<br />${`252`}</h5>
                        <p className="card-text">-9 JUICES<br />OR<br/>-6 JUICES AND 3 SMOOTHIES</p>
                        <div className="row">
                            <div>
                                <h6>JUICE OPTIONS</h6>
                            {juiceComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-4"
                            >
                            <h6>SMOOTHIE OPTIONS</h6>
                            {smoothieComponent()}
                            {/* {JSON.stringify(juices)} */}
                            </div>
                            {selectedJuices.length > 0 ? <div className="mt-4">
                                <h6>SELECTED JUICES</h6>
                                {showChosenJuices()}
                            </div> : null}
                            {selectedSmoothies.length > 0 ? <div className="mt-4">
                                <h6>SELECTED SMOOTHIES</h6>
                                {showChosenSmoothies()}
                            </div> : null}
                        </div>
                    </div>
                    <div id="inner-second" className="col-md-8">
                        {showDropIn()}
                    </div>
                </div>
            </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
            </Modal>
          </div>
        );
      }

    const modalSubscription1 = (props) => {
        return (
          <div>
            <Button 
            className="btn btn-outline-light"
            id="subscription1"
            onClick={() => {
                plan1()
                toggle1()
                }
            }
            >Subscribe</Button>
            <Modal 
            isOpen={modal1} 
            toggle={toggle1} 
            size="lg"
            >
              <ModalHeader toggle={toggle1}>WEEKLY OPTION</ModalHeader>
              <ModalBody>
                <div className="row">
                    <div id="inner-first" className="col-md-4">
                        <h5 className="card-title">WEEKLY OPTION<br />${`200`}</h5>
                        <p className="card-text">-2 OUNCES (OR) 10 TEA BAGS
                        <br />OF YOUR CHOICE OF
                        <br />"SIMPLISEDE"
                        <br />HERBAL TEA BLENDS
                        <br />
                        <br />AND
                        <br />
                        <br/>-7 JUICES/SMOOTHIES
                        <br/>(MIX & MATCH)</p>
                        <div className="row">
                            <div 
                            className="mt-0">
                                <h6>HERBAL TEA OPTIONS</h6>
                            {herbalTeaComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-0">
                            <h6>JUICE OPTIONS</h6>
                            {juiceComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-0"
                            >
                            <h6>SMOOTHIE OPTIONS</h6>
                            {smoothieComponent()}
                            </div>
                            {selectedJuices.length > 0 ? <div className="mt-4">
                                <h6>SELECTED JUICES</h6>
                                {showChosenJuices()}
                            </div> : null}
                            {selectedSmoothies.length > 0 ? <div className="mt-4">
                                <h6>SELECTED SMOOTHIES</h6>
                                {showChosenSmoothies()}
                            </div> : null}
                            {selectedTeas.length > 0 ? <div className="mt-4">
                                <h6>SELECTED TEAS</h6>
                                {showChosenTeas()}
                            </div> : null}
                        </div>
                    </div>
                    <div id="inner-second" className="col-md-8">
                        {showDropIn()}
                    </div>
                </div>
            </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
            </Modal>
          </div>
        );
      }

    const subscriptionSecondGroup = () => {
        return(
            <>
            <div className="card-deck justify-content-center" >
                 <div 
                 className="card  bg-secondary"
                 style={{color: "white"}}
                 >
                     <div className="d-flex flex-row" >
                         <div 
                         className="d-flex flex-col"
                         >
                             <div>
                                 <img 
                             className="card-img-top" 
                             src="membershipRow2.png" 
                             alt="Card image cap " 
                             width="231"
                             height="426"
                             style={{width: "100%", height: "auto" }}
                             />
                             </div>
                                     
                         </div>
                         <div className="d-flex flex-col" >
                             <div 
                             className="card-body"
                             style={{fontSize: 10}}
                              >
                                 <h5 className="card-title">WEEKLY OPTION<br />${`280`}</h5>
                                 <p className="card-text">-2 OUNCES (OR) 10 TEA BAGS
                                 <br />OF YOUR CHOICE OF
                                 <br />"SIMPLISEDE"
                                 <br />HERBAL TEA BLENDS
                                 <br />
                                 <br />AND
                                 <br />
                                 <br/>-7 JUICES/SMOOTHIES
                                 <br/>(MIX & MATCH)</p>
                                 
                                 {}
                                 {modalSubscription2()}
                             </div>
                         </div>
                     </div>
                 </div>
                 <div className="card  bg-secondary" style={{color: "white"}}>
                     <div className="d-flex flex-row">
                         <div className="d-flex flex-col">
                             <div>
                                 <img 
                             className="card-img-top" 
                             src="membershipRow2.png" 
                             alt="Card image cap " 
                             width="231"
                             height="426"
                             style={{width: "100%", height: "auto" }}
                             />
                             </div>
                         </div>
                         <div className="d-flex flex-col ">
                             <div 
                             className="card-body"
                             style={{fontSize: 10}}
                              >
                                 <h5 className="card-title">BI-WEEKLY OPTION<br />$220</h5>
                                 <p className="card-text">-4 OUNCES (OR) 15 TEA BAGS
                                 <br />OF YOUR CHOICE OF
                                 <br />"SIMPLISEDE"
                                 <br />HERBAL TEA BLENDS
                                 <br />
                                 <br />AND
                                 <br />
                                 <br/>-12 JUICES/SMOOTHIES
                                 <br/>(MIX & MATCH)</p>
                                 {modalSubscription3()}
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </>
            
        )
    }

    
    const modalSubscription2 = (props) => {
        return (
          <div>
            <Button 
            className="btn btn-outline-light"
            id="subscription2"
            onClick={() => {
                plan2()
                toggle2()
                }
            }
            >Subscribe</Button>
            <Modal 
            isOpen={modal2} 
            toggle={toggle2} 
            size="lg"
            >
              <ModalHeader toggle={toggle2}>WEEKLY OPTION</ModalHeader>
              <ModalBody>
                <div className="row">
                    <div id="inner-first" className="col-md-4">
                        <h5 className="card-title">WEEKLY OPTION<br />${`280`}</h5>
                        <p className="card-text">-2 OUNCES (OR) 10 TEA BAGS
                                 <br />OF YOUR CHOICE OF
                                 <br />"SIMPLISEDE"
                                 <br />HERBAL TEA BLENDS
                                 <br />
                                 <br />AND
                                 <br />
                                 <br/>-7 JUICES/SMOOTHIES
                                 <br/>(MIX & MATCH)</p>
                                 <div className="row">
                            <div 
                            className="mt-0">
                                <h6>HERBAL TEA OPTIONS</h6>
                            {herbalTeaComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-0">
                            <h6>JUICE OPTIONS</h6>
                            {juiceComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-0"
                            >
                            <h6>SMOOTHIE OPTIONS</h6>
                            {smoothieComponent()}
                            </div>
                            {selectedJuices.length > 0 ? <div className="mt-4">
                                <h6>SELECTED JUICES</h6>
                                {showChosenJuices()}
                            </div> : null}
                            {selectedSmoothies.length > 0 ? <div className="mt-4">
                                <h6>SELECTED SMOOTHIES</h6>
                                {showChosenSmoothies()}
                            </div> : null}
                            {selectedTeas.length > 0 ? <div className="mt-4">
                                <h6>SELECTED TEAS</h6>
                                {showChosenTeas()}
                            </div> : null}
                        </div>
                    </div>
                    <div id="inner-second" className="col-md-8">
                        {showDropIn()}
                    </div>
                </div>
            </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
            </Modal>
          </div>
        );
      }


      const showChosenOnes = () => {
          return (
              <div>
                  <h6>SMOOTHIE OPTIONS</h6>
                            {smoothieComponent()}
                            {selectedTeas.length > 0 ? <div className="mt-4">
                                <h6>SELECTED TEAS</h6>
                                {showChosenSmoothies()}
                            </div> : null}
                            {selectedJuices.length > 0 ? <div className="mt-4">
                                <h6>SELECTED JUICES</h6>
                                {showChosenJuices()}
                            </div> : null}
                            {selectedSmoothies.length > 0 ? <div className="mt-4">
                                <h6>SELECTED SMOOTHIES</h6>
                                {showChosenSmoothies()}
                            </div> : null}
              </div>
          )
      }
      
    const modalSubscription3 = (props) => {
        return (
          <div>
            <Button 
            className="btn btn-outline-light"
            id="subscription3"
            onClick={() => {
                plan3()
                toggle3()
                }
            }
            >Subscribe</Button>
            <Modal 
            isOpen={modal3} 
            toggle={toggle3} 
            size="lg"
            >
              <ModalHeader toggle={toggle3}>WEEKLY OPTION</ModalHeader>
              <ModalBody>
                <div className="row">
                    <div id="inner-first" className="col-md-4">
                        <h5 className="card-title">WEEKLY OPTION<br />${`220`}</h5>
                        <p className="card-text">-4 OUNCES (OR) 15 TEA BAGS
                                 <br />OF YOUR CHOICE OF
                                 <br />"SIMPLISEDE"
                                 <br />HERBAL TEA BLENDS
                                 <br />
                                 <br />AND
                                 <br />
                                 <br/>-12 JUICES/SMOOTHIES
                                 <br/>(MIX & MATCH)</p>
                                 <div className="row">
                            <div 
                            className="mt-0">
                                <h6>HERBAL TEA OPTIONS</h6>
                            {herbalTeaComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-0">
                            <h6>JUICE OPTIONS</h6>
                            {juiceComponent()}
                            </div>
                            <br />
                            <div 
                            className="mt-0"
                            >
                            <h6>SMOOTHIE OPTIONS</h6>
                            {smoothieComponent()}
                            </div>
                            {selectedJuices.length > 0 ? <div className="mt-4">
                                <h6>SELECTED JUICES</h6>
                                {showChosenJuices()}
                            </div> : null}
                            {selectedSmoothies.length > 0 ? <div className="mt-4">
                                <h6>SELECTED SMOOTHIES</h6>
                                {showChosenSmoothies()}
                            </div> : null}
                            {selectedTeas.length > 0 ? <div className="mt-4">
                                <h6>SELECTED TEAS</h6>
                                {showChosenTeas()}
                            </div> : null}
                            
                        </div>
                    </div>
                    <div id="inner-second" className="col-md-8">
                        {showDropIn()}
                    </div>
                </div>
            </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
            </Modal>
          </div>
        );
      }


    return (
        <Layout
            title={`Membership`}
            description={``}
            className="container-fluid"
            
        >
            {redirectUserFunction()}
        <div style={{backgroundImage: "url(" + "dandelion1.jpeg" + ")"}}>
        <div className="container col-md-10" style={{fontFamily: "TrashHand"}}>
            <div 
            className="row" 
            style={{backgroundImage: "url(" + "dandelion2.jpeg" + ")"}}
            
            >
                {/* <div 
                className="col md-2"
                >
                </div> */}
                    <div 
                    className="row mb-5 mx-auto"
                    style={{alignContent: "center"}}
                    >
                        <div 
                        className="col-md-10 mx-auto" 
                        >
                                <h1 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}} fontSize="64px">SIMPLISEDE MEMBERSHIPS</h1>
                                <h3 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>FOR LOCAL SOUTHERN CALIFORNIANS</h3>
                            <br />
                            <p>
                                <h3 
                                style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}
                                >WE WOULD LOVE TO PROVIDE YOU WITH A QUALITY DELIVERY EXPERIENCE WHERE YOU</h3>
                            </p>
                            <p>
                                <h3 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>CAN RECEIVE FRESH JUICES/SMOOTHIES/ HERBAL TEA BLENDS</h3>
                            </p>
                            <p>
                                <h3 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>WEEKLY OR BI-WEEKLY</h3>
                            </p>
                                <br />
                            <p>
                                <h2 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>JUICE AND SMOOTHIE SUBSCRIPTION</h2>
                            </p>
                        </div>
                    </div>
                
            </div>

            <br />
                {subscriptionFirstGroup()}
            <h3 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>WEEKLY OR BI-WEEKLY</h3>
                {subscriptionSecondGroup()}
                <br />
            <h2 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>HERBAL TEA, JUICE AND SMOOTHIE SUBSCRIPTION</h2>
            <br />
            <h1 style={{textAlign: "center", whiteSpace: "wrap", color: "white"}}>ALL MEMBERSHIPS INCLUDE:</h1>
            <div className="row">
                <div className="col-md-2">
                </div>
                <div className="col-md-4">
                    <p><h3 style={{textAlign: "left", whiteSpace: "wrap", color: "white"}}>-DELIVERY FEE</h3></p>
                    <p><h3 style={{textAlign: "left", whiteSpace: "wrap", color: "white"}}>-A LITTLE CONVO W/ MARCHELLE</h3></p>
                    <p><h3 style={{textAlign: "left", whiteSpace: "wrap", color: "white"}}>-SAMPLE OF SEA MOSS W/ (OR) W/O BLADDERWRACK</h3></p>
                </div>
            </div>

        </div>
            <div>
                <img 
                src="recycle.jpg" 
                alt="recycle"
                width="1206"
                height="604"
                style={{width: "100%", height: "auto" }}
                />
            </div>
        </div>
        </Layout>
    );
};

export default Membership;






