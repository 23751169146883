
const schema = () => {
    return `const mongoose = require("mongoose");
    const Schema = mongoose.Schema
    const { ObjectId } = mongoose.Schema;
    
    const productSchema = new mongoose.Schema(
        {
            title: {
                type: String,
                trim: true,
                required: true,
                unique: true,
                maxlength: 80
            },
            ASIN: {
                type: String,
                default: ""
            },
            itemSpecifics: {
                type: Array,
                default: []
            },
            pictureURLs: {
                type: Array,
                default: []
            },
            url: {
                type: String,
                default: ""
            },
            size: {
                type: String,
                trim: true
            },
            color: {
                type: String,
                trim: true
            },
            upc: {
                type: Array,
                default: []
            },
            description: {
                type: String,
                required: true,
                // maxlength: 2000
            },
            ebayCategoryId: {
                type: String,
                default: ""
            },
            categoryID: {
                type: String,
                default: ""
            },
            categoryId: {
                type: String,
                default: ""
            },
            postEbay: {
                type: Schema.Types.Mixed,
                default: []
            },
            keywords: {
                type: Array,
                default: []
            },
            ebayListing: [{
                type: ObjectId,
                ref: "Ebaylisting"
            }],
            itemId: {
                type: Array,
                default: []
            },
            amazon: [{
                type: ObjectId,
                ref: "Amazon",
                // default: ''
            }],
            bedbathandbeyond: [{
                type: ObjectId,
                ref: "bedbathandbeyond",
                // default: ''
            }],
            cabelas: [{
                type: ObjectId,
                ref: "Cabelas",
                // default: '' 
            }],
            itemidcheck: [{
                type: ObjectId,
                ref: "Itemidcheck"
    
            }],
            hayneedle: [{
                type: ObjectId,
                ref: "Hayneedle",
                // default: ''
            }],
            homedepot: [{
                type: ObjectId,
                ref: "HomeDepot",
                // default: ''
            }],
            lowes:  [{
                type: ObjectId,
                ref: "Lowes",
                // default: ''
            }],
            macys:  [{
                type: ObjectId,
                ref: "Macys",
                // default: ''
            }],
            overstock:  [{
                type: ObjectId,
                ref: "Overstock",
                // default: ''
            }],
            sears:  [{
                type: ObjectId,
                ref: "Sears",
                // default: ''
            }],
            target:  [{
                type: ObjectId,
                ref: "Target",
                // default: ''
            }],
            unbeatablesale:  [{
                type: ObjectId,
                ref: "Unbeatablesale",
                // default: ''
            }],
            walmart:  [{
                type: ObjectId,
                ref: "Walmart",
                // default: ''
            }],
            wayfair:  [{
                type: ObjectId,
                ref: "Wayfair",
                // default: ''
            }],
            ebayUrls:  [{
                type: ObjectId,
                ref: "ebay",
                // default: ''
            }],
            // user: {
            //     type: ObjectId,
            //     ref: "User",
            //     default: ''
            // },
            primarySource: {
                type: Array,
                default: []
            },
            sources: {
                type: Array,
                default: []
            },
            isListed: {
                type: Boolean,
                default: false
            },
            sold: {
                type: Boolean,
                default: false
            },
            lastPriceStockCheck: {
                type: mongoose.Schema.Types.Mixed,
                default: {}
            },
            price: {
                type: Number,
                trim: true,
                required: true,
                maxlength: 32
            },
            startPrice: {
                type: Number,
                trim: true,
                required: true,
                maxlength: 32
            },
            priceHistory: {
                type: Array,
                default: []
            },
            stockHistory: {
                type: Array,
                default: []
            },
            ebay: [{
                type: ObjectId,
                ref: "Ebay"
            }],
            status: {
                type: String,
                default: "neverAssigned",
                enum: [
                    "neverAssigned",
                    "Assigned1",
                    "Assigned2",
                    "Assigned3",
                    "Assigned4",
                    "Assigned5",
                    "Assigned6",
                    "Assigned7",
                    "Assigned8",
                    "Assigned9",
                    "Assigned10",
                    "Assigned11",
                    "Assigned12",
                ]
            }
        },
        { timestamps: true }
    );
    
    module.exports = mongoose.model("Product", productSchema);
    `
}

export default schema