// CREATE
export const CREATE_QUOTE = 'CREATE_QUOTE'

// READ MANY
export const RECEIVE_QUOTES = 'RECEIVE_QUOTES'

// READ ONE
export const SINGLE_QUOTE = 'SINGLE_QUOTE'

// UPDATE
export const UPDATE_QUOTE = 'UPDATE_QUOTE'

// DELETE
export const DELETE_QUOTE = 'DELETE_QUOTE'

// USER
export const LOGGED_IN_USER = 'LOGGED_IN_USER'