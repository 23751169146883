import React, { useState, useEffect } from "react";
import Header from '../components/layouts/Header'
import { Link, withRouter } from "react-router-dom";
import ContactForm from './ContactForm';

const Contact = ( props ) => {
    const [formShow, setFormShow] = useState(true)
    const [ s, setS ] = useState({
        date: "",
        quantity: "",
        message: '',
        name: '',
        email: '',
        sent: false,
        buttonText: 'Send Message',
        success: false,
        error: false
    })
    // const { date, quantity } = props.location.state

    const init = () => { setS({ 
        ...s, 
        date: props && props.location && props.location.state && props.location.state.date ? props.location.state.date : "", 
        quantity: props && props.location && props.location.state && props.location.state.quantity ? props.location.state.quantity : "",
        message: props && props.location && props.location.state && props.location.state.quantity && props.location.state.date ? 
        `Order fulfill by date: ${ props.location.state.date }\nQuantity: ${ props.location.state.quantity }` 
        : "",
    })}

    useEffect( () => {
        init()
    }, [])

    if(formShow === true) {
        return (
            <div className="container-fluid">
                <div className="row" style={{height: "220px"}}>
                    <Header />
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h2>Contact form</h2>
                        <hr />
                        <ContactForm 
                        // emailAddress={emailAddress} 
                        s={ s }
                        setS={ setS }
                        setFormShow={setFormShow}
                        formShow={formShow}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
                <div className="container-fluid">
                <div className="row" style={{height: "220px"}}>
                    <Header />
                </div>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="text-center">Message submitted!</h2>
                            <p className="text-muted text-center mt-5">Thank you for your message.</p>
                            <p className="text-muted text-center mt-2">We will be in touch as soon as possible.</p>
                        </div>
                    </div>
                </div>
        );
    }
};

export default withRouter( Contact );