import React, { useState, useEffect } from 'react'
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
// import FolderTree, { testData } from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';
import { Accordion } from 'react-bootstrap'

const ManageGalleries = () =>
{
    const [ s, setS ] = useState({
        pictures: [],
        chosen_picture: '',
        name: 'root node',
        checked: 0,
        isOpen: false,
        children: [],
        path: [],
        _id: 0,
        nickname: 'first',
        path_main: '../assets/img/',
        path_tshirts: '../assets/img/tshirts/',
        path_shirt_page: '../assets/img/tshirts/shirtPage',
        uploadedImage: null,
        accordionNumber: null
    })

    const getAllPictures = ( r ) =>
    {
        return r.keys().map( r )
    }

    const init = () =>
    {
        let listOfImages = getAllPictures(require.context('../assets/img/tshirts/', false, /\.(png|jpe?g|svg)$/))
        setS({ ...s, pictures: listOfImages })
    }

    useEffect( () => {
        init()
    }, [])

    const loadHomePageMPG = async ( accordionNumber ) =>
    {
        let a = {}
        a.listOfImages = getAllPictures(require.context('../assets/img/tshirts/', false, /\.(png|jpe?g|svg)$/))
        console.log( `a.listOfImages (1): `, a.listOfImages )
        a.listOfImages = a.listOfImages.map( _path => {
            let b = {}
            b._path = _path // PARAM
            console.log( `b._path: `, b._path )
            b.newPath = b._path.replace( `/static/media/`, `../assets/img/tshirts/`)
            console.log( `b.newPath (1): `, b.newPath )
            b.re = /[.].{8,9}(?=.jpg|gif|png)/g
            b.newPath = b.newPath.replace( b.re, `.` ).replace( `..jpg`, `.jpg` ).replace( `..png`, `.png` ).replace( `..gif`, `.gif` )
            return b.newPath
        })
        console.log( `a.listOfImages (2): `, a.listOfImages )
        setS({ ...s, pictures: a.listOfImages, accordionNumber: accordionNumber })
    }

    const loadShirtPageMPG = async ( accordionNumber ) =>
    {
        let a = {}
        a.listOfImages = getAllPictures(require.context('../assets/img/tshirts/shirtPage/', false, /\.(png|jpe?g|svg)$/))
        console.log( `a.listOfImages (1): `, a.listOfImages )
        a.listOfImages = a.listOfImages.map( _path => {
            let b = {}
            b._path = _path // PARAM
            console.log( `b._path: `, b._path )
            b.newPath = b._path.replace( `/static/media/`, `../assets/img/tshirts/shirtPage/`)
            console.log( `b.newPath (1): `, b.newPath )
            b.re = /[.].{8,9}(?=.jpg|gif|png)/g
            b.newPath = b.newPath.replace( b.re, `.` ).replace( `..jpg`, `.jpg` ).replace( `..png`, `.png` ).replace( `..gif`, `.gif` )
            return b.newPath
        })
        console.log( `a.listOfImages (2): `, a.listOfImages )
        setS({ ...s, pictures: a.listOfImages, accordionNumber: accordionNumber })
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className='mb-3 p-2'>
                    <Link to={`/admin/dashboard`}> {`<--`}&nbsp;Back to Admin Dashboard</Link>
                </div>
            </div>
            <div className="row">
                <h1 className='text-center m-1 p-1'>Manage Galleries</h1>
            </div>
            <div className="row mt-2">
                <Accordion 
                    // defaultActiveKey="0"
                    >
                    <Accordion.Item 
                        eventKey="0" 
                        onClick={ e => { 
                            e.preventDefault()
                            loadHomePageMPG( '0' )
                        }}
                        >
                        <Accordion.Header>Home Page Mid-Page Gallery</Accordion.Header>
                        <Accordion.Body>
                        <h3 className="text-center">Home Page Mid-Page Gallery</h3>
                        <form>
                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-2 col-form-label">Picture</label>
                                <div className="col-sm-10">
                                    <select 
                                        name="" 
                                        id="" 
                                        value={ s.chosen_picture }
                                        className="form-control"
                                        onInput={ e => {
                                            console.log( `e.target.value: `, e.target.value)
                                            setS({ ...s, chosen_picture: e.target.value })
                                        }}
                                        className="form-control"
                                        >
                                    {
                                        s.pictures && s.pictures.length > 0 && s.accordionNumber === '0' ?
                                        s.pictures.map( ( image, id ) => <option key={ id } value={ image }>{ image }</option>)
                                        : <option value={""}>{}</option>
                                    }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-4"></div>
                                <div className="col-8">
                                    {
                                        s.chosen_picture ?
                                        <div className='form-group row mt-3 p-1'>
                                            <img 
                                                src={ require( `../assets/img/tshirts/${ s.chosen_picture.split( `/` ).slice( -1 )[ 0 ] }` ) } 
                                                alt="chosen"
                                                />
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </form> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Home Page Bottom-Page Gallery</Accordion.Header>
                        <Accordion.Body>
                            
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item 
                        eventKey="2" 
                        onClick={ e => { 
                            e.preventDefault()
                            loadShirtPageMPG( "2" )
                        }}
                        >
                        <Accordion.Header>Shirt Page Gallery</Accordion.Header>
                        <Accordion.Body>
                        <h3 className="text-center">Shirt Page Gallery</h3>
                        <form>
                            <div className="form-group row">
                                <label htmlFor="" className="col-sm-2 col-form-label">Picture</label>
                                <div className="col-sm-10">
                                    <select 
                                        name="" 
                                        id="" 
                                        value={ s.chosen_picture }
                                        className="form-control"
                                        onChange={ e => {
                                            console.log( `e.target.value: `, e.target.value)
                                            setS({ ...s, chosen_picture: e.target.value })
                                        }}
                                        className="form-control"
                                        >
                                    {
                                        s.pictures && s.pictures.length > 0 && s.accordionNumber === '2' ?
                                        s.pictures.map( ( image, id ) => <option key={ id } value={ image }>{ image }</option>)
                                        : <option value={""}>{``}</option>
                                    }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-4"></div>
                                <div className="col-8">
                                   
                                </div>
                            </div>
                        </form> 
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
         
            <div>
                <label htmlFor="">Change image</label>
                <input 
                    type="file"
                    name="" 
                    id="" 
                    className="form-control-file" 
                    accept='image/*'
                    // value={ s.uploadedImage }
                    onChange={ e => {
                        setS( { ...s, uploadedImage: e.target.files[ 0 ] })
                    }}
                    />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                       {/* uploaded image: { JSON.stringify( s.uploadedImage ) } */}
                    </div>
                    <div className="col-sm"></div>
                </div>
            </div>
            { JSON.stringify( s )}
        </div>
    )
}

export default ManageGalleries