import React from "react"

const columns = [
    {
     name: 'Item name',
     selector: 'itemname',
     sortable: true,
    },
    {
     name: 'Item description',
     selector: 'itemdesc',
     sortable: true,
    },
    {
     name: 'User',
     selector: 'itemuser',
     sortable: true,
    },
   ];

export default columns