import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../api/auth";
import { Link } from "react-router-dom";
import { getCustomers, getOrder, deleteProduct } from "./apiAdmin";
import styled from 'styled-components'
import { Button } from 'reactstrap'
import DataTable from 'react-data-table-component';
import PurchasesModal from './PurchasesModal'
import SubscriptionModal from './SubscriptionModal'

const TextField = styled.input`
 height: 32px;
 width: 200px;
 border-radius: 3px;
 border-top-left-radius: 5px;
 border-bottom-left-radius: 5px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 border: 1px solid #e5e5e5;
 padding: 0 32px 0 16px;

 &:hover {
  cursor: pointer;
 }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const columns = [
    {
     name: 'Customer Name',
     selector: 'customerName',
     sortable: true,
    },
    {
     name: 'Purchases',
     selector: 'purchases',
     sortable: true,
    },
    {
     name: 'Subscriber',
     selector: 'subscriptionStatus',
     sortable: true,
    },
   ];


const Customers = () => {
    // state
    const [customers, setCustomers] = useState([])
    const [error, setError] = useState(false)
    const { user, token } = isAuthenticated()
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    // loaders
    const loadCustomers = () => {
        getCustomers(user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCustomers(data);
            }
        });
    };

    // const destroy = productId => {
    //     deleteProduct(productId, user._id, token).then(data => {
    //         if (data.error) {
    //             console.log(data.error);
    //         } else {
    //             loadProducts();
    //         }
    //     });
    // };

    // useEffect
    useEffect(() => {
        loadCustomers();
    }, []);


    // filter component
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
         <TextField id="search" type="text" placeholder="Filter By Text" value={filterText} onChange={onFilter} />
         <ClearButton type="button" onClick={onClear}>X</ClearButton>
        </>
       );

    // create datatable array
    const customerList = () => {
        return customers.map((customer, i) => {
            console.log(`customer item: `, customer)
            return {
                customerName: customer.customerName,
                purchases: customer.purchases,
                subscriptionStatus: customer.subscriptionStatus,
            }
        })
    }

    // create subHeaderComponentMemo    
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
     if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
     }
    };
   
    return [<FilterComponent 
        key="button2" 
        onFilter={e => setFilterText(e.target.value)} 
        onClear={handleClear} 
        filterText={filterText} 
        />,
    ];
   }, [filterText, resetPaginationToggle]);

   // create the datatable
   const customersDataTable = () => {
    const filteredItems = customerList().filter(item => item.customerName && item.customerName.toLowerCase().includes(filterText.toLowerCase()));
    // const filteredItems =  datatableThoughts()     //fakeUsers.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  

    return (
      <DataTable
        title="customers"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        // selectableRows
        responsive
        expandableRows
        expandableRowsComponent={<CustomerExpandedComponent edata={customers}/>}
        persistTableHead
      />
    );
  };

  const CustomerExpandedComponent = ({ data, edata }) => {
    let currentCustomer = edata.filter((cust, i) => {
        if (data.customerName === cust.userData.name) {
            return cust.userData
        }
    })
    let { 
        name,
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zipcode,
        email,
        phone,
        history,
        role,
        braintreeCustomerId,
        braintreeSubscriptions
    } = currentCustomer[0].userData
      return (
          <>
          <div className="row">
              <div 
              className="col-11"
              style={{
                  textAlign: "left"
              }}
              >
                  <div className="row">
                    <div className="col-2">
                    {/* <h5 >Customer name: {data.customerName}</h5> */}
                    <p style={{
                    fontFamily: "Arial",
                    textAlign: "left"
                    }}><strong>Address</strong></p>
                    <p
                    style={{
                    fontFamily: "Arial",
                    textAlign: "left"
                    }}>{`${name}`}<br />
                    {`${address1}, ${address2}`}<br />
                    {`${city}, ${state} ${zipcode}`}<br />
                    </p>
                    </div>

                    <div className="col-3">
                        <p style={{
                        fontFamily: "Arial",
                        textAlign: "left"
                    }}><strong>Phone</strong><br />
                    {`${phone}`}
                    </p>
                    
                    <p style={{
                        fontFamily: "Arial",
                        textAlign: "left"
                    }}><strong>Email</strong><br />
                    {email}
                    </p>
                    
                    </div>

                    <div className="col-3">
                        <p style={{
                            fontFamily: "Arial",
                            textAlign: "left"
                        }}><strong>Purchases: {data.purchases}</strong> <PurchasesModal 
                        history={history} 
                        getOrder={getOrder}
                        user={user}
                        token={token}
                        /></p>
                        
                    </div>

                    <div className="col-2">
                        <p style={{
                            fontFamily: "Arial",
                            textAlign: "left"
                        }}><strong>Subscriptions: {braintreeSubscriptions.length}</strong> <SubscriptionModal
                            braintreeSubscriptions={braintreeSubscriptions}
                            user={user}
                            token={token}
                        /></p>
                        
                    </div>


                </div>
              </div>

          </div>
          </>
      )
  }

  const CustomerPurchasesModal = (props) => {

  }


    return (
        <Layout
            title="Customers"
            description="Manage customers"
            className="container-fluid"
        >
            <div className="row">
                <div className="col-12">
                    <h2 className="text-center">
                        Total {customers.length} Customers
                    </h2>
                    <hr />
                    {customersDataTable()}
                    <br />
                    
                    {/* <p>{JSON.stringify(customers)}</p> */}
                </div>
            </div>
        </Layout>
    );
};

export default Customers;
