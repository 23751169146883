import React from 'react';
import classNames from 'classnames';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';

const Sidebar = ({
    // classNames,
    // Link,
    isAuthenticated,
    signout,
    redText,
    removeClass,
    removeAll,
    history,
    v,
    setV,
}) => {
    return(
        <>
          {/*====== OFF CANVAS START ======*/}
          <div className={ classNames("offcanvas-wrapper", {"show-offcanvas": redText})}>
              <div className={ classNames("offcanvas-overly", {"show-overly": redText}) } onClick={ removeAll }/>
              <div className="offcanvas-widget">
                  <Link to="#" className="offcanvas-close" onClick={ removeClass }><i className="fal fa-times" /></Link>
                  {/* Search Widget */}
                  <div className="widget search-widget">
                  </div>
                  {/* About Widget */}
                  <div className="widget about-widget">
                  </div>
                  {/* Nav Widget */}
                  <div className="widget nav-widget">
                    <h5 className="widget-title">Account</h5>
                    <ul>
                      <li>{ isAuthenticated() && isAuthenticated().user.role === 1 ? <Link to="/admin/dashboard">Admin Dashboard</Link> : null }</li>
                      <li>{ isAuthenticated() && isAuthenticated().user.role === 0 ? <Link to="/user/dashboard">Member Dashboard</Link> : null }</li>           
                      <li>
                        {
                          isAuthenticated() ? 
                          <Link to={`/`}><span onClick={ () => signout( () => { history.push( "/" ) } ) }>Signout</span></Link>
                          : <Link to="/signin">Login</Link> 
                        }
                      </li>
                      <li>{ isAuthenticated() ? null : <Link to="/signup">Sign up</Link> }</li>    
                    </ul>
                  </div>
                  {/* Social Link */}
                  <div className="widget social-link">
                  </div>
                </div>
          </div>
        {/*====== OFF CANVAS END ======*/}
        </>
    )
}

export default Sidebar