import React, { useState, useEffect, useCallback } from 'react'
import Datatable from './Datatable'
import { getQuotes } from '../../api'
import { isAuthenticated } from '../../api/auth'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './modal.css'

const AdminQuotes = () => {
    /** Steps remaining on this...
     * 1. Clicking on modal Send Response button causes email to be sent to user with response, written to quote doc, and reflected in the dashboard
     * 2. Email is sent to both the admin and user of the response to the quote.
     * 3. Ability to add quoted $ in the response.
     */
    const [ quotes, setQuotes ] = useState([])
    const [modal, setModal] = useState(false);
    const [values, setValues] = useState({
        id: "",
        jobName: "",
        jobDescription: "",
        responses: []
    })
    const [response, setResponse ] = useState({
        title: "",
        _body: "",
        t: null,
        quotePrice: null
    })

    let { title, _body, t, quotePrice } = response

    let { id, jobName, jobDescription, responses } = values
  
    const toggle = () => setModal(!modal);

    let { user, token } = isAuthenticated()

    const init = useCallback(
        async () => {
            let _quotes = await getQuotes( token, user._id, setQuotes )
            setQuotes(_quotes)
        }, [getQuotes]
    )

    useEffect(() => {init() }, [init])

    const sendResponse = async () => {
        try {
            let endpoint = `${process.env.REACT_APP_API}/quotes/response/admin`
            let headers = { Accept: "application/json", "Content-Type": "application/json" }
            let method = `POST`
            let body = JSON.stringify({
                _id: id,
                body: _body,
                title,
                t: new Date(),
                quotePrice
            })
            let result = await fetch( endpoint, { method, headers, body } )
            result = await result.json()

        } catch ( e ) { console.log( `Got an error in sendResponse...`, e ) }
    }

    const quoteModal = (item) => {
        return(
            
        <div>
        <Button color="primary" onClick={e => {e.preventDefault(); setValues({
            ...values, 
            id: item._id, 
            jobName: item.jobName, 
            jobDescription: item.jobDescription,
            responses: item.responses
            }); toggle()}}>Respond</Button>
        <Modal size="xl" isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
          <ModalHeader toggle={toggle}><h2>{jobName}</h2></ModalHeader>
          <div className="container-fluid pb-5">
            _id: {id}
            <p className="mb-4">{jobDescription}</p>
            {
                responses && responses.length > 0 ?
                <>
                    <div style={{ maxHeight: "40vh", overflowY: "auto"}}>
                    <h4>History of Responses</h4>
                    {
                        responses.map((item, i) => <div key={`response-${i}`} style={{border: "0.5px solid black"}}>
                        <p>from: {item.from}</p>
                        {item.t}
                        <p>title: {item.title}</p>
                        <p>{item.body}</p>
                    </div>)
                    }
                    </div>
                </>
                : null
            }
          </div>
          <ModalBody>
              <h4>Add A New Response Below</h4>
               <form>
                   <div className="form-group">
                        <input 
                            className="form-control"
                            type="text"
                            value={title}
                            onChange={e => setResponse({...response, title: e.target.value})}
                            placeholder="Enter title"
                        />
                   </div>
                   <div className="form-group">
                        <textarea 
                            className="form-control"
                            value={_body}
                            onChange={e => setResponse({...response, _body: e.target.value})}
                            placeholder="Enter body"
                        />
                   </div>
                    <div className="form-group form-inline">
                        <label style={{ marginRight: "10px" }}>Quote Amount $</label>
                        <input 
                            className="form-control"
                            type="number"
                            value={quotePrice}
                            onChange={e => setResponse({...response, quotePrice: e.target.value})}
                            // placeholder="Enter quote dollar amount"
                        />
                    </div>
                   <button onClick={ e => {e.preventDefault(); sendResponse() }} className="btn btn-primary">Send</button>
               </form>

          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
        )
    }

    return(
        <div className="container">
            <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                    <h1>Admin Quotes</h1>
                    {
                        quotes && quotes.length > 0 ?
                        // JSON.stringify(quotes)
                        quotes.map((item, i) => {
                            return(
                                <div style={{border: "0.5px solid black"}} className="card my-1" key={`quote-${i}`}>
                                    <div className="container py-2">
                                        <h3 className="">{item.jobName}</h3>
                                        {
                                            item.quotePrice && item.approved === false ?
                                            <p>Current quote: <span style={{ color: 'red' }}>${item.quotePrice}</span></p>
                                            : item.quotePrice && item.approved === true ?
                                            <p>Client approved quote: <span style={{ color: 'green' }}>${item.quotePrice}</span></p>
                                            : null
                                        }
                                        <p>{item.jobDescription}</p>
                                        <hr />
                                        <p>Status: <span style={{color: "blue"}}>{item.status}</span> {quoteModal(item)}</p>
                                        {/* <details>
                                            <summary>JSON item</summary>
                                            { JSON.stringify(item)}
                                        </details> */}
                                    </div>
                                </div>
                            )
                        })
                        : null
                    }
                    {/* <Datatable /> */}
                </div>
                <div className="col-sm-2"></div>
            </div>
        </div>
    )
}

export default AdminQuotes