import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow'
import Slider from "react-slick";

import menugalleryimg1 from '../../../assets/img/menu/menu-gallery-1.jpg';
import menugalleryimg2 from '../../../assets/img/menu/menu-gallery-2.jpg';
import menugalleryimg3 from '../../../assets/img/menu/menu-gallery-3.jpg';
import menugalleryimg4 from '../../../assets/img/menu/menu-gallery-4.jpg';
import menugalleryimg5 from '../../../assets/img/menu/menu-gallery-5.jpg';
import menugalleryimg6a from '../../../assets/img/menu/menu-gallery-6_a.jpg';
import menugalleryimg6b from '../../../assets/img/menu/menu-gallery-6_b.jpg';
import menugalleryimg7a from '../../../assets/img/menu/menu-gallery-7_a.jpg';
import menugalleryimg7b from '../../../assets/img/menu/menu-gallery-7_b.jpg';

 const SliderComponent = () =>
 {
   
  const menugalleryPosts = [
    {
        photo: menugalleryimg1,
    },
    {
        photo: menugalleryimg2,
    },
    {
        photo: menugalleryimg3,
    },
    {
        photo: menugalleryimg4,
    },
    {
        photo: menugalleryimg5,
    },
    {
        photo: menugalleryimg1,
    },
    {
        photo: menugalleryimg2,
    },
    {
      photo: menugalleryimg3,
    },
    {
        photo: menugalleryimg4,
    },
    {
        photo: menugalleryimg5,
    },
    {
        photo: menugalleryimg6a,
    },
    {
        photo: menugalleryimg6b,
    },
    {
        photo: menugalleryimg7a,
    },
    {
        photo: menugalleryimg7b,
    },
  ];

  const gallerysettings = {
    slidesToShow: 3,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  }; 
  
    return (
      <>
      
          {/* Gallery */}
          <div className="gallery-wrap mt-100">
            <div className="container">
              <Slider className="row gallery-slider text-center" {...gallerysettings}>
              {menugalleryPosts.map((item, i) => (
                <div key={i} className="col-lg-12">
                  <Link to={item.photo} className="gallery-popup">
                    <img src={item.photo} alt="" />
                  </Link>
                </div>
              ))}
              </Slider>
            </div>
          </div>
      </>
    );
}

export default SliderComponent;
