import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Header from '../components/layouts/Header'
import BlockUi from 'react-block-ui';
import { signin, signup, authenticate, isAuthenticated  } from "../api/auth";
import {Container,Row,Col,CardBody,Form,FormGroup,Input,Label,Button} from 'reactstrap'
import '../style.css'

const Signup = (props) => {
    const [blocking, setBlocking] = useState(false)
    const [redirectSignin, setRedirectSignin] = useState(false)
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        email: "",
        phone: "",
        password: "",
        error: "",
        success: false
    });

    const [isChecked, setIsChecked] = useState(false)

    const [loginValues, setLoginValues] = useState({
        emailLogin: "",
        passwordLogin: "",
        errorLogin: "",
        successLogin: false,
        redirectToReferrer: false,
        loadingLogin: false
    });

    const { 
        firstName, 
        lastName, 
        address1, 
        address2, 
        city, 
        state, 
        zipcode, 
        email, 
        phone,
        password, 
        success, 
        error 
    } = values;

    const { 
        emailLogin, 
        passwordLogin, 
        successLogin, 
        errorLogin,
        redirectToReferrer,
        loadingLogin
    } = loginValues;

    const { user } = isAuthenticated();

    const toggleBlocking = () => {
      setBlocking(!blocking)
    }

    const toggleform = () => {
      document.querySelector('.cont').classList.toggle('s--signup');
    }
    

    const handleLoginChange = name => event => {
        setLoginValues({ ...loginValues, errorLogin: false, [name]: event.target.value });
    };
    
    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const showLoading = () => {

    }

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false });
        let name = firstName + ' ' + lastName
        signup({ 
          name,
          firstName, 
          lastName, 
          address1, 
          address2, 
          city, 
          state, 
          zipcode, 
          email, 
          phone, 
          password
         }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({
                    ...values,
                    firstName: "",
                    lastName: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    email: "",
                    phone: "",
                    password: "",
                    error: "",
                    success: true
                })
                // redirectUserToSignin()
                setRedirectSignin(true)
            }
        });
    };

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
      <div
          className="alert alert-info"
          style={{ display: success ? "" : "none" }}
      >
          New account is created. Please <Link to="/signin">Signin</Link>
      </div>
  );

    
    const redirectUserToSignin = () => {
      if(redirectSignin) {
        return <Redirect to="/signin" />
      }
  };
    const redirectUser = () => {
      if (redirectToReferrer) {
          if (user && user.role === 1) {
              return <Redirect to="/admin/dashboard" />;
          } else {
              return <Redirect to="/user/dashboard" />;
          }
      }
      if (isAuthenticated()) {
          return <Redirect to="/" />;
      }
  };

  const signupForm = () => {
    return(
      <>
      
      <BlockUi tag="div" blocking={blocking}>
      <Form 
        className="theme-form"
        >
          
          <Row form>
            <Col md="6">
              <FormGroup>
                <Input                                     
                onChange={handleChange("firstName")}
                className="form-control" 
                type="text" 
                placeholder="First Name"
                value={firstName}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Input 
                className="form-control" 
                type="text" 
                placeholder="Last Name"
                onChange={handleChange("lastName")}
                value={lastName}
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Input 
                type="text" 
                value={email}
                onChange={handleChange("email")}
                name="email" 
                id="email" 
                placeholder="Email"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input 
                type="password" 
                value={password}
                onChange={handleChange("password")}
                name="password" 
                id="password" 
                placeholder="Password"
                />
              </FormGroup>
            </Col>
            </Row>
            <Row form>
              <Col md={7}>
                <FormGroup>
                    <Input 
                    type="text" 
                    value={address1}
                    onChange={handleChange("address1")}
                    name="address1" 
                    id="address1"
                    placeholder="Address"
                    />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                    <Input 
                    type="text" 
                    value={address2}
                    onChange={handleChange("address2")}
                    name="address2" 
                    id="address2"
                    placeholder="Apartment, suite, floor"
                    />
                </FormGroup>
              </Col>
            </Row>
            
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={city}
                        onChange={handleChange("city")}
                        name="city" 
                        id="city"                                            
                        placeholder="City"
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={state}
                        onChange={handleChange("state")}
                        name="state" 
                        id="state"                                            
                        placeholder="State"
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={zipcode}
                        onChange={handleChange("zipcode")}
                        name="zipcode" 
                        id="zipcode"
                        placeholder="Zip"
                        />
                    </FormGroup>
                </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                  <Input 
                  type="text" 
                  value={phone}
                  onChange={handleChange("phone")}
                  name="phone" 
                  id="phone"
                  placeholder="*Phone"
                  required
                  />
              </FormGroup>
              
            </Col>
          </Row>
          <Row form>
            <Col sm="4">
            </Col>
            <Col sm="8">
              <Button 
              className="pull-right"
              color="primary"
              onClick={clickSubmit}
              >Sign Up</Button>
            {/* {isAuthenticated() ? showSuccess()  : 
            <div className="text-left mt-2 m-l-20">Are you already user? 
              <a style={{color: "blue"}} className="btn-link text-capitalize" onClick={toggleform}>Login</a>
            </div>} */}
            </Col>
          </Row>
          <div className="form-divider"></div>
        </Form>
        </BlockUi>
        {/* {redirectUserToSignin()} */}
      </>
    )
  }

    return (
   <div className="page-wrapper">
     <div>
       <div className="row" style={{height: "220px"}}>
          <Header />
       </div>
     
        <div className="authentication-main mt-0" >
          <Row>
            <Col md="12">
              <div className="auth-innerright auth-bg">
                <div className="authentication-box">
                  <div className="mt-4">
                    <CardBody className="p-0">
                    <div className="cont text-center s--signup">
                    <div> 
                      {/* {loginForm()} */}
                        </div>
                        <div className="sub-cont" 
                          style={{
                            maxHeight: "100%",
                            overflow: 'auto',
                            overflowY: "scroll",
                            display: 'block'
                          }}>
                          <div className="img">
                            
                            <div className="img__text m--in">
                              {/* <h2 className="signup-side-signin-header">One of us?</h2> */}
                              <p >If you already have an account, just <a href="/signin">sign in</a>. We've missed you!</p>
                            </div>
                            
                          </div>
                          <div className="container">
                            <div className="row mb-2 mt-5">
                              <h1>&nbsp;Sign up...</h1>                              
                            </div>
                            <div className="row">
                              {showSuccess()}
                              {showError()}
                            </div>
                            <div className="row mb-3">
                              <h6>&nbsp;&nbsp;&nbsp;Enter your Account Information Below To Signup</h6>                            
                            </div>
                            {blocking ? showLoading() : signupForm()}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    );
};

export default Signup