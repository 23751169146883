import React, { useState, useEffect } from "react";
import Header from '../components/layouts/Header'
import { isAuthenticated } from "../api/auth";
import { Link } from "react-router-dom";
import { 
    getPurchaseHistory, 
    getCurrentSubscription,
    updateSubscription,
    cancelSubscription,
    read
  } from "./apiUser";
import moment from "moment";
import {  
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Row,
    Col
 } from "reactstrap";



const Dashboard = () => {
    const [history, setHistory] = useState([]);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [currentUser, setCurrentUser] = useState()
    const [braintreeSubscriptions, setBraintreeSubscriptions] = useState([])
    const [edit, setEdit] = useState(true)

    const toggleSubscriptionModal = () => setSubscriptionModal(!subscriptionModal)

    const {
        user: { _id, name, email, role }
    } = isAuthenticated();
    const token = isAuthenticated().token;

    const init = (userId, token) => {
        // getPurchaseHistory(userId, token).then(data => {
        //     if (data.error) {
        //         console.log(data.error);
        //     } else {
        //         setHistory(data);
        //     }
        // });
    };

    
    const planIdResolver = (p) => {
        let planId1
        if(p === "gb66") { 
            return(<div>
            <p><strong>WEEKLY OPTION<br/>
            9 JUICES OR 6 JUICES AND 3 SMOOTHIES</strong></p>
        </div>)
        }
        if(p === "8bz2") { 
            return(
                <>
                <p><strong>WEEKLY OPTION<br/>
                -2 OUNCES (OR) 10 TEA BAGS OF YOUR CHOICE OF "SIMPLISEDE" HERBAL TEA BLENDS AND -7 JUICES/SMOOTHIES (MIX & MATCH)</strong></p>
                </>
            )
        }
        if(p === "p9cb") { 
            return(
                <>
                <p><strong>WEEKLY OPTION<br />
                2 OUNCES (OR) 10 TEA BAGS OF YOUR CHOICE OF "SIMPLISEDE" HERBAL TEA BLENDS AND 7 JUICES/SMOOTHIES (MIX & MATCH)</strong></p></>
            )
        }
        if(p === "5cdg") { 
            return(
                <>
                <p><strong>BI-WEEKLY OPTION<br />
                4 OUNCES (OR) 15 TEA BAGS OF YOUR CHOICE OF "SIMPLISEDE" HERBAL TEA BLENDS AND 12 JUICES/SMOOTHIES (MIX & MATCH)</strong></p>
                </>
            )
        }
     
    }

    const readCurrentUser = (userId, token) => {
        read(userId, token)
        .then(response => {
            console.log(`response: `, response)
            let braintreeSubscriptions
            if(response.braintreeSubscriptions === undefined) {
                braintreeSubscriptions = []
            }
            setCurrentUser(response)
            braintreeSubscriptions = response.

           
            setBraintreeSubscriptions(braintreeSubscriptions)
        })
        .catch(err => console.log(err) )
    }

    const showCancel = (id) => {
        console.log(`What's my id? `, id)
        if(edit) {
            return (<>
            <Button
            className="btn btn-danger btn-sm"
            onClick={() => handleCancel(id)}
            >Cancel</Button>
        </>)}
    }

    const handleCancel = (id) => {
        cancelSubscription(currentUser._id, token, id)
        .then(response => {
            console.log(`cancel response: `, response)
            
        read(currentUser._id, token)
        .then(response1 => {
            // console.log(`response: `, response)
            setCurrentUser(response1)
            // let { braintreeSubscriptions } = response1
            setBraintreeSubscriptions(response1.braintreeSubscriptions)
        })
        .catch(err => console.log(err) )
        })
    }

    const showModalContent = () => {
        return(            
            <>
            {(braintreeSubscriptions.length > 0 && role === 0) ? braintreeSubscriptions.map((item, i) => {
                let {
                    id,
                    firstBillingDate,
                    planId,
                    price,
                    status,
                    nextBillingDate
                } = item.subscription
              
                if(item.subscriptionItems && item.subscriptionItems.length != 0) {
                    let subscriptionItems = item.subscriptionItems
                    console.log(`subscriptionItems: `, subscriptionItems)
                    return(
                        <>
                        
                        <h4 >Subscription {`${i+1}`}</h4>
                        {status === 'Active' ? <div className="alert alert-success">{status}</div> 
                    : <div className="alert alert-danger">{status}</div>}
                        <div className="row">
                            <Col md={9}>{planIdResolver(planId)}</Col>
                            <Col md={1} sm={12}>{(status === 'Active') ? showCancel(id) : <a href="/membership">Resubscribe</a>}</Col>
                        </div>
                        <div className="row">
                            <div 
                            className="col-6"
                            style={{
                                borderStyle: "dotted"
                            }}
                            >
                                <h5>Subscription Items</h5>
                                
                                {subscriptionItems.map((item, i) => {
                                    return <p>{`${i + 1}. ${item}`}</p>
                                })}
                                
                            </div>
                            <div className="col-4">
                                <p>Price: {price}
                                <br />
                                First Billing Date: {firstBillingDate}<br />
                                Next Billing Date: {nextBillingDate}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div 
                            style={{
                                minHeight: "40px"
                            }}></div>
                        </div>
                        </>
                        )
                } else {
                    console.log(`item: `, item)
                    return(
                        <>
                        <h4>Subscription {`${i+1}`}</h4>
                        {status === 'Active' ? <div className="alert alert-success">{status}</div> 
                    : <div className="alert alert-danger">{status}</div>}
                        <div className="row">
                            <Col md={9}>{planIdResolver(planId)}</Col>
                            <Col md={1} sm={12}>{(status === 'Active') ? showCancel(id) : <a href="/membership">Resubscribe</a>}</Col>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                {/* <label>Subscription Items</label>
                                {subscriptionItems.map((item, i) => {
                                    <p>{item}</p>
                                })} */}
                            </div>
                            <div className="col-4">
                                <p>Price: {price}
                                <br />
                                First Billing Date: {firstBillingDate}<br />
                                Next Billing Date: {nextBillingDate}
                                </p>
                            </div>
                        </div>
                        </>
                        )
                }
                
            }).reverse() : <p>{`No current subscriptions.  Please `}<a href="/membership">create a new subscription</a>.</p>}
            </>
        )
    }

    const SubscriptionsModal = (props) => {
        return(
            <>
            <Button 
            className="btn btn-outline-light"
            id="subscription0"
            onClick={() => {
                // plan0()
                toggleSubscriptionModal()
                }
            }
            >Manage Subscriptions</Button>
                <Modal 
                isOpen={subscriptionModal}
                toggle={toggleSubscriptionModal}
                size="lg"
                id="subModal"
                >
                    <ModalHeader toggle={toggleSubscriptionModal}>SUBSCRIPTIONS</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md="10">
                                        {showModalContent()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
                </Modal>
            </>
        )
    }

    useEffect(() => {
        init(_id, token)
        readCurrentUser(_id, token)
    }, []);

    const userLinks = () => {
        return (
            <div className="card">
                <h4 className="card-header">User Links</h4>
                <ul className="list-group">
                    <li className="list-group-item">
                        <Link className="nav-link" to="/user/createquote">
                        <span style={{color: "black"}}>Create Quote</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/user/myquotes/${_id}`}>
                        <span style={{color: "black"}}>My Quotes</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/profile/${_id}`}>
                            <span style={{color: "black"}}>Update Profile</span>
                        </Link>
                    </li>
                    {/* <li className="list-group-item">
                    <SubscriptionsModal />
                    </li> */}
                </ul>
            </div>
        );
    };

    const userInfo = () => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">User Information</h3>
                <ul className="list-group">
                    <li style={{color: "black"}} className="list-group-item">{name}</li>
                    <li style={{color: "black"}} className="list-group-item">{email}</li>
                    <li style={{color: "black"}} className="list-group-item">
                        {role === 1 ? "Admin" : "Registered User"}
                    </li>
                </ul>
            </div>
        );
    };

    const purchaseHistory = history => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">Quotes history</h3>
                <ul className="list-group">
                    <li className="list-group-item">
                        {history.map((h, i) => {
                            return (
                                <div>
                                    <hr />
                                    {h.products.map((p, i) => {
                                        return (
                                            <div key={i} 
                                            style={{color: "black"}}
                                            >
                                                <h6>Product name: {p.name}</h6>
                                                <h6>
                                                    Product price: ${p.price}
                                                </h6>
                                                <h6>
                                                    Purchased date:{" "}
                                                    {moment(
                                                        p.createdAt
                                                    ).fromNow()}
                                                </h6>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div
            className="container-fluid"
        >
            
            <div className="row" style={{height: "220px"}}>
                <Header />
            </div>
            <div className="row">
                <div className="col-md-3">{userLinks()}</div>
                <div className="col-md-9">
                    {userInfo()}
                    {/* {purchaseHistory(history)} */}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
