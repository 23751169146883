import React, { useState, useEffect } from "react";
import { updateItem } from "../../../api/apiAdmin";
import { isAuthenticated } from "../../../api/auth";
import { Redirect } from "react-router-dom";

const UpdateItemComponent = ({ data }) => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    user: "",
    _id: "",
    createdAt: "",
    error: "",
    redirectUser: false,
  });

  const [response, setResponse] = useState({
    subject: "",
    body: "",
    quote1: 0,
    loading: false,
    errorResponse: "",
  });

  const { token } = isAuthenticated();

  const { 
      subject, 
      body, 
      quote1, 
      loading, 
      errorResponse 
    } = response;

  const { 
        name, 
        description, 
        user, 
        _id, 
        createdAt,
        error, 
        redirectUser 
    } = values;

  const init = (data) => {
    setValues({
      ...values,
      name: data.itemname,
      description: data.itemdesc,
      user: data.itemuser,
      _id: data.quoteId,
      createdAt: new Date(data.createdAt).toLocaleString(),
    });
  };

  useEffect(() => {
    init(data);
  }, [data]);

  const showRedirect = () => {
    if (redirectUser) {
      return <Redirect to="/user/datatables" />;
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault()
    updateItem(token, values)
      .then((response) => {
        if (response.error) {
          setValues({ ...values, error: response.error });
        }
        console.log(
          `Line 38  - DatatableV1/UpdateItemComponennt.js - response: `,
          response
        );
        setValues({
          ...values,
          name: response.name,
          description: response.description,
          user: response.user,
          redirectUser: true,
          _id: response._id,
        });
      })
      .catch((err) => {
        console.log(`Line 53 - DatatableV1/UpdateItemComponent.js - err`, err);
        setValues({ ...values, error: err });
      });
  };

  const handleChange = (name) => (e) => {
    e.preventDefault();
    let value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeResponse = (name) => (e) => {
    e.preventDefault();
    let value = e.target.value;
    setResponse({
      ...response,
      [name]: value,
    });
  };

  const showError = () => {
    if (error) {
      return <div>{error}</div>;
    }
  };

  const responseForm = () => {
    return (
      <>
        <form>
            <div className="col-sm">

            <div className="form-group">
            <input
              type="text"
              value={subject}
              onChange={handleChangeResponse("subject")}
              placeholder="Subject"
            />
          </div>
          <div className="form-group">
            <textarea
              type="text"
              value={body}
              onChange={handleChangeResponse("body")}
              placeholder="Body"
              rows={10}
              cols={50}
            />
          </div>

            </div>
            <div className="col-sm">
            <div className="form-group">
                <label><strong>QUOTE AMOUNT</strong></label><br />
              <input
                type="number"
                onChange={handleChangeResponse("quote1")}
                value={quote1}
                />
          </div>
          <div className="form-group">
              <button className="btn btn-outline-primary">Send response</button>
          </div>

            </div>
          
          
        </form>
      </>
    );
  };

  return (
    <>
      <div className="container">
        {showError()}
        {showRedirect()}
        <div className="row my-5">
            <div className="col-lg-6">
                <h2>CLIENT</h2>
                <div style={{ border: "1px dotted" }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <h5>Created Date:</h5>
                        </div>
                        <div className="col-sm-9">
                            <p  style={{fontSize: "15px"}}>{createdAt}</p>
                        </div>
                    </div>

                    <hr />
                    <div className="row">

                    <div className="col-sm-6">
                            <h5>Job Title:</h5>
                        </div>
                        <div className="col-sm-9">
                            <p  style={{fontSize: "15px"}}>{name}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-3">
                            <h5>Job Details:</h5>
                        </div>
                        <div className="col-sm-9">
                            <p style={{fontSize: "15px"}}>
                                {description.split(`\n`).map((item, i) => {
                                    return (
                                        <>
                                        <p key={i}>{item}</p>
                                        </>
                                        );
                                    })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6" style={{borderLeft: "0.5px solid black"}}>
                <h2>ADMIN</h2>
                <h4>Response</h4>
                {responseForm()}
            </div>
        </div>
      </div>
    </>
  );
};

export default UpdateItemComponent;
