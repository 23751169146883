import React, { Component } from 'react';



class Unisexsidebar extends Component {
  render() {
    return (
      <div className="room-booking-form">
        {/* <h5 className="title">Sizes</h5> */}
        <form action="/contact">
          <div className="input-group">
              <button 
                type='submit'
                className="main-btn btn-filled"
                >get a quote</button>
          </div>
        </form>
      </div>
    );
  }
}

export default Unisexsidebar;
