import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { signout, isAuthenticated } from "../api/auth";
import { itemTotal } from "./cartHelpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "#ff9900" };
    } else {
        return { color: "#ffffff" };
    }
};

const Menu = ({ history }) => (
    <div 
    className="mt-3"
    style={{
        fontSize: "10"
    }}
    >

        <ul className="nav nav-tabs bg-primary">
            <li className="nav-item">
                <Link
                    className="nav-link"
                    style={isActive(history, "/")}
                    to="/"
                >
                    Home
                </Link>
            </li>

            <li className="nav-item">
                <Link
                    className="nav-link"
                    style={isActive(history, "/shop")}
                    to="/shop"
                >
                    Menu
                </Link>
            </li>

{/* 
            <li className="nav-item">
            
                <Link
                    className="nav-link"
                    style={isActive(history, "/cart")}
                    to="/cart"
                >
                    Cart{" "}
                    <sup>
                        <small className="cart-badge">{itemTotal()}</small>
                    </sup>
                </Link>
            </li> */}

            {isAuthenticated() && isAuthenticated().user.role === 0 && (
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={isActive(history, "/user/dashboard")}
                        to="/user/dashboard"
                    >
                        Dashboard
                    </Link>
                </li>
            )}

            {isAuthenticated() && isAuthenticated().user.role === 1 && (
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        style={isActive(history, "/admin/dashboard")}
                        to="/admin/dashboard"
                    >
                        Dashboard
                    </Link>
                </li>
            )}

            {!isAuthenticated() && (
                <Fragment>
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, "/signin")}
                            to="/signin"
                        >
                            Signin
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, "/signup")}
                            to="/signup"
                        >
                            Signup
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, "/membership")}
                            to="/membership"
                        >
                            Membership
                        </Link>
                    </li>
                </Fragment>
            )}

            
            <li className="nav-item">
                <Link
                    className="nav-link"
                    style={isActive(history, "/aboutus")}
                    to="/aboutus"
                >
                    About Us
                </Link>
            </li>

            {isAuthenticated() && (
            <Fragment>
                 <li className="nav-item">
                        <Link
                            className="nav-link"
                            style={isActive(history, "/membership")}
                            to="/membership"
                        >
                            Membership
                        </Link>
                    </li>
                    
                <li className="nav-item">
                    <span
                        className="nav-link"
                        style={{ cursor: "pointer", color: "#ffffff" }}
                        onClick={() =>
                            signout(() => {
                                history.push("/");
                            })
                        }
                    >
                        Signout
                    </span>
                </li>
                {itemTotal() != 0 ? <li>
                    <div
                    className="">
                        <span 
                        className="fa-layers fa-fw"
                        style={{
                            position: "relative",
                            top: "35px",
                            right: "0px"
                        }}
                        >
                            <Link
                            className="nav-link"
                            style={isActive(history, "/cart")}
                            to="/cart"
                            >
                                <FontAwesomeIcon size="2x" icon={faShoppingCart} color="#8B4513" />
                                <sup
                                className="position-relative mt-3"
                                style={{
                                    color: "white",
                                    left: "20px",
                                    position: "relative",
                                    // top: "0px",
                                    verticalAlign: "top"

                                }}>
                                    {itemTotal()}
                                </sup>                            
                            </Link>
                        </span>
                    </div>
                </li> : null}
                
                </Fragment>
            )}
        </ul>
    </div>
);

export default withRouter(Menu);
