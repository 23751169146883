import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../api/auth";
import { Link } from "react-router-dom";
import { getCategories, createCategory, removeCategory } from "./apiAdmin";

const AddCategory = () => {
    // const [name, setName] = useState("");
    // const [error, setError] = useState(false);
    // const [success, setSuccess] = useState(false);
    const [values, setValues] = useState({
        name: '',
        error: false,
        success: false,
        categories: [],
        removed: false,
        reload: false
    });


    const { name, error, success, categories, removed, reload } = values;

    // destructure user and token from localstorage
    const { user, token } = isAuthenticated();

    useEffect(() => {
        loadCategories();
    }, [reload]);

    const loadCategories = () => {
        getCategories().then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setValues({ ...values, categories: data });
            }
        });
    };

    const showCategories = () => {
        return categories.map((c, i) => {
            return (
                <button
                    onDoubleClick={() => deleteConfirm(c._id)}
                    title="Double click to delete"
                    key={i}
                    className="btn btn-outline-primary mr-1 ml-1 mt-3"
                >
                    {c.name}
                </button>
            );
        });
    };

    const deleteConfirm = id => {
        let answer = window.confirm('Are you sure you want to delete this category?');
        if (answer) {
            deleteCategory(id);
        }
    };

    const deleteCategory = id => {
        // console.log('delete', slug);
        removeCategory(id, user._id, token).then(data => {
            setValues({ ...values, error: false, success: false, name: '', removed: !removed, reload: !reload });
            // if (data.error) {
            //     console.log(data.error);
            // } else {
            //     setValues({ ...values, error: false, success: false, name: '', removed: !removed, reload: !reload });
            // }
        });
    };


    // const handleChange = e => {
    //     setError("");
    //     setName(e.target.value);
    // };

    const handleChange = e => {
        setValues({ ...values, name: e.target.value, error: false, success: false, removed: '' });
    };

    // const clickSubmit = e => {
    //     e.preventDefault();
    //     setError("");
    //     setSuccess(false);
    //     // make request to api to create category
    //     createCategory(user._id, token, { name }).then(data => {
    //         if (data.error) {
    //             setError(data.error);
    //         } else {
    //             setError("");
    //             setSuccess(true);
    //         }
    //     });
    // };
    
    const clickSubmit = e => {
        e.preventDefault();
        // console.log('create category', name);
        createCategory(user._id, token, { name }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({ ...values, error: false, success: false, name: '', removed: !removed, reload: !reload });
            }
        });
    };

    const newCategoryFom = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={name}
                    autoFocus
                    required
                />
            </div>
            <button className="btn btn-outline-primary">Create Category</button>
        </form>
    );

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success">{name} is created</h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger">Category should be unique</h3>;
        }
    };


    const showRemoved = () => {
        if (removed) {
            return <p className="text-danger">Category is removed</p>;
        }
    };

    const mouseMoveHandler = e => {
        setValues({ ...values, error: false, success: false, removed: '' });
    };


    const goBack = () => (
        <div className="mt-5">
            <Link to="/admin/dashboard" className="text-warning">
                Back to Dashboard
            </Link>
        </div>
    );

    return (
        <Layout
            title="Add a new category"
            description={`G'day ${user.name}, ready to add a new category?`}
        >
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {showSuccess()}
                    {showError()}
                    {/* {showRemoved()} */}
                    <div onMouseMove={mouseMoveHandler}></div>
                    {newCategoryFom()}
                    {showCategories()}
                    {goBack()}
                </div>
            </div>
        </Layout>
    );
};

export default AddCategory;
