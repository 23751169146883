import React, { useState, useEffect } from "react";
import {
    getProducts,
    getBraintreeClientToken,
    processPayment,
    createOrder
} from "./apiCore";
import { emptyCart } from "./cartHelpers";
import Card from "./Card";
import { isAuthenticated } from "../api/auth";
import { Link } from "react-router-dom";
import "braintree-web";
import DropIn from "braintree-web-drop-in-react";
import { 
    Button, 
    Form,
    FormGroup, 
    Label, 
    Input, 
    FormText, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Container,
    Row,
    Col,
    CardBody
 } from 'reactstrap';

const Checkout = ({ products }) => {
    const [data, setData] = useState({
        loading: false,
        success: false,
        clientToken: null,
        error: "",
        instance: {},
        address: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
    });

    
    const { 
        firstName, 
        lastName, 
        address,
        address1, 
        address2, 
        city, 
        state, 
        zipcode
    } = data;

    const userId = isAuthenticated() && isAuthenticated().user._id;
    const token = isAuthenticated() && isAuthenticated().token;

    const getToken = (userId, token) => {
        getBraintreeClientToken(userId, token).then(data => {
            if (data.error) {
                setData({ ...data, error: data.error });
            } else {
                setData({ clientToken: data.clientToken });
            }
        });
    };

    useEffect(() => {
        getToken(userId, token);
    }, []);

    const handleAddress = event => {
        setData({ ...data, address: event.target.value });
    };

    
    const handleChange = name => event => {
        setData({ ...data, error: false, [name]: event.target.value });
    };


    const getTotal = () => {
        return products.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.price;
        }, 0);
    };

    const showCheckout = () => {
        return isAuthenticated() ? (
            <div>{showDropIn()}</div>
        ) : (
            <Link to="/signin">
                <button className="btn btn-primary">Sign in to checkout</button>
            </Link>
        );
    };


    const buy = () => {
        setData({ loading: true });
        // send the nonce to your server
        // nonce = data.instance.requestPaymentMethod()
        let nonce;
        let getNonce = data.instance
            .requestPaymentMethod()
            .then(data => {
                // console.log(data);
                nonce = data.nonce;
                // once you have nonce (card type, card number) send nonce as 'paymentMethodNonce'
                // and also total to be charged
                // console.log(
                //     "send nonce and total to process: ",
                //     nonce,
                //     getTotal(products)
                // );
                const paymentData = {
                    paymentMethodNonce: nonce,
                    amount: getTotal(products)
                };

                processPayment(userId, token, paymentData)
                    .then(response => {
                        console.log(response);
                        // empty cart
                        // create order
                                            
                        let deliveryAddress = `${firstName} ${lastName}
                        ${address1}, ${address2}
                        ${city}, ${state} ${zipcode}`

                        const createOrderData = {
                            products: products,
                            transaction_id: response.transaction.id,
                            amount: response.transaction.amount,
                            address: deliveryAddress
                        };

                        createOrder(userId, token, createOrderData)
                            .then(response => {
                                emptyCart(() => {
                                    console.log(
                                        "payment success and empty cart"
                                    );
                                    setData({
                                        loading: false,
                                        success: true
                                    });
                                });
                            })
                            .catch(error => {
                                console.log(error);
                                setData({ loading: false });
                            });
                    })
                    .catch(error => {
                        console.log(error);
                        setData({ loading: false });
                    });
            })
            .catch(error => {
                // console.log("dropin error: ", error);
                setData({ ...data, error: error.message });
            });
    };

    const showDropIn = () => (
        <div onBlur={() => setData({ ...data, error: "" })}>
            {data.clientToken !== null && products.length > 0 ? (
                <div>
                    <h>Delivery address:</h>
                    {/* <div className="form-group mb-3">
                        <label className="text-muted">Delivery address:</label>
                        <textarea
                            onChange={handleAddress}
                            className="form-control"
                            value={data.address}
                            placeholder="Type your delivery address here..."
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-muted">Delivery address:</label>
                        <textarea
                            onChange={handleAddress}
                            className="form-control"
                            value={data.address}
                            placeholder="Type your delivery address here..."
                        />
                    </div> */}
                    <Row form>
            <Col xl={6}>
              <FormGroup>
                <Input                                     
                onChange={handleChange("firstName")}
                className="form-control" 
                type="text" 
                placeholder="First Name"
                value={firstName}
                
                />
              </FormGroup>
            </Col>
            <Col xl={6}>
              <FormGroup>
                <Input 
                className="form-control" 
                type="text" 
                placeholder="Last Name"
                onChange={handleChange("lastName")}
                value={lastName}
                />
              </FormGroup>
            </Col>
            
          </Row>
            <Row form>
              <Col xl={8}>
                <FormGroup>
                    <Input 
                    type="text" 
                    value={address1}
                    onChange={handleChange("address1")}
                    name="address1" 
                    id="address1"
                    placeholder="Address"
                    />
                </FormGroup>
              </Col>
              <Col xl={4}>
                <FormGroup>
                    <Input 
                    type="text" 
                    value={address2}
                    onChange={handleChange("address2")}
                    name="address2" 
                    id="address2"
                    placeholder="Apt."
                    />
                </FormGroup>
              </Col>
            </Row>
            
            <Row form>
                <Col xl={12}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={city}
                        onChange={handleChange("city")}
                        name="city" 
                        id="city"                                            
                        placeholder="City"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col xl={8}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={state}
                        onChange={handleChange("state")}
                        name="state" 
                        id="state"                                            
                        placeholder="State"
                        />
                    </FormGroup>
                </Col>
                <Col xl={4}>
                    <FormGroup>
                        <Input 
                        type="text" 
                        value={zipcode}
                        onChange={handleChange("zipcode")}
                        name="zipcode" 
                        id="zipcode"
                        placeholder="Zip"
                        />
                    </FormGroup>
                </Col>
          </Row>
            <div>
                    <DropIn
                        options={{
                            authorization: data.clientToken,
                            paypal: {
                                flow: "vault"
                            }
                        }}
                        onInstance={instance => (data.instance = instance)}
                    />
                    <button onClick={buy} className="btn btn-success btn-block">
                        Pay
                    </button>
    
            </div>
                </div>
            ) : null}
        </div>
    );

    const showError = error => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = success => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
            Thanks! Your payment was successful!
        </div>
    );

    const showLoading = loading =>
        loading && <h2 className="text-danger">Loading...</h2>;

    return (
        <div>
            <h2>Total: ${getTotal()}</h2>
            {showLoading(data.loading)}
            {showSuccess(data.success)}
            {showError(data.error)}
            {showCheckout()}
        </div>
    );
};

export default Checkout;
