import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

// from template
import Preloader from './components/layouts/Preloader';
import Home from './components/pages/Home';
import Hometwo from './components/pages/Hometwo';
import Homethree from './components/pages/Homethree';
import About from './components/pages/About';
import Gallery from './components/pages/Gallery';
import Shirts from './components/pages/Shirts';
import Menu from './components/pages/Menu';
import FAQ from './components/pages/FAQ';
import Offers from './components/pages/Offers';
import Restaurant from './components/pages/Restaurant';
import Blog from './components/pages/Blog';
import Blogdetails from './components/pages/Blogdetails';
import Places from './components/pages/Places';
import Placesdetails from './components/pages/Placesdetails';
import Roomgrid from './components/pages/Roomgrid';
import Roomlist from './components/pages/Roomlist';
import Roomdetails from './components/pages/Roomdetails';
import Unisex from './components/pages/Unisex';
import ManageGalleries from './ManageGalleries';
import ManageSubscribers from './ManageSubscribers';

// from prior frontend
import Signup from './user/Signup'

import Signin from "./user/Signin";
import Membership from "./core/Membership";
import PrivateRoute from "./auth/PrivateRoute";
import Dashboard from "./user/UserDashboard";
import AdminRoute from "./auth/AdminRoute";
import AdminDashboard from "./user/AdminDashboard";
import AddCategory from "./admin/AddCategory";
import AddProduct from "./admin/AddProduct";
// import Shop from "./core/Shop";
import Product from "./core/Product";
import ProductMain from './Product'
import Cart from "./core/Cart";
import Orders from "./admin/Orders";
import Profile from "./user/Profile";
import ManageProducts from "./admin/ManageProducts";
import UpdateProduct from "./admin/UpdateProduct";
import Customers from "./admin/Customers";
// import About from './core/About'
// import Home from './core/Home'
import Contact from './core/Contact'
import ShopNow from './core/ShopNow'
// import Gallery from './core/Gallery'
import CreateQuote from './user/CreateQuote'
// import FAQ from './core/FAQ'
import MyQuotes from "./user/MyQuotes";
import TshirtsRugsEtc from './components/pages/TshirtsRugsEtc';
import PrintLocations from './components/pages/PrintLocations';
import ProductionSchedule from './components/pages/ProductionSchedule';
import PantoneColorMatching from './components/pages/PantoneColorMatching';
import ArtAndGraphicsDesign from './components/pages/ArtAndGraphicsDesign';
import SpecialAndUniqueOrders from './components/pages/SpecialAndUniqueOrders';

function App() {
  return (
      <Router>
        { <Preloader />}
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/home-two' component={Hometwo} />
          <Route path='/home-three' component={Homethree} />
          <Route path='/about' component={About} />
          <Route path='/gallery' component={Shirts} />
          <Route path='/shirts' component={Shirts} />
          <Route path='/menu' component={Menu} />
          <Route path='/faq' component={FAQ} />
          <Route path='/offers' component={Offers} />
          <Route path='/restaurant' component={Restaurant} />
          <Route path='/news' component={Blog} />
          <Route path='/news-details' component={Blogdetails} />
          <Route path='/places' component={Places} />
          <Route path='/places-details' component={Placesdetails} />
          <Route path='/room-grid' component={Roomgrid} />
          <Route path='/shop' component={Roomgrid} />
          <Route path='/room-list' component={Roomlist} />
          <Route path='/room-details' component={Roomdetails} />
          <Route path='/unisex-men-women-youth' component={Unisex} />
          <Route path='/tshirts-rugs-etc' component={TshirtsRugsEtc} />
          <Route path='/print-locations' component={PrintLocations} />
          <Route path='/custom-pantone-color-matching' component={PantoneColorMatching} />
          <Route path='/art-and-graphics-design' component={ArtAndGraphicsDesign} />
          <Route path='/special-and-unique-orders' component={SpecialAndUniqueOrders} />
          <Route path='/contact' component={Contact} />

          // FAQ PAGES
          <Route path='/faq/productionschedule' component={ProductionSchedule} />

          {/* Prior routes taken from _frontend */}
          <Route path="/signin" exact component={Signin} />
          <Route path="/signup" exact component={Signup} />
            <PrivateRoute
                path="/user/dashboard"
                exact
                component={Dashboard}
            />
            <AdminRoute
                path="/admin/dashboard"
                exact
                component={AdminDashboard}
            />
            <AdminRoute
                path="/create/category"
                exact
                component={AddCategory}
            />
            <AdminRoute
                path="/create/product"
                exact
                component={AddProduct}
            />
            <AdminRoute
                path="/admin/product"
                exact
                component={ProductMain}
            />
            <AdminRoute
                path="/admin/products"
                exact
                component={ManageProducts}
            />
            <AdminRoute
                path="/admin/customers"
                exact
                component={Customers}
            />
            <AdminRoute
                path="/admin/managegalleries"
                exact
                component={ ManageGalleries }
            />
            <AdminRoute
                path="/admin/subscriptions"
                exact
                component={ ManageSubscribers }
            />
            <AdminRoute
                path="/admin/product/update/:productId"
                exact
                component={UpdateProduct}
            />
            <Route path="/product/:productId" exact component={Product} />
            <Route path="/cart" exact component={Cart} />
            <AdminRoute path="/admin/orders" exact component={Orders} />
            <PrivateRoute
                path="/profile/:userId"
                exact
                component={Profile}
            />
            <PrivateRoute
                path="/user/myquotes/:userId"
                exact
                component={MyQuotes}
            />
            <PrivateRoute
                path="/user/createquote"
                exact
                component={CreateQuote}
            />
            <PrivateRoute
                path="/admin/products"
                exact
                component={ManageProducts}
            />


        </Switch>
      </Router>
  );
}

export default App;
