import React, { useState } from 'react'
import Header from '../components/layouts/Header'
import {isAuthenticated} from '../api/auth'
import loader1 from '../assets/loaders/loader1.gif'

const CreateQuote = () => {
    const [values, setValues] = useState({
        jobName: "",
        jobDescription: "",
        error: false,
        loading: false,
        success: false
    })

    const {
        jobName,
        jobDescription,
        error,
        loading,
        success
    } = values

    const { user, token } = isAuthenticated()

    const handleChange = name => e => {
        let value = e.target.value
        setValues({...values, [name]: value})
    }

    const createQuote = async () => {
        console.log( `entered createQuote, user._id...`, user._id )
        setValues({...values, loading: true})
        try {
            let endpoint = `${process.env.REACT_APP_API}/quotes/create/${user._id}`
            let headers = { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` }
            let method = `POST`
            let body = JSON.stringify( {
                jobName: jobName,
                jobDescription: jobDescription,
                user: user._id
            } )
            let _quote = await fetch( endpoint, { method, headers, body } )
            _quote = await _quote.json()
            console.log( `_quote...`, _quote )
            setValues({...values, loading: false, success: true})
        } catch ( e ) { console.log( `Got an error in createQuote...`, e );  setValues({...values, loading: false, error: true}) }
    }

    return(
        <div className="container-fluid">
            <div className="row" style={{height: "220px"}}>
                <Header />
            </div>
            <p>
            <a href="/user/dashboard" style={{color: "black"}}>&nbsp;&nbsp;&nbsp;{`<-`} Back to Dashboard</a>
            </p>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-sm">
                        <br />
                        {
                            success ?
                            null
                            : <h1>Create Quote Request</h1>
                        }
                        <br />
                        {
                            loading === false && success === false ?
                            <form>
                            <div className="form-group">
                                <label>Work Request Title</label><br/>
                                <input 
                                    type="text"
                                    className="form-control"
                                    onChange={handleChange("jobName")}
                                    placeholder="Enter work request title"
                                    />
                            </div>

                            <div className="form-group">
                                <label>Work details</label>
                                <textarea 
                                    rows={10}
                                    // cols={170}
                                    className="form-control"
                                    onChange={handleChange("jobDescription")}
                                    type="text"
                                    placeholder="Enter work details"
                                    />
                            </div>
                            <div className="form-group">
                                <input 
                                    type="file" 
                                    className="form-control"
                                    placeholder="Upload photos"
                                />
                            </div>
                        <button onClick={e => { e.preventDefault(); createQuote() }} className="btn btn-outline-primary">Submit</button>
                        </form>
                        : loading === true ?
                        <div><img src={ loader1 } alt="loader" /></div>
                        : success === true ?
                        <div>
                            <h1 style={{color: "green"}}>Quote Request Submitted Successfully</h1>
                        </div> :
                        null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CreateQuote