import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import background from '../../../assets/img/about/header_b.jpg'

class Breadcrumb extends Component {
  render() {
    return (
      <section className="breadcrumb-area" style={{backgroundImage: `url(${background})`}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>&nbsp;</span>
              <h2 className="page-title">About Us</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Home</Link></li>
                <li className="active">About</li>
              </ul>
            </div>
          </div>
        </section>
    );
  }
}

export default Breadcrumb;
