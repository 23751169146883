// Intending for this page to contain a datatable of quotes - pending or non-pending
import React, { useState, useEffect, useCallback } from 'react'
// import { getQuotes } from './apiUser'
import Header from '../components/layouts/Header'
import { isAuthenticated } from '../api/auth'
import loader1 from '../assets/loaders/loader1.gif'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './modal.css'
import moment from "moment";

const MyQuotes = ({match}) => {
    const [modal, setModal] = useState(false);
    const [ edit, setEdit ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ quotes, setQuotes ] = useState([])
    const [ values, setValues ] = useState({
        id: "",
        jobName: "",
        jobDescription: "",
        idx: null,
        responses: [],
        status: ""
    })
    
    const [response, setResponse ] = useState({
        title: "",
        _body: "",
        t: null,
        quotePrice: null
    })


    let { id, jobName, jobDescription, idx, responses, status } = values

    let { title, _body, t, quotePrice } = response

    const toggle = () => setModal(!modal);

    console.log( `match...`, match )
    const _id = match.params.userId
    console.log( `process.env.REACT_APP_API...`, process.env.REACT_APP_API )
    console.log( `_id...`, _id )
    const { token } = isAuthenticated()
    console.log( `token...`, token )

    const getUserQuotes = useCallback(
        async () => {
            console.log( `Running getUserQuotes...` )
            try {
                let endpoint = `${process.env.REACT_APP_API}/quotes/getuserquotes/${_id}`
                let headers = { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                let method = `POST`
                let _quotes = await fetch( endpoint, { method, headers } )
                _quotes = await _quotes.json()
                console.log( `_quotes...`, _quotes )
                setQuotes( _quotes )
            } catch ( e ) { console.log( `Got an error in getUserQuotes...`, e ) }
        }, [_id]
    )

    const updateUserQuote = async () => {
        // e.preventDefault()
        console.log( `entered updateUserQuote...`)
        try {
            let endpoint = `${process.env.REACT_APP_API}/quotes/updatequote/${_id}`
            let headers = { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` }
            let method = `PUT`
            let body = JSON.stringify( { _id: id, jobName, jobDescription })
            let _quotes = await fetch( endpoint, { method, headers, body } )
            _quotes = await _quotes.json()
            console.log( `_quotes...`, _quotes )
            setQuotes( _quotes )
            setEdit( false )
        } catch ( e ) { console.log( `Got an error in updateUserQuote...`, e ) }
    }

    const cancelQuote = async (id_item) => {
        // e.preventDefault()
        console.log( `entered cancelQuote...`)
        try {
            let endpoint = `${process.env.REACT_APP_API}/quotes/cancelquote/${_id}`
            let headers = { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` }
            let method = `PUT`
            let body = JSON.stringify( { _id: id_item })
            let _quotes = await fetch( endpoint, { method, headers, body } )
            _quotes = await _quotes.json()
            console.log( `_quotes...`, _quotes )
            setQuotes( _quotes )
            setEdit( false )
        } catch ( e ) { console.log( `Got an error in cancelQuote...`, e ) }
    }

    const init = useCallback(
        () => { getUserQuotes() }, []
    )

    useEffect(() => { init() }, [init])

    const approveQuote = async (id_item) => {
        try {
            let endpoint = `${process.env.REACT_APP_API}/quotes/approvequote/${_id}`
            let headers = { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` }
            let method = `PUT`
            let body = JSON.stringify( { _id: id_item })
            let _quotes = await fetch( endpoint, { method, headers, body } )
            _quotes = await _quotes.json()
            console.log( `_quotes...`, _quotes )
            setQuotes( _quotes )
            setEdit( false )
        } catch ( e ) { console.log( `Got an error in approveQuote...`, e ) }
    }

    const showCard = (q, index) => {
        return(
            <div key={`prod-${index}`} className="container py-3">
                <div className="row">
                    <div className="col-10">
                        <h3 title={q._id}>{ q.jobName }</h3>
                        {
                            q.quotePrice && q.approved === false ?
                            <p>Current quote: <span title="This quote is pending your approval" style={{ color: 'red' }}>${q.quotePrice}</span>
                            &nbsp;&nbsp;&nbsp;
                            <button onClick={e => {e.preventDefault(); approveQuote(q._id)}} className="btn btn-success">Approve ${q.quotePrice}</button>
                            </p>
                            : q.quotePrice && q.approved === true ?
                            <p>Client approved quote: <span style={{ color: 'green' }}>${q.quotePrice}</span></p>
                            : null
                        }
                        <p>{q.jobDescription}</p>
                        <hr />
                        {
                            q.responses && q.responses.length > 0 ?
                            <h5 className="mt-3">Responses</h5>
                            : null
                        }
                        {
                            q.responses && q.responses.length > 0 ?
                            <div style={{maxHeight: "40vh", overflowY: "auto"}}>
                                {q.responses.map((item, i) => <div key={`response-${i}`} style={{border: "0.5px solid black"}} className="container py-2">
                                <p>From: <strong>{item.from}</strong></p>
                                { moment(item.t).fromNow() }
                                <p>title: {item.title}</p>
                                <p>{item.body}</p>
                            </div>)}
                            </div>
                            : null
                        }
                        <hr/>
                        
                        <p>{quoteModal(q)}</p>
                        {
                            q.status && q.status === `Awaiting Admin Response`  ?
                            <p>Status: <span style={{color: "blue"}}>{q.status}</span></p>
                            : q.status && q.status === `Awaiting User Response`  ?
                            <p>Status: <span style={{color: "red", fontWeight: "bold"}}>{q.status}</span></p>
                            : <p>Status: {q.status}</p>
                        }
                        {
                            q.user && q.user.name ?
                            <p>Created by: {q.user.name}</p>
                            
                            : null
                        }
                        <p>Created date: { moment(q.createdAt).fromNow() }</p>
                        <p>Last updated: { moment(q.updatedAt).fromNow() }</p>
                    </div>
                    <div className="col-2">
                        <div className="button-group">
                            <button onClick={ e => { 
                                e.preventDefault(); 
                                setValues({ ...values, id: q._id, jobName: q.jobName, jobDescription: q.jobDescription, idx: index, status: q.status }); 
                                setEdit(true);  
                                } } className="btn btn-sm btn-info mt-3">Edit</button>&nbsp;
                            <button onClick={ e => { 
                                e.preventDefault(); 
                                setValues({ ...values, status: `Cancelled` }); 
                                cancelQuote(q._id) 
                                } }  className="btn btn-sm btn-danger mt-3">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const editCard = (q, index) => {
        return(
                <div key={`prod-${index}`}>
                    <div className="col-10">
                        {
                            loading === false ?
                            <form>
                            <div className="form-group mt-3">
                                <input 
                                    className="form-control"
                                    type="text"
                                    value={jobName}
                                    onChange={e => setValues({...values, jobName: e.target.value})}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <textarea 
                                    className="form-control"
                                    rows="12"
                                    cols="70"
                                    value={jobDescription}
                                    onChange={e => setValues({...values, jobDescription: e.target.value})}
                                />
                            </div>
                            <div className="btn-group">
                                
                        <button onClick={ updateUserQuote } className="btn btn-sm btn-primary mt-3">Save</button>&nbsp;
                        <button onClick={ e => { e.preventDefault(); setValues({...values, jobName: q.jobName, jobDescription: q.jobDescription }); setEdit(false) }} className="btn btn-sm btn-danger mt-3">Cancel</button>
                            </div>
                            <hr/>
                            {
                                q.user && q.user.name ?
                                <p>Created by: {q.user.name}</p>
                                
                                : null
                            }
                            <p>Created date: {q.createdAt}</p>
                        </form>
                        : <img src={ loader1 } alt="loader" />
                        }
                    </div>
                    <div className="col-2">
                    </div>
                </div>
        )
    }

    const quoteCard = ( q, index ) => {
        return (
            <div className="card my-1" style={{ backgroundColor: "white", border: "0.5px solid black"}}>
                {
                    q.jobName && edit === false ?
                    showCard(q, index)
                    : q.jobName && edit === true && idx === index ?
                    editCard(q, index)
                    : showCard(q, index)
                }
                {/* { JSON.stringify( q )} */}
            </div>
        )
    }

    
    const quoteModal = (item) => {
        return(
            
        <div>
        <Button color="primary" onClick={e => {e.preventDefault(); setValues({
            ...values, 
            id: item._id, 
            jobName: item.jobName, 
            jobDescription: item.jobDescription,
            responses: item.responses
            }); toggle()}}>Respond</Button>
        <Modal size="xl" isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
          <ModalHeader toggle={toggle}><h2>{jobName}</h2></ModalHeader>
          <div className="container-fluid pb-5">
            _id: {id}
            <p className="mb-4">{jobDescription}</p>
            {
                responses && responses.length > 0 ?
                <>
                    <div style={{ maxHeight: "40vh", overflowY: "auto"}}>
                    <h4>History of Responses</h4>
                    {
                        responses.map((item, i) => <div key={`response-${i}`} style={{border: "0.5px solid black"}}>
                        {item.t}
                        <p>title: {item.title}</p>
                        <p>{item.body}</p>
                    </div>)
                    }
                    </div>
                </>
                : null
            }
          </div>
          <ModalBody>
              <h4>Send A Message To G&K Apparel Print Below</h4>
               <form>
                   <div className="form-group">
                        <input 
                            className="form-control"
                            type="text"
                            value={title}
                            onChange={e => setResponse({...response, title: e.target.value})}
                            placeholder="Enter title"
                        />
                   </div>
                   <div className="form-group">
                        <textarea 
                            className="form-control"
                            value={_body}
                            onChange={e => setResponse({...response, _body: e.target.value})}
                            placeholder="Enter body"
                        />
                   </div>
                   <button onClick={ e => {e.preventDefault(); sendUserResponse() }} className="btn btn-primary">Send</button>
               </form>

          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
        )
    }


    const sendUserResponse = async () => {
        try {
            let endpoint = `${process.env.REACT_APP_API}/quotes/response/user`
            let headers = { Accept: "application/json", "Content-Type": "application/json" }
            let method = `POST`
            let body = JSON.stringify({
                _id: id,
                body: _body,
                title,
                t: new Date(),
                quotePrice
            })
            let result = await fetch( endpoint, { method, headers, body } )
            result = await result.json()

        } catch ( e ) { console.log( `Got an error in sendResponse...`, e ) }
    }

    return(
        <div className="container-fluid">
            <div className="row" style={{height: "220px"}}>
                <Header />
            </div>
            <div className="row">
                <p><a href="/user/dashboard" style={{color: "black"}}>&nbsp;&nbsp;&nbsp;{`<-`} Back to Dashboard</a>
                </p>
            </div>
            <div className="row mt-5">
                <div className="col-2"></div>
                <div className="col-sm" style={{ backgroundColor: ""}}>
                    <h1>My Quotes!</h1>
                    <ul style={{ maxHeight: "85vh", overflowY: "auto"}}>
                        {
                            quotes && quotes.length > 0 ? 
                            <div>
                                { quotes
                                .filter(q => q.status !== `Cancelled` ? q : null)
                                .filter(q => q.status !== `Cancelled and refunded` ? q : null)
                                .map( ( q, index ) => quoteCard( q, index ) ) }
                            </div>
                            : <div>
                                <button className="btn btn-primary" onClick={ e => { e.preventDefault(); getUserQuotes() }}>Get Quotes</button>
                            </div>
                        }
                    </ul>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
    )
}

export default MyQuotes