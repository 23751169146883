import React, { useState, useEffect } from 'react'
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { Accordion } from 'react-bootstrap'
import { 
    addNew, 
    deactivateSubscription,
    getAllSubscriptions, 
    reactivateSubscription,
    deleteSubscription,
    updateSubscription
} from '../api/subscription';

const ManageSubscribers = () =>
{
    const [ s, setS ] = useState({
        subscribers: [],
        loading: false,
        chosen: false, // determines if email/firstName/lastName/etc. is loaded by typing or by a click on a subscriber card on the page
        email: "",
        name: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        phone: "",
        active: null,
        active_options: [
            { label: 'Choose Active / Not Active...', value: '' },
            { label: 'true', value: true },
            { label: 'false', value: false },
        ],
    })

    const handleLoadingSubscribers = async () =>
    {
        let a = {}
        try 
        {
            a.subscribers = await getAllSubscriptions()
            setS({ ...s, subscribers: a.subscribers })
        } catch ( e ) {
            
        }
    }

    const init = () =>
    {
        handleLoadingSubscribers()
    }

    useEffect( () => {
        init()
    }, [])


    return(
        <div className="container-fluid">
            <div className="row">
                <div className='mb-3 p-2'>
                    <Link to={`/admin/dashboard`}> {`<--`}&nbsp;Back to Admin Dashboard</Link>
                </div>
            </div>
            <div className="row">
                <h1 className='text-center m-1 p-1'>Manage Subscribers</h1>
            </div>
            <div className="row mt-5">
                <div className="col-md-4 col-sm-12">
                    <div style={{ border: '0.5px dotted gray'}}>
                        <h3 className="text-center m-3 p-1">{ s.chosen ? `Update` : `Add New` } Subscriber</h3>
                        <form>
                            <div id='email-row' className="form-group row p-1">
                                <div className="col-12">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        placeholder='email'
                                        style={{
                                            height: `99%`
                                        }}
                                        value={ s.email }
                                        onChange={ e => {
                                            setS( { ...s, email: e.target.value } )
                                        }}
                                    />
                                </div>
                            </div>
                            <div id='first-last-name-row' className="form-group row p-1">
                                <div className="col-6">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control m-1 mx-auto" 
                                        style={{ width: '95%', height: `99%` }}
                                        placeholder='firstName'
                                        value={ s.firstName }
                                        onChange={ e => {
                                            setS( { ...s, firstName: e.target.value } )
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control m-1 mx-auto" 
                                        style={{ width: '95%', height: `99%` }}
                                        placeholder='lastName'
                                        value={ s.lastName }
                                        onChange={ e => {
                                            setS( { ...s, lastName: e.target.value } )
                                        }}
                                    />
                                </div>
                            </div>
                            <div id='address-row' className="form-group row p-1">
                                <div className="col-7">
                                    <input 
                                        // rows={ 2 }
                                        type="text"
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        placeholder='address1'
                                        value={ s.address1 }
                                        onChange={ e => {
                                            setS( { ...s, address1: e.target.value } )
                                        }}
                                    />
                                </div>
                                <div className="col-5">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        placeholder='address2'
                                        value={ s.address2 }
                                        onChange={ e => {
                                            setS( { ...s, address2: e.target.value } )
                                        }}
                                    />
                                </div>
                            </div>
                            <div id='city-row' className="form-group row p-1">
                                <div className="col-12">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        placeholder='city'
                                        value={ s.city }
                                        onChange={ e => {
                                            setS( { ...s, city: e.target.value } )
                                        }}
                                    />
                                </div>
                            </div>
                            <div id='state-zipcode-row' className="form-group row p-1">
                                <div className="col-6">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        style={{ width: '95%' }}
                                        placeholder='state'
                                        value={ s.state }
                                        onChange={ e => {
                                            setS( { ...s, state: e.target.value } )
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        style={{ width: '95%' }}
                                        className="form-control" 
                                        placeholder='zipcode'
                                        value={ s.zipcode }
                                        onChange={ e => {
                                            setS( { ...s, zipcode: e.target.value } )
                                        }}
                                    />
                                </div>
                            </div>
                            <div id='phone-row' className="form-group row p-1">
                                <div className="col-12">
                                    <input 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        placeholder='phone'
                                        value={ s.phone }
                                        onChange={ e => {
                                            setS( { ...s, phone: e.target.value } )
                                        }}
                                    />
                                </div>
                            </div>
                            <div id='active-row' className="form-group row p-1">
                                <div className="col-12">
                                    <select 
                                        type="text" 
                                        name="" 
                                        id="" 
                                        className="form-control" 
                                        placeholder='active'
                                        value={ s.active }
                                        onChange={ e => {
                                            setS( { ...s, active: e.target.value } )
                                        }}
                                    >
                                        {
                                            s.chosen ? 
                                            s.active_options.map( ( opt, id ) => <option key={ `active-option-${ id }`} value={ opt.value }>{ opt.label }</option>)
                                            : s.active_options.map( ( opt, id ) => <option key={ `active-option-${ id }`} value={ opt.value }>{ opt.label }</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row my-2 p-1">
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <button className="btn btn-primary btn-sm">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-8 col-sm-12 d-flex flex-wrap" style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
                {
                    s.subscribers && s.subscribers.length > 0 ?
                    s.subscribers.map( ( subscriber, id ) => {
                        return <div 
                                key={ id } 
                                className="card m-1 p-1" 
                                style={{ 
                                    border: '1px solid black',
                                    backgroundColor: s.chosen ? 'lightgreen' : '',
                                    maxHeight: '35vh',
                                    overflowY: 'auto'
                                 }}
                                onClick={ e => { 
                                    e.preventDefault()
                                    setS( { 
                                        ...s, 
                                        chosen: !s.chosen,
                                        name: subscriber.name,
                                        firstName: subscriber.firstName,
                                        lastName: subscriber.lastName,
                                        address1: subscriber.address1,
                                        address2: subscriber.address2,
                                        city: subscriber.city,
                                        state: subscriber.state,
                                        zipcode: subscriber.zipcode,
                                        phone: subscriber.phone,
                                        email: subscriber.email,
                                        active: subscriber.active,
                                    } ) 
                                } }
                                >
                            
                            <div>
                                    <sup className='m-1 p-1'>_id: { subscriber._id }</sup>
                                </div>
                                <div>
                                {
                                    subscriber.email ? subscriber.email : `Missing email!`
                                }
                            <div className="card-body container">
                                <div className="row">
                                    <div className="col-6">
                                        {
                                            subscriber.active ?
                                            <p className='text-center' style={{ color: 'green' }}>{ `Active` }</p>
                                            : <p className='text-center' style={{ color: 'red' }}>{ `Not Active` }</p>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <button 
                                            className={ subscriber.active ? "btn btn-outline-danger btn-sm" : "btn btn-secondary btn-sm"
                                            }
                                            onClick={ e => { 
                                                    if ( subscriber.active )
                                                    {
                                                        deactivateSubscription( subscriber.email )
                                                        .then( r => handleLoadingSubscribers() )
                                                        .catch( e => console.log( `Got an error (1)...`, e ) )
                                                        
                                                    } else {
                                                        reactivateSubscription( subscriber.email )
                                                        .then( r => handleLoadingSubscribers() )
                                                        .catch( e => console.log( `Got an error (2)...`, e ) )
                                                    }
                                                }}>
                                                    {
                                                        subscriber.active ?
                                                        `deactivate`
                                                        : `activate`
                                                    }
                                        </button>
                                        <button 
                                            className="btn btn-danger btn-sm m-1"
                                            onClick={ e => {
                                                let r = window.confirm( `Do you really want to delete ${ subscriber.email } from the subscriptions collection?`)
                                                if ( r === true )
                                                {
                                                    deleteSubscription( subscriber.email )
                                                    .then( r => handleLoadingSubscribers() )
                                                    .catch( e => console.log( `Got an error (3)...`, e ) )
                                                }
                                            }}
                                            >delete</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    })
                    : null
                }
                </div>
            </div>


         
        </div>
    )
}

export default ManageSubscribers