import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import DataTable from 'react-data-table-component';
import UpdateItemComponent from './UpdateItemComponent'
import ClearButton from './features/ClearButton'
import TextField from './features/TextField'
import columns from './features/columns'
import { isAuthenticated } from "../../../api/auth";
import { getQuotes, getUserQuotes } from "../../../api";

const MainDatatable = () => {
    // state
    const [mainData, setMainData] = useState([])
    const [error, setError] = useState(false)
    const { user, token } = isAuthenticated()
    const [filterText, setFilterText] = React.useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    // loaders
    const loadData = () => {
        getQuotes(token, user._id).then(data => {
            if (data.error || data == null) {
                console.log(`Line 24 - src/quotes/AdminQuotes/Datatable/index.js data: `, data)
                console.log(data.error);
            } else {
                console.log(`Line 27-src/quotes/AdminQuotes/Datatable/index.js data: `, data)
                setMainData(data);
            }
        });
    };

    // useEffect
    useEffect(() => {
        loadData();
    }, []);


    // filter component
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
         <TextField id="search" type="text" placeholder="Filter By Text" value={filterText} onChange={onFilter} />
         <ClearButton type="button" onClick={onClear}>X</ClearButton>
        </>
       );

    // create datatable array
    const itemList = () => {
        if (mainData.length > 0) {
            return mainData.map((itemz, i) => {
            console.log(`Line 49 - src/core/Datatable/index.js - itemz: `, itemz)
            console.log(`Line 50 - src/core/Datatable/index.js - itemz.jobName: `, itemz.jobName)
            console.log(`Line 51 - src/core/Datatable/index.js - itemz.jobDescription: `, itemz.jobDescription)
            console.log(`Line 52 - src/core/Datatable/index.js - itemz.user: `, itemz.user)
            return {
                itemname: itemz.jobName,
                itemdesc: itemz.jobDescription,
                itemuser: itemz.user,
                quoteId: itemz._id,
                createdAt: itemz.createdAt,
            }
        })
    } else { return [] }
    }

    // create subHeaderComponentMemo    
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
     if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
     }
    };
   
    return [<FilterComponent 
        key="button2" 
        onFilter={e => setFilterText(e.target.value)} 
        onClear={handleClear} 
        filterText={filterText} 
        />,
    ];
   }, [filterText, resetPaginationToggle]);

   // create the datatable
   const itemsDataTable = () => {
    const filteredItems = itemList().filter(item => item.itemname && item.itemname.toLowerCase().includes(filterText.toLowerCase()));
    // const filteredItems =  datatableThoughts()     //fakeUsers.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  

    return (
      <DataTable
        title="Quotes List"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        // selectableRows
        responsive
        expandableRows
        expandableRowsComponent={<UpdateItemComponent data={mainData}/>}
        persistTableHead
      />
    );
  };




    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="text-center">
                        Total {mainData.length} Items
                    </h2>
                    {/* <p>mainData: {JSON.stringify(mainData)}</p> */}
                    <hr />
                    {itemsDataTable()}
                    <br />
                    
                    {/* <p>{JSON.stringify(customers)}</p> */}
                </div>
            </div>
        </div>
    );
};

export default MainDatatable;
