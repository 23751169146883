var API = process.env.REACT_APP_API

// CREATE
export const createQuote = (token, userId, quote) => {
    return fetch(`${API}/quotes/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(quote)
    })
    .then(response => {
        return response.json()
    })
    .catch(e => console.log( e ) )
}

// READ MANY ONE USER
export const getUserQuotes = (token, userId) => {
    return fetch(`${API}/quotes/getuserquotes/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        // body: JSON.stringify()
    })
}

// READ MANY
export const getQuotes = async (token, userId, setQuotes) => {
    try {
        let endpoint = `${API}/quotes/getallquotes/${userId}`
        let headers = { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` }
        let method = `POST`
        let result = await fetch( endpoint, { method, headers } )
        result = await result.json()
        setQuotes(result)
        return result
    } catch ( e ) { console.log( `Got an error in getQuotes...`, e) }
}

// READ ONE
export const getQuote = (token, userId, _id) => {
    return fetch(`${API}/quotes/getquotebyid/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({_id})
    })
}

// UPDATE
export const changeQuote = (token, userId, quote) => {
    return fetch(`${API}/quotes/updatequote/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(quote)
    })
}

// DELETE
export const removeQuote = (token, userId, _id) => {
    return fetch(`${API}/quotes/delete/${userId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({_id})
    })
}
