import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Layout from "./Layout";
import { getCart } from "./cartHelpers";
import Card from "./Card";
import Checkout from "./Checkout";
import Header from "../components/layouts/Header";

const Cart = ( props ) => {
    const [items, setItems] = useState([]);
    const [run, setRun] = useState(false);
    // console.log( `props: `, props )
    const [ s, setS ] = useState({
        date: null,
        quantity: null
    })
    // console.log( `date: `, date )
    // console.log( `quantity: `, quantity )

    useEffect(() => {
        // setItems(getCart());
        setS({ ...s, date: props.location.state.date, quantity: props.location.state.quantity })
    }, [run]);

    const showItems = items => {
        return (
            <div
            >
                <div className="row">
                    <div className="col-6 text-center">
                        <h2 
                className="float-right"
                >Your cart has {`${items.length}`} items</h2>
                    </div>

                </div>
                
                <hr />
                {items.map((product, i) => (
                    <Card
                        cartPage={true}
                        key={i}
                        product={product}
                        showAddToCartButton={false}
                        cartUpdate={true}
                        showRemoveProductButton={true}
                        showVariations={false} 
                        defaultPricing={false}
                        showViewProductButton={false}
                        hidePrice={true}
                        setRun={setRun}
                        run={run}
                    />
                ))}
            </div>
        );
    };

    const noItemsMessage = () => (
        <h2>
            Your cart is empty. <br /> <Link to="/shop">Continue shopping</Link>
        </h2>
    );

    return (
        <div
            title="Cart"
            className="container-fluid"
        >
            
            <div className="row" style={{height: "220px"}}>
                <Header />
            </div>
            <div className="row">
                <div>
                    Order by date: { s.date }
                </div>
                <div>
                    Quantity: { s.quantity }
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    {s.quantity > 0 ? showItems(items) : noItemsMessage()}
                </div>

                <div className="col-md-6 mt-7">
                    <h2 className="mb-4">Your cart summary</h2>
                    <hr />
                    <Checkout products={items} />
                </div>
            </div>
        </div>
    );
};

export default withRouter( Cart );
