import React, { useState, useEffect } from 'react'
import loader1 from '../../assets/loaders/loader1.gif'
import successCheckmark from '../../assets/icons/success-checkmark.jpg'
import {
    showLeafsAndSubcategories,
    showNoLeafResults,
    showSubcategories1,
    showSubcategories2,
    showSubcategories3,
    showSubcategories4,
    leafsGreenShow
} from './leafsAndSubCategories'

import {
    getCategories,
    getLeafsAndSubcategories,
    persistSubcategoryChooseLeafs,
    persistSubcategoryChooseLeafs2,
    persistSubcategoryChooseLeafs3,
    getInventoryItems,
    getInitialInventory
} from './leafsAndSubCategories/api'

const ProductInventory = () => {
    const [loading, setLoading] = useState(false)
    const [titleQuery, setTitleQuery] = useState("")
    const [limit, setLimit] = useState(50)
    const [skip, setSkip] = useState(0)
    const [skipMessage, setSkipMessage] = useState("")
    const [inventory, setInventory] = useState([])
    const [initialInventory, setInitialInventory] = useState([])
    const [leafsAndSubCategories, setLeafsAndSubCategories] = useState([])
    const [subCategories1, setSubcategories1] = useState([])
    const [subCategories2, setSubcategories2] = useState([])
    const [subCategories3, setSubcategories3] = useState([])
    const [subCategories4, setSubcategories4] = useState([])
    const [categories, setCategories] = useState([])
    const [leafs, setLeafs] = useState([])
    const [leafResults, setLeafResults] = useState([])
    
    const init = () => {
        getInventory(); 
        getInitialInventory(setInitialInventory)
        getCategories(setCategories, skip, limit)
    }

    useEffect(() => {
        init()
    }, [])

    
    const chooseLeaf = code => e => { // white subcategories
        e.preventDefault();  
        getLeafsAndSubcategories(code, setLeafsAndSubCategories, setLeafs, setSubcategories1) 
        setSubcategories1([])
        setSubcategories2([])
        setSubcategories3([])
        setLeafs([])
    }  
    const chooseLeaf2 = code => e => { // white subcategories, level 2
        e.preventDefault();
        persistSubcategoryChooseLeafs(code, setSubcategories2, setLeafs)
    }
    const chooseLeaf3 = code => e => { // white subcategories, level 3
        e.preventDefault();
        persistSubcategoryChooseLeafs2(code, setSubcategories3, setLeafs)
    }
    const chooseLeaf4 = code => e => { // white subcategories, level 3
        e.preventDefault();
        persistSubcategoryChooseLeafs3(code, setSubcategories4, setLeafs)
    }
    const getLeaf = code => e => { // green leafs
        e.preventDefault();
        getInventoryItems(code, setLeafResults)
        // getBestSellingItems(code, setLeafResults) 
        // getItemIdCheckItems(code, setItemIdCheckResults) 
        // getEbayCategoryScrapesItems(code, setEbayCategoryScrapesResults)
        // getProductsItems(code, setProductsResults)
    }
  

    const getInventory = async () => {
        try {
            let endpoint = `${process.env.REACT_APP_API}/product/getallitems`
            let headers = { Accept: "application/json", "Content-Type": "application/json"}
            let method = `POST`
            let result = await fetch( endpoint, { method, headers } )
            result = await result.json()
            setInventory(result)
        } catch (e) { console.log(e); }
    }

    const tdStyle = {
        padding: "3px",
        border: "1px solid black"
    }
    const tdStyle2 = {
        padding: "2px"
    }

    const handleTitleSearch = async () => {
        setLoading(true)
        try {
            let endpoint = `${process.env.REACT_APP_API}/overstock/finditem/${titleQuery}/${limit}/${skip}`
            let headers = { Accept: "application/json", "Content-Type": "application/json"}
            let method = `POST`
            let result = await fetch( endpoint, { method, headers } )
            result = await result.json()
            let _skipMessage = `Skipping ${result.skip} of ${result.count}`
            setSkipMessage(_skipMessage)
            setInventory(result.result)
            setLoading(false)
        } catch (e) { console.log(`Got an error in handleTitleSearch...`, e)}
    }

    const showInventory = inventory => {
        return(
            <>
            <div className="sticky-top" style={{backgroundColor: "white", borderTop: "1px solid black"}}>
            <div className="col-md-12 col-sm-12 col-xs-12" style={{overflowX: "scroll"}}>
                <ul style={{display: "flex", flexWrap: "wrap", listStyle: "none"}}>
                    <li className="p-2 m-1">
                         <h5>Root Categories ({`${categories.length}`})</h5>
                        <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
                            <ul style={{listStyle: "none"}}>
                                {categories && categories.length > 0 ? categories.map((c, i) => <li
                                    key={`category-${i}`}
                                    className="list-group-item"
                                    onClick={chooseLeaf(c.code)}
                                >
                                    {c.code} - {c.name}
                                    </li>) : null}
                            </ul>
                        </div>
                    </li>
                    {
                        subCategories1 && subCategories1.length > 0 ? <li className="p-2 m-1">
                        <h5>Level 1 Subcategories ({`${subCategories1.length}`})</h5>
                        {showSubcategories1(subCategories1, getLeaf, chooseLeaf2)}
                        </li> : null
                    }
                    {
                        subCategories2 && subCategories2.length > 0 ? <li className="p-2 m-1">
                        <h5>Level 2 Subcategories ({`${subCategories2.length}`})</h5>
                            {showSubcategories2(subCategories2, getLeaf, chooseLeaf3)}
                        </li>
                        : null
                    }
                    {
                        subCategories3 && subCategories3.length > 0 ? <li className="p-2 m-1">
                        <h5>Level 3 Subcategories ({`${subCategories3.length}`})</h5>
                            {showSubcategories3(subCategories3, getLeaf, chooseLeaf3)}
                        </li>
                        : null
                    }
                    {
                        subCategories4 && subCategories4.length > 0 ? <li className="p-2 m-1">
                        <h5>Level 3 Subcategories ({`${subCategories4.length}`})</h5>
                            {showSubcategories4(subCategories4, getLeaf, chooseLeaf4)}
                        </li>
                        : null
                    }
                    {
                        leafs && leafs.length > 0 ? <li className="p-2 m-1">
                        <h5>Leafs ({`${leafs.length}`})</h5>
                            {leafsGreenShow(leafs, getLeaf, chooseLeaf)}
                        </li>
                        : null
                    }
                </ul>
                
            </div>
                <form>
                    <div className="row">
                        <div className="col-3">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle2}>
                                            Limit
                                        </td>
                                        <td style={tdStyle2}>
                                            <select defaultValue={limit} onChange={e => setLimit(e.target.value)}>
                                                <option value="1">1</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-3">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle2}>
                                            Skip pages
                                        </td>
                                        <td style={tdStyle2}>
                                            <select defaultValue={skip} onChange={e => setSkip(e.target.value)}>
                                                <option value={0}>0</option>
                                                <option value={1}>1</option>
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="col-6">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle2}><button onClick={e => {e.preventDefault(); handleTitleSearch()}} className="btn btn-primary">Search titles</button></td>
                                        <td style={tdStyle2}>
                                            <input 
                                                style={{width: "28rem"}}
                                                className="form-control"
                                                type="search"
                                                onChange={e => setTitleQuery(e.target.value)}
                                                value={titleQuery}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </form>
                <div>
                    {
                        skipMessage ? <span>{skipMessage}</span> : null
                    }
                </div>
            </div>
                <div className="container-fluid" style={{backgroundColor: "", maxHeight: "85vh", overflowY: "auto"}}>
                    <ul style={{display: "flex"}} className="list-group">
                        {
                            loading === false && leafResults.length > 0 ? leafResults.map((_item, i) => {
                                return <li key={`product-${i}`} className="list-item" style={{ borderTop: "1px solid black"}}>
                                <h5>{_item.title}</h5>
                                    <div className="row">
                                        <div className="col-sm">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={tdStyle}>color: </td>
                                                        <td style={tdStyle}>{_item.color}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>size: </td>
                                                        <td style={tdStyle}>{_item.size}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>price: </td>
                                                        <td style={tdStyle}>{_item.price}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>In Stock?: </td>
                                                        <td style={tdStyle}>{_item.availability ? `Yes` : `No`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>Last Check </td>
                                                        <td style={tdStyle}>{_item.updatedAt}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-2">
                                            <form action={`/admin/p/product/${_item._id}`}><button type="submit" className="btn btn-primary mt-3">Open Item</button></form>
                                            
                                        </div>
                                        <div className="col-sm">
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                {_item.pictureURLs && _item.pictureURLs.length > 0 ? 
                                                _item.pictureURLs.map((pic, index) => <img src={pic} key={`prod-${index}`} width="100px" alt="prod"/>) 
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }) :
                            loading === true ?
                            <div><img src={loader1} alt="loader" /></div>
                            : null
                        }
                    </ul>
                </div>
            </>
        )
    }

    const showRandomInventory = (initialInventory) => {
        return(
            <>
            <div className="container-fluid" style={{backgroundColor: "", maxHeight: "85vh", overflowY: "auto"}}>
                    <ul style={{display: "flex"}} className="list-group">
                        {
                            loading === false && initialInventory.length > 0 ? initialInventory.map((_item, i) => {
                                return <li key={`product-${i}`} className="list-item" style={{ borderTop: "1px solid black"}}>
                                <h5>{_item.title}</h5>
                                    <div className="row">
                                        <div className="col-sm">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={tdStyle}>color: </td>
                                                        <td style={tdStyle}>{_item.color}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>size: </td>
                                                        <td style={tdStyle}>{_item.size}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>price: </td>
                                                        <td style={tdStyle}>{_item.price}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>In Stock?: </td>
                                                        <td style={tdStyle}>{_item.availability ? `Yes` : `No`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={tdStyle}>Last Check </td>
                                                        <td style={tdStyle}>{_item.updatedAt}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-2">
                                            <div className="row">
                                                <form><button onClick={e => {e.preventDefault(); window.open(`/admin/p/product/${_item._id}`, '_blank')}} className="btn btn-primary mt-3">Open Item</button></form>
                                            </div>
                                            <div className="row">
                                                {
                                                    _item.amazon && _item.amazon.length > 0 ?
                                                    <div>Amazon: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/amazon/${_item.amazon[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`amazon _id: ${_item.amazon[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.bedbathandbeyond && _item.bedbathandbeyond.length > 0 ?
                                                    <div>Bed Bath & Beyond: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/bedbathandbeyond/${_item.bedbathandbeyond[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`bedbathandbeyond _id: ${_item.bedbathandbeyond[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.cabelas && _item.cabelas.length > 0 ?
                                                    <div>Cabelas: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/cabelas/${_item.cabelas[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`cabelas _id: ${_item.cabelas[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.hayneedle && _item.hayneedle.length > 0 ?
                                                    <div>Hayneedle: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/hayneedle/${_item.hayneedle[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`hayneedle _id: ${_item.hayneedle[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.homedepot && _item.homedepot.length > 0 ?
                                                    <div>Home Depot: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/homedepot/${_item.homedepot[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`homedepot _id: ${_item.homedepot[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.lowes && _item.lowes.length > 0 ?
                                                    <div>Lowes: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/lowes/${_item.lowes[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`lowes _id: ${_item.lowes[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.macys && _item.macys.length > 0 ?
                                                    <div>Macys: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/macys/${_item.macys[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`macys _id: ${_item.macys[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.overstock && _item.overstock.length > 0 ?
                                                    <div>Overstock: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/overstock/${_item.overstock[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`overstock _id: ${_item.overstock[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.sears && _item.sears.length > 0 ?
                                                    <div>Sears: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/sears/${_item.sears[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`sears _id: ${_item.sears[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.target && _item.target.length > 0 ?
                                                    <div>Target: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/target/${_item.target[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`target _id: ${_item.target[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.unbeatablesale && _item.unbeatablesale.length > 0 ?
                                                    <div>Unbeatablesale: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/unbeatablesale/${_item.unbeatablesale[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`unbeatablesale _id: ${_item.unbeatablesale[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.walmart && _item.walmart.length > 0 ?
                                                    <div>Walmart: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/walmart/${_item.walmart[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`walmart _id: ${_item.walmart[0]}`} /></div>
                                                    : null
                                                }
                                                {
                                                    _item.wayfair && _item.wayfair.length > 0 ?
                                                    <div>Wayfair: <img src={successCheckmark}
                                                    onClick={e => {e.preventDefault(); window.open(`/admin/p/wayfair/${_item.wayfair[0]}`, '_blank')}} 
                                                     width="50" alt="success" title={`wayfair _id: ${_item.wayfair[0]}`} /></div>
                                                    : null
                                                }
                                            </div>
                                            
                                        </div>
                                        <div className="col-sm">
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                {_item.pictureURLs && _item.pictureURLs.length > 0 ? 
                                                _item.pictureURLs.map((pic, index) => <img src={pic} key={`prod-${index}`} width="100px" alt="prod"/>) 
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }) :
                            loading === true ?
                            <div><img src={loader1} alt="loader" /></div>
                            : null
                        }
                    </ul>
                </div>
            </>
        )
    }

    return(
        <>
            <div className="container py-3" style={{ borderTop: "1px solid black"}}>
                <div className="row">
                    <div className="col-12">
                        <h3>Product Inventory</h3>
                        {
                            inventory && inventory.length > 0 ?
                            showInventory(inventory)
                            : null
                        }
                        {
                            leafResults.length === 0 && initialInventory && initialInventory.length > 0 ?
                            showRandomInventory(initialInventory)
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductInventory