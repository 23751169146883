import React, { Component } from 'react'
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow'
// import background from '../../assets/img/tshirts/shirtPage/tshirt-rack.jpg'
import background from '../../assets/img/bg/04.jpg'
import SliderComponent from '../sections/SliderComponent';

import galleryimg1 from '../../assets/img/tshirts/shirtPage/01.jpg';
import galleryimg2 from '../../assets/img/tshirts/shirtPage/02.jpg';
import galleryimg3 from '../../assets/img/tshirts/shirtPage/03.jpg';
import galleryimg4 from '../../assets/img/tshirts/shirtPage/04.jpg';
import galleryimg5 from '../../assets/img/tshirts/shirtPage/05.jpg';
import galleryimg6 from '../../assets/img/tshirts/shirtPage/06.jpg';
import galleryimg7 from '../../assets/img/tshirts/shirtPage/07.jpg';
// import galleryimg8 from '../../assets/img/tshirts/shirtPage/08.jpg';
import galleryimg8 from '../../assets/img/tshirts/shirtPage/08.jpg';
import galleryimg9 from '../../assets/img/tshirts/shirtPage/09.jpg';
import galleryimg10 from '../../assets/img/tshirts/shirtPage/10.jpg';
import galleryimg11 from '../../assets/img/tshirts/shirtPage/11.jpg';
import galleryimg12 from '../../assets/img/tshirts/shirtPage/12.jpg';
import galleryimg13 from '../../assets/img/tshirts/shirtPage/13.jpg';
import galleryimg14 from '../../assets/img/tshirts/shirtPage/14.jpg';
import galleryimg15 from '../../assets/img/tshirts/shirtPage/15.jpg';
import galleryimg16 from '../../assets/img/tshirts/shirtPage/16.jpg';
import galleryimg17 from '../../assets/img/tshirts/shirtPage/17.jpg';
import galleryimg18 from '../../assets/img/tshirts/shirtPage/18.jpg'; // rapper guy
import galleryimg19 from '../../assets/img/tshirts/shirtPage/19.jpg'; // rapper guy
import galleryimg20 from '../../assets/img/tshirts/shirtPage/20.jpg'; // rapper guy
import galleryimg21 from '../../assets/img/tshirts/shirtPage/21.jpg'; // rapper guy
import galleryimg22 from '../../assets/img/tshirts/shirtPage/22.jpg'; // rapper guy
import galleryimg23 from '../../assets/img/tshirts/shirtPage/23.jpg'; // rapper guy
import galleryimg24 from '../../assets/img/tshirts/shirtPage/24.jpg'; // rapper guy
import galleryimg25 from '../../assets/img/tshirts/shirtPage/25.jpg'; // rapper guy
import galleryimg26 from '../../assets/img/tshirts/shirtPage/26.jpg'; // rapper guy
import galleryimg27 from '../../assets/img/tshirts/shirtPage/27.jpg'; // rapper guy
import galleryimg28 from '../../assets/img/tshirts/shirtPage/28.jpg'; // rapper guy
import galleryimg29 from '../../assets/img/tshirts/shirtPage/29.jpg'; // rapper guy
import galleryimg30 from '../../assets/img/tshirts/shirtPage/30.jpg'; // rapper guy
import galleryimg31 from '../../assets/img/tshirts/shirtPage/31.jpg'; // rapper guy
import galleryimg32 from '../../assets/img/tshirts/shirtPage/32.jpg'; // rapper guy
import galleryimg33 from '../../assets/img/tshirts/shirtPage/33.jpg'; // rapper guy
import galleryimg34 from '../../assets/img/tshirts/shirtPage/34.jpg'; // rapper guy
import galleryimg35 from '../../assets/img/tshirts/shirtPage/35.jpg'; // rapper guy
import galleryimg36 from '../../assets/img/tshirts/shirtPage/36.jpg'; // rapper guy
import galleryimg37 from '../../assets/img/tshirts/shirtPage/37.jpg'; // rapper guy
import galleryimg38 from '../../assets/img/tshirts/shirtPage/38.jpg'; // rapper guy
import galleryimg39 from '../../assets/img/tshirts/shirtPage/39.jpg'; // rapper guy
import galleryimg40 from '../../assets/img/tshirts/shirtPage/40.jpg'; // rapper guy
import galleryimg41 from '../../assets/img/tshirts/shirtPage/41.jpg'; // rapper guy
import galleryimg42 from '../../assets/img/tshirts/shirtPage/42.jpg'; // rapper guy
import galleryimg43 from '../../assets/img/tshirts/shirtPage/43.jpg'; // rapper guy
import galleryimg44 from '../../assets/img/tshirts/shirtPage/44.jpg'; // rapper guy
import galleryimg45 from '../../assets/img/tshirts/shirtPage/45.jpg'; // rapper guy
import galleryimg46 from '../../assets/img/tshirts/shirtPage/46.jpg'; // rapper guy
import galleryimg47 from '../../assets/img/tshirts/shirtPage/47.jpg'; // rapper guy
import galleryimg48 from '../../assets/img/tshirts/shirtPage/48.jpg'; // rapper guy
import galleryimg49 from '../../assets/img/tshirts/shirtPage/49.jpg'; // rapper guy


const galleryPosts = [
  {
    photo: galleryimg1,
    anitime: '.3s',
  },
  {
    photo: galleryimg2,
    anitime: '.4s',
  },
  {
    photo: galleryimg3,
    anitime: '.5s',
  },
  {
    photo: galleryimg4,
    anitime: '.6s',
  },
  {
    photo: galleryimg15, // long horizontal
    anitime: '1.1s',
  },
  {
    photo: galleryimg6,
    anitime: '.8s',
  },
  {
    photo: galleryimg7,
    anitime: '.9s',
  },
  {
    photo: galleryimg8,
    anitime: '1.1s',
  },
  {
    photo: galleryimg11,
    anitime: '1.1s',
  },
  {
    photo: galleryimg5,
    anitime: '1.1s',
  },
  {
    photo: galleryimg10,
    anitime: '1.1s',
  },
  {
    photo: galleryimg14,
    anitime: '1.1s',
  },
  {
    photo: galleryimg12, // short 1
    anitime: '1.1s',
  },
  {
    photo: galleryimg13, // short 2
    anitime: '1.1s',
  },
  {
    photo: galleryimg9,
    anitime: '.7s',
  },
  {
    photo: galleryimg17, // long horizontal
    anitime: '1.1s',
  },
  {
    photo: galleryimg18, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg19, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg20, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg21, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg22, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg23, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg24, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg25, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg26, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg27, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg28, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg29, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg30, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg31, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg32, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg33, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg34, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg35, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg36, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg37, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg38, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg39, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg40, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg41, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg42, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg43, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg44, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg45, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg46, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg47, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg48, 
    anitime: '1.1s',
  },
  {
    photo: galleryimg49, 
    anitime: '1.1s',
  },
];
class Gallery extends Component {
  render() {
    return (
      <div>
        <Header />
        <br />
        <br />
        <br />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{ backgroundImage: `url(${background})` }}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>The ultimate shirt</span>
              <h2 className="page-title">Gallery</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Home</Link></li>
                <li className="active">Gallery</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== GALLERY START ======*/}
        <section>
          <div className="container">
            <SliderComponent />
          </div>
        </section>
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {galleryPosts.map((item, i) => (
              <ReactWOW key={i} animation="fadeInUp" data-wow-delay={item.anitime}>
              <div className="single-gallery-image">
                <Link to={item.photo} className="popup-image">
                  <img src={item.photo} alt="Images" />
                </Link>
              </div>
              </ReactWOW>
              ))}
            </div>
          </div>
        </section>
        {/*====== GALLERY END ======*/}
        <Footer />
      </div>

    );
  }
}

export default Gallery;
