

export const addNew = async email => 
{
    let a = {}
    a.email = email // PARAM
    try 
    {
        a.endpoint = `${ process.env.REACT_APP_API }/subscriptions/addnew`
        a.method = `POST`
        a.headers = { Accept: 'application/json', "Content-Type": "application/json" }
        a.body = JSON.stringify( { email: a.email } )
        a.response = await fetch( a.endpoint, { method: a.method, headers: a.headers, body: a.body } )
        a.result = await a.response.json()
        return a.result
    } catch ( e ) {
        console.log( `Got an error in addNew... `, e )
        return
    }
}

export const deactivateSubscription = async email => 
{
    let a = {}
    a.email = email // PARAM
    try 
    {
        a.endpoint = `${ process.env.REACT_APP_API }/subscriptions/deactivate`
        a.method = `POST`
        a.headers = { Accept: 'application/json', "Content-Type": "application/json" }
        a.body = JSON.stringify( { email: a.email } )
        a.response = await fetch( a.endpoint, { method: a.method, headers: a.headers, body: a.body } )
        a.result = await a.response.json()
        return a.result
    } catch ( e ) {
        console.log( `Got an error in deactivateSubscription... `, e )
        return
    }
}

export const getAllSubscriptions = async () => 
{
    let a = {}
    try 
    {
        a.endpoint = `${ process.env.REACT_APP_API }/subscriptions/getall`
        a.method = `POST`
        a.headers = { Accept: 'application/json', "Content-Type": "application/json" }
        a.response = await fetch( a.endpoint, { method: a.method, headers: a.headers } )
        a.result = await a.response.json()
        return a.result
    } catch ( e ) {
        console.log( `Got an error in getAllSubscriptions... `, e )
        return
    }
}

export const updateSubscription = async updatedSubscription => 
{
    let a = {}
    try 
    {
        a.endpoint = `${ process.env.REACT_APP_API }/subscriptions/update`
        a.method = `PUT`
        a.headers = { Accept: 'application/json', "Content-Type": "application/json" }
        a.body = JSON.stringify( updatedSubscription )
        a.response = await fetch( a.endpoint, { method: a.method, headers: a.headers, body: a.body } )
        a.result = await a.response.json()
        return a.result
    } catch ( e ) {
        console.log( `Got an error in updateSubscription... `, e )
        return
    }
}

export const reactivateSubscription = async email => 
{
    let a = {}
    a.email = email // PARAM
    try 
    {
        a.endpoint = `${ process.env.REACT_APP_API }/subscriptions/reactivate`
        a.method = `POST`
        a.headers = { Accept: 'application/json', "Content-Type": "application/json" }
        a.body = JSON.stringify( { email: a.email } )
        a.response = await fetch( a.endpoint, { method: a.method, headers: a.headers, body: a.body } )
        a.result = await a.response.json()
        return a.result
    } catch ( e ) {
        console.log( `Got an error in reactivateSubscription... `, e )
        return
    }
}

export const deleteSubscription = async email => 
{
    let a = {}
    a.email = email // PARAM
    try 
    {
        a.endpoint = `${ process.env.REACT_APP_API }/subscriptions/delete`
        a.method = `POST`
        a.headers = { Accept: 'application/json', "Content-Type": "application/json" }
        a.body = JSON.stringify( { email: a.email } )
        a.response = await fetch( a.endpoint, { method: a.method, headers: a.headers, body: a.body } )
        a.result = await a.response.json()
        return a.result
    } catch ( e ) {
        console.log( `Got an error in reactivateSubscription... `, e )
        return
    }
}
