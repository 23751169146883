import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
    emailContactForm
} from "./apiCore";

const ContactForm = ({ authorEmail, emailAddress, setFormShow, formShow, s, setS }) => {
    // const [values, setValues] = useState({
    //     message: '',
    //     name: '',
    //     email: '',
    //     sent: false,
    //     buttonText: 'Send Message',
    //     success: false,
    //     error: false
    // });

    const { message, name, email, sent, buttonText, success, error } = s;


    const loadEmail = (em) => {
        setS({
            ...s,
            email: em

        })
    }

    const ifSetFormShowFalse = () => {
        if(formShow) {
            return(
                <div>
                    <h1>Message Sent</h1>
                </div>
            )
        }
    }

    const clickSubmit = e => {
        e.preventDefault();
        setS({ ...s, buttonText: 'Sending...' });
        setFormShow(false)
        emailContactForm({ authorEmail, name, email, message }).then(data => {
            // if (data.error) {
            //     setS({ ...s, error: data.error });
            // } else {
            //     setS({
            //         ...s,
            //         sent: true,
            //         name: '',
            //         email: '',
            //         message: '',
            //         buttonText: 'Sent',
            //         success: data.success
            //     });
            // }
            setFormShow(false)
        });
    };

    const handleChange = name => e => {
        setS({ ...s, [name]: e.target.value, error: false, success: false, buttonText: 'Send Message' });
    };

    const showSuccessMessage = () => success && <div className="alert alert-info">Thank you for contacting us.</div>;

    const showErrorMessage = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const contactForm = () => {
        return (
            <form onSubmit={clickSubmit} className="pb-5">
                <div className="form-group">
                    <label className="lead">Message</label>
                    <textarea
                        onChange={ e => { setS({ ...s, message: e.target.value })}}
                        type="text"
                        className="form-control"
                        value={s.message}
                        required
                        rows="10"
                    ></textarea>
                </div>

                <div className="form-group">
                    <label className="lead">Name</label>
                    <input type="text" onChange={handleChange('name')} className="form-control" value={name} required />
                </div>

                <div className="form-group">
                    <label className="lead">Email</label>
                    <input
                        type="email"
                        onChange={handleChange('email')}
                        className="form-control"
                        value={email}
                        required
                    />
                </div>

                <div>
                    <button className="btn btn-primary">{buttonText}</button>
                </div>
               
            </form>
        );
    };

    return (
        <React.Fragment>
            {showSuccessMessage()}
            {showErrorMessage()}
            {formShow ? contactForm() : ifSetFormShowFalse()}
        </React.Fragment>
    );
};

export default ContactForm;
