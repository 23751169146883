import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Bookingform = () => 
{
  const [ s, setS ] = useState({
    date: `2022-01-29`,
    quantity: 0
  })
  return (
    <section className="booking-form boxed-layout">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="booking-form-inner">
              <form action="#">
                <div className="row align-items-end">
                  {/* <div className="col-lg-3 col-md-6">
                    <div className="inputs-filed mt-30">
                      <label htmlFor="arrival-date">Arrival Date</label>
                      <div className="icon"><i className="fal fa-calendar-alt" /></div>
                      <input type="text" placeholder="24th march 2020" name="arrival-date" id="arrival-date" />
                    </div>
                  </div> */}
                  <div className="col-lg-3 col-md-6">
                    <div className="inputs-filed mt-30">
                      <label htmlFor="departure-date">Delivery Date</label>
                      <div className="icon"><i className="fal fa-calendar-alt" /></div>
                      <input 
                        type="date" 
                        placeholder="30th august 2022" 
                        name="departure-date" 
                        id="departure-date"
                        value={ s.date }
                        onChange={ e => { setS( { ...s, date: e.target.value } ) } }
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="inputs-filed mt-30">
                      <label htmlFor="guests">Quantity</label>
                      <div  className="nice-select">
                        <input 
                          type="number" 
                          name="" 
                          id="" 
                          value={ s.quantity }
                          onChange={ e => { setS( { ...s, quantity: e.target.value } ) } }
                          className="form-control" 
                        />
                        {/* <select name="guests" id="guests">
                            <option >Select From Here</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="inputs-filed mt-30">
                      {/* {
                        s.date && s.quantity ?
                        <Link to={{ pathname: "/cart", state: { date: s.date, quantity: s.quantity } }}>check availability</Link>
                        : <button onClick={ e => { e.preventDefault(); }}>check availability</button>
                      } */}
                      {/* <button onClick={ e => { e.preventDefault(); }}>check availability</button> */}
                      <Link to={{ pathname: "/contact", state: { date: s.date, quantity: s.quantity } }}>check availability</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
);
}

export default Bookingform;
