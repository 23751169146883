import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Roomsidebar from '../layouts/Roomsidebar';
import Unisexsidebar from '../layouts/Unisexsidebar';
import { Link } from 'react-router-dom';

import background from '../../assets/img/bg/04.jpg'
import pic1 from '../../assets/img/shop/printlocations.jpg'

class ProductionSchedule extends Component {
  render() {
    return (
      <div>
        <Header />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage: `url(${background})`}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>&nbsp;</span>
              <h2 className="page-title">Print Locations</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Home</Link></li>
                <li className="active">Shop</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== ROOM-DETAILS START ======*/}
        <section className="room-details pt-120 pb-90">
          <div className="container">
            <div className="row">
              {/* details */}
              <div className="col-lg-8">
                <div className="deatils-box">
                  <div className="thumb">
                    <img  src={ pic1 } alt="" />
                  </div>
                  {/* <p className='text-center' style={{ fontSize: '20px'}}>
                    We offer the following print locations:
                  </p> */}
                  <br />
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                      {/* <ul style={{ listStyleType: 'circle'}}>
                        <li>Rugs</li>
                        <li>T-Shirts</li>
                        <li>Sweat Shirts</li>
                        <li>Hoodies</li>
                        <li>Crop Tops</li>
                        <li>Tank Tops</li>
                      </ul> */}
                    <div>
                  </div>
                    </div>
                    <div className="col-4"></div>
                  </div>
                  
                </div>
              </div>
              {/* form */}
              <div className="col-lg-4">
                {/* <Unisexsidebar /> */}
              </div>
            </div>
          </div>
        </section>
        {/*====== ROOM-DETAILS END ======*/}
        <Footer />
      </div>

    );
  }
}

export default ProductionSchedule;
