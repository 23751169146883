import { combineReducers } from 'redux';

// Import custom components
import quoteReducer from './quote';
import userReducer from './user'

const rootReducer = combineReducers( {
    quote: quoteReducer,
    userInfo: userReducer,
} );

export default rootReducer;