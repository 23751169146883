import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../api/auth";
import { Link, Redirect } from "react-router-dom";
import ShowImage from "../core/ShowImage";
import { getProductWithImage, getCategories, updateProduct } from "./apiAdmin";
import UpdateProductCard from './UpdateProductCard'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter
} from 'reactstrap'

const UpdateProduct = ({ match }) => {
    const [showImageSignal, setShowImageSignal] = useState(false)
    const [imageFile, setImageFile] = useState(null)
    const [addVar, setAddVar] = useState(0)
    // const [currentProduct, setCurrentProduct] = useState({})
    const [categoryArray, setCategoryArray] = useState([])
    const [categoryName, setCategoryName] = useState([])
    const [addIngredient, setAddIngredient] = useState(0)
    // const [ingredientsArray, setIngredientsArray] = useState([])
    const [ingredientsModal, setIngredientsModal] = useState(false)
    const [priorValues, setPriorValues] = useState({
        priorItemId: "",
        priorName: "",
        priorDescription: "",
        priorCategory: "",
        priorIngredients: [{ 
            number: 0,
            ingredient: "",
            product: ""
        }],
        priorVariations: [],
        priorShipping: "",
        priorPhoto: {},
        priorQuantity: "",
        priorSold: 0,
        priorCreatedAt: "",
        priorUpdatedAt: ""
    })

    
    const {
        priorItemId,
        priorName,
        priorDescription,
        priorCategory,
        priorIngredients,
        priorVariations,
        priorShipping,
        priorPhoto,
        priorQuantity,
        priorSold,
        priorCreatedAt,
        priorUpdatedAt,
    } = priorValues


    const [values, setValues] = useState({
        _id: "",
        name: "",
        description: "",
        category: "",
        categories: [],
        ingredients: [{ 
            number: 0,
            ingredient: "",
            product: ""
        }],
        variations: [],
        shipping: "",
        photo: {},
        sold: 0,
        createdAt: "",
        updatedAt: "",
        loading: false,
        error: false,
        createdProduct: "",
        redirectToProfile: false,
        formData: ""
    })

    const { user, token } = isAuthenticated();
    const {
        _id,
        name,
        description,
        category,
        categories,
        ingredients,
        variations,
        loading,
        photo,
        sold,
        createdAt,
        updatedAt,
        shipping,
        error,
        createdProduct,
        redirectToProfile,
        formData
    } = values

    
    const init = productId => {
        getProductWithImage(productId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                console.log('data from init: ', data)

                // check for undefined variations
                if(data.variations === undefined) {
                    data.variations = []
                }



                // populate the state with the product document
                setValues({
                    ...values,
                    _id: data._id,
                    name: data.name,
                    description: data.description,
                    category: data.category._id,
                    shipping: data.shipping,
                    quantity: data.quantity,
                    photo: data.photo,
                    sold: data.sold,
                    variations: data.variations,
                    ingredients: data.ingredients ? data.ingredients : [{ 
                        number: 0,
                        ingredient: "",
                        product: data.name
                    }],
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                    formData: new FormData()
                });

                // set the prior values
                setPriorValues({
                    priorItemId: data._id,
                    priorName: data.name,
                    priorDescription: data.description,
                    priorCategory: data.category._id,
                    priorIngredients: data.ingredients ? data.ingredients : [{ 
                        number: 0,
                        ingredient: "",
                        product: data.name
                    }],
                    priorVariations: data.variations,
                    priorShipping: data.shipping,
                    priorPhoto: data.photo,
                    priorQuantity: data.quantity,
                    priorSold: data.sold,
                    priorCreatedAt: data.createdAt,
                    priorUpdatedAt: data.updatedAt,
                })
                // set image file
                // setImageFile(URL.createObjectURL(data.photo))
                
            }
        });
    };

    // load categories and set form data
    const initCategories = () => {
        getCategories().then(data2 => {
            if (data2.error) {
                setValues({ ...values, error: data2.error })
            } else {
                console.log(`Categories (data2): `, data2)
                setCategoryArray(data2)
                // setValues({
                //     ...values,
                //     categories: data2
                // })
            }
        });
    };



    

    useEffect(() => {
        init(match.params.productId);
        // load categories
        initCategories();
    }, []);

    const matchUpCategory = () => {
        setValues({
            ...values,
            category: categoryArray.filter((c, i) => {
                if(c._id === category) {
                    return c
                }
            })
        })
    }

    // matchUpCategory()
//     /* 
//     BEGIN VARIATIONS
//     */
   
   const handleVariationChange = (name, num) => event => {
    // num is the iteration number
    // name is the variation property which can be vname, vprice, vshipping, vquantity
    // these are tested next in the following if statements
    const value = event.target.value;
    console.log(`num: `, num);
    event.preventDefault()
    if(variations === [] || variations.length === 0 || variations === undefined) {
        setValues({
            ...values,
            variations: []
        })
    }
    let newV = Array.from(variations)
            
    if(name === "vname") { 
        newV[num].vname = value;
        console.log(`newVariations[numberVal].vname value: `, newV)
    }

    if(name === "vprice") { 
        newV[num].vprice = value;
        console.log(`newVariations[numberVal].vprice value: `, newV)
    }

    if(name === "vshipping") { 
        newV[num].vshipping = value;
        console.log(`newVariations[numberVal].vshipping value: `, newV)
    }

    if(name === "vquantity") { 
        newV[num].vquantity = value;
        console.log(`newVariations[numberVal].vquantity value: `, newV)            
    }
    
    setValues({...values, variations: newV})
    formData.set("variations", JSON.stringify(variations))
};

   const addVariation = (e) => {
    e.preventDefault()
    setAddVar(addVar + 1)
    let oldV = Array.from(variations); // gets current variations
    let n = oldV.length; // get current array position
    console.log(`Current number of variations is: ${n}`);
    let vPost = [{ 
        number: n,
        vname: "",
        vprice: "",
        vquantity: "",
        vshipping: ""
    }]  
    let newV = oldV.concat(vPost);         
    setValues({
        ...values,
        variations: newV,
        error: ""
    })
}

const removeVariation = (e, num) => {
    e.preventDefault();
  
    setValues({
      ...values,
      variations: variations.filter(item => item.number !== num),
      error: ''
    })
    
    formData.set("variations", JSON.stringify(variations));
  };

  const showSelect = (svalue) => {
      let tempArray = [0, 1]
      return (
          <>
          <select >
          {tempArray.map((item, i) => {
          if (svalue === i) {
              return(
                <option value={i}>{i === 0 ? `No`: `Yes`}</option>
              )
          } 
          
          return(
              <option value={i}>{i === 1 ? `Yes` : `No`} </option>
          )
      })}
      </select>
      </>
      )
    
  }

    
   const VariationComponent = () => {
    // first, you must create an empty variation item mapped to the state variations array
    variations && variations.map((item, i) => {
        let count = i + 1
        return(
        <div key={count} className="mb-3" >
        {/* <h4>{`Variation ${count}`}</h4> */}
        {variations[i]._id ? <div  className="form-group">
            <label className="text-muted">{`Variation Database ID#`}</label>
            <input
                type="text"
                className="form-control"
                value={variations[i]._id}
                disabled
            />
        </div> : null}
        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Name`}</label>
            <input
                onChange={handleVariationChange("vname", i)}
                type="text"
                className="form-control"
                value={variations[i].vname}
                placeholder="Add variation name (example: small)"
            />
        </div>
        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Price`}</label>
            <input
                onChange={handleVariationChange("vprice", i)}
                type="number"
                className="form-control"
                value={variations[i].vprice}
                placeholder="Add price"
            />
        </div>


        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Shipping`}</label>
            
            {showSelect(item.vshipping)}
        </div>

        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Quantity`}</label>
            <input
                onChange={handleVariationChange("vquantity", i)}
                type="number"
                className="form-control"
                value={variations[i].vquantity}
            />
        </div>
        {/* <p>{`${variations[i].number}`}</p> */}
        <button onClick={addVariation}>Add variation</button>
        <button onClick={(e) => removeVariation(e, variations[i].number)} className="btn-danger">{`Remove Variation ${count}`}</button>
        </div>
          
        )
    })
} 


//     /* 
//     END VARIATIONS
//     */

//     /* 
//     BEGIN INGREDIENTS
//     */
    const toggleIngredientsModal = () => setIngredientsModal(!ingredientsModal)

    
    const addIngredientFunction = (e) => {
        e.preventDefault()
        setAddIngredient(addIngredient + 1)
        
        let oldI
        if(ingredients === undefined) { oldI = [] }
        else { oldI = Array.from(ingredients) } // gets current ingredients 
        
        let o = oldI.length; // get current array position
        console.log(`Current number of ingredients is: ${o}`);
        let iPost = [{ 
            number: o,
            ingredient: "",
            product: name
        }]  
        let newI = oldI.concat(iPost);         
        setValues({
            ...values,
            ingredients: newI,
            error: ""
        })
        // formData.set("ingredients", JSON.stringify(ingredients))
    }

    
    const showIngredients = () => {
        if (ingredients) {
            return(
            <div
            className="mr-3"
            style={{
                border: "1px solid rgba(192,192,192, 0.8)",
                minWidth: "300px"
                // backgroundColor: 'rgba(192,192,192, 0.8)'
                
            }}
            >
                <h6>Ingredients:</h6>
                    {ingredients && ingredients.map((item, i) => {
                        return(
                            <div 
                            style={{opacity: "1"}}>
                                {item.ingredient}
                            </div>
                        )
                    })}
                    <br />
            </div>
        )}
    }

    const ingredientsCheck = () => {
        return (
            <div>
                <p>Please add ingredients!</p>
            <button 
            className="btn btn-primary"
            onClick={addIngredientFunction}
            >Add ingredient</button>
            </div>)
        
    }

   const removeIngredient = (e, num) => {
    e.preventDefault();
  
    if(num === 1) {
        setValues({
            ...values,
            ingredients: ingredients.filter(item => item.number !== num),
            error: ''
          })
    }
    else {
        setValues({
            ...values,
            ingredients: ingredients.filter(item => item.number !== num),
            error: ''
          })
          
          formData.set("ingredients", JSON.stringify(ingredients));
    }
    
  };


    const ingredientsModalComponent = (props) => {
        return(
            <>
            <Button 
            className="btn btn-outline-light"
            id="subscription0"
            onClick={() => {
                // plan0()
                toggleIngredientsModal()
                }
            }
            >Manage Ingredients</Button>
                <Modal 
                isOpen={ingredientsModal}
                toggle={toggleIngredientsModal}
                size="lg"
                id="subModal"
                >
                    <ModalHeader toggle={toggleIngredientsModal}>INGREDIENTS</ModalHeader>
                    <ModalBody>
                        
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md="10">
                                        {ingredientsCheck()}
                                        {showIngredients()}
                                        {/* {showIngredientModalContent()} */}
                                        {showIngredientModalContent()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
                </Modal>
            </>
        )
    }

    const showIngredientModalContent = () => {
        // first, you must create an empty variation item mapped to the state variations array
        if(!ingredients){
            // return <>{ingredientsCheck()}</>
        }
        if(ingredients) {
            return ingredients.map((item, i) => {
                let count = i + 1
                return(
                    <>
                    <div key={count} className="mb-3" >
                <h4>{`Ingredient ${count}`}</h4>
                <div  className="form-group">
                            <label className="text-muted">{`Ingredient ${count} Name`}</label>
                            <input
                                onChange={handleIngredientChange("ingredient", i)}
                                type="text"
                                className="form-control"
                                value={ingredients[i].ingredient}
                                placeholder="Add ingredient name (example: Damiana)"
                            />
                        </div>
                {/* <p>{`${variations[i].number}`}</p> */}
                <button onClick={addIngredientFunction}>Add ingredient</button>
                <button onClick={(e) => removeIngredient(e, ingredients[i].number)} className="btn-danger">{`Remove ingredient ${count}`}</button>
                </div>
                </>
                )
            })
        }
    }



    const handleIngredientChange = (name, num) => event => {
        // num is the iteration number
        // name is the variation property which can be vname, vprice, vshipping, vquantity
        // these are tested next in the following if statements
        const valueI = event.target.value;
        console.log(`num: `, num);
        event.preventDefault()
        let newI = Array.from(ingredients)
                
        if(name === "ingredient") { 
            newI[num].ingredient = valueI;
            console.log(`newVariations[numberVal].vname value: `, newI)
        }

        if(name === "number") { 
            newI[num].number = valueI;
            console.log(`newI[num].number value: `, newI)
        }

        if(name === "product") { 
            newI[num].product = valueI;
            console.log(`newI[num].product value: `, newI)
        }

        setValues({...values, ingredients: newI})
        formData.set("ingredients", JSON.stringify(newI));


    }
   

//     /* 
//     END INGREDIENTS
//     */


    const handleChange = name => event => {
        if(name === "photo") {
            var value = event.target.files[0]
            setImageFile(value)
            setShowImageSignal(true)
            formData.set(name, value);
            console.log("photo value: ", value)
        } else {
            var value = event.target.value
            setValues({ ...values, [name]: value })
            formData.set(name, value);
        }
        // const value =
        //     name === "photo" ? event.target.files[0] : event.target.value;
        
        
        // setValues({ ...values, [name]: value })
        // formData.set(name, value);
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });
        // name: data.name,
        // description: data.description,
        // category: data.category._id,
        // shipping: data.shipping,
        // quantity: data.quantity,
        // photo: data.photo,
        // sold: data.sold,
        // variations: data.variations,
        // ingredients
        formData.set("name", name)
        formData.set("category", category)
        formData.set("description", description)
        formData.set("ingredients", JSON.stringify(ingredients))
        formData.set("_id", _id)

        updateProduct(match.params.productId, user._id, token, formData).then(
            data => {
                setValues({
                    redirectToProfile: true
                })
                // if (data.error) {
                //     setValues({ ...values, error: data.error });
                // } else {
                //     setValues({
                //         ...values,
                //         name: "",
                //         description: "",
                //         photo: "",
                //         ingredients: [],
                //         price: "",
                //         quantity: "",
                //         loading: false,
                //         error: false,
                //         redirectToProfile: true,
                //         createdProduct: data.name
                //     });
                // }
            }
        );
    };

    const showImageFile = () => {
        if(imageFile) {
        return <><img 
            src={window.URL.createObjectURL(imageFile)} 
            className="img-thumbnail"
            style={{
                width: "200px"
            }}
        />
        </>}
    }

    const newPostForm = () => {
        
        return(
        <>
        <div className="row mt-0">
        </div>
        <div>{showImageFile()}</div>
        <form className="mb-3" onSubmit={clickSubmit}>
        {showIngredients()}
            <h4>Post Photo</h4>
            <div className="form-group">
                <label className="btn btn-secondary">
                    <input
                        onChange={handleChange("photo")}
                        type="file"
                        name="photo"
                        accept="image/*"
                    />
                </label>
            </div>
            
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input
                    onChange={handleChange("name")}
                    type="text"
                    className="form-control"
                    value={name}
                    name="name"
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Description</label>
                <textarea
                    onChange={handleChange("description")}
                    className="form-control"
                    value={description}
                    name="description"
                    rows={8}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Category</label>
                {category ? <select
                    onChange={handleChange("category")}
                    className="form-control"
                    name="category"
                    defaultValue={category}
                >
                    {categoryArray &&
                        categoryArray.map((c, i) => (
                            <option key={i} value={c._id}>
                                {c.name}
                            </option>
                        ))}
                </select> : null }
            </div>
            {/* {variations[i].vshipping === true ? <select
                        onChange={handleVariationChange("vshipping", i)}
                        className="form-control"
                        value={1}
                        defaultValue={1}
                    >
                        <option>Please select</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select> : <select
                        onChange={handleVariationChange("vshipping", i)}
                        className="form-control"
                        value={0}
                        defaultValue={0}
                    >
                        <option>Please select</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                } */}

            <div>
                {ingredientsModalComponent()}
            </div>
            
            <div>
                <div>
                    <button onClick={addVariation}>Add variation</button>
                </div>
            </div>

            {variations && variations.map((item, i) => {
                let count = i + 1
                return(
                <div key={count} className="mb-3" >
                <h4>{`Variation ${count}`}</h4>
                {variations[i]._id ? <div  className="form-group">
                    <label className="text-muted">{`Variation Database ID#`}</label>
                    <input
                        type="text"
                        className="form-control"
                        value={variations[i]._id}
                        disabled
                    />
                </div> : null}
                <div  className="form-group">
                    <label className="text-muted">{`Variation ${count} Name`}</label>
                    <input
                        onChange={handleVariationChange("vname", i)}
                        type="text"
                        className="form-control"
                        value={variations[i].vname}
                        placeholder="Add variation name (example: small)"
                    />
                </div>
                <div  className="form-group">
                    <label className="text-muted">{`Variation ${count} Price`}</label>
                    <input
                        onChange={handleVariationChange("vprice", i)}
                        type="number"
                        className="form-control"
                        value={variations[i].vprice}
                        placeholder="Add price"
                    />
                </div>


                <div  className="form-group">
                    <label className="text-muted">{`Variation ${count} Shipping`}</label>
                    {variations[i].vshipping === true ? <select
                        onChange={handleVariationChange("vshipping", i)}
                        className="form-control"
                        value={1}
                        defaultValue={1}
                    >
                        <option>Please select</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select> : <select
                        onChange={handleVariationChange("vshipping", i)}
                        className="form-control"
                        value={0}
                        defaultValue={0}
                    >
                        <option>Please select</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>}
                    
            {/* {showSelect(variations[i].vshipping)} */}
        </div>

        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Quantity`}</label>
            <input
                onChange={handleVariationChange("vquantity", i)}
                type="number"
                className="form-control"
                value={variations[i].vquantity}
            />
        </div>
        {/* <p>{`${variations[i].number}`}</p> */}
        <button onClick={addVariation}>Add variation</button>
        <button onClick={(e) => removeVariation(e, variations[i].number)} className="btn-danger">{`Remove Variation ${count}`}</button>
        </div>
          
        )
    })}
            <br/>
            <br />
            <button className="btn btn-outline-primary">Update Product</button>
        </form>
        </>
    )
}
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: createdProduct ? "" : "none" }}
        >
            <h2>{`${createdProduct}`} is updated!</h2>
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>Loading...</h2>
            </div>
        );

    const redirectUser = () => {
        if (redirectToProfile) {
            return <Redirect to="/shop" />;
        }
    }

    {/* {imageFile ? <><img 
            src={imageFile} 
            className="img-thumbnail"
            style={{
                width: "200px"
            }}
        />
        </> : <><ShowImage 
                            item={productNew} 
                            url="product"
                            productId={productNew._id}
                        /></>} */}

    const showImageFunction = (imageFile2, values2) => {
        if(imageFile2 && showImageSignal === false) {
            return(
                <>
                    <ShowImage 
                            item={values2} 
                            url="product"
                            productId={values2._id}
                        />
                </>
            )
        }
        else if(showImageSignal === true) {
            return (
                <>
                    <img 
                    src={imageFile} 
                    className="img-thumbnail"
                    style={{
                        width: "200px"
                    }}
                    />
                </>
            )
        }
    }


    return (
        <Layout
            title="Add a new product"
            description={`Hello ${user.name}, ready to add a new product?`}
        >
            <div className="row">
                {/* <div>{JSON.stringify(variations)}</div> */}
                <div className="col-12 col-md-12 col-sm-12 col-lg-6">
                    <h1
                    style={{
                        // textAlign: "center"
                    }}
                    >New Version</h1>
                    <UpdateProductCard 
                    updateProductPage={true}
                    currentProductValues={true}
                    productNew={values}
                    hidePrice={true}
                    showImageFunction={showImageFunction}
                    showImageFile={showImageFile}
                    imageFile={imageFile}
                    showImageSignal={showImageSignal}
                    category={category}
                    categoryArray={categoryArray}
                    ingredients={ingredients}
                    />
                </div>
                {/* <div className="col-12 col-md-12 col-sm-12 col-lg-6">
                    <h1
                    style={{
                        textAlign: "center"
                    }}
                    >Prior Version</h1>
                    <UpdateProductCard 
                    updateProductPage={true}
                    priorProductValues={true}
                    productPrior={priorValues}
                    hidePrice={true}
                    />
                </div>
         */}

                <div className="col-6">
                    <div className="row">
                        <div className="col-4">
                        
                        </div>
                        <div className="col-4">
                        </div>
                        
                    </div>
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                    {/* {JSON.stringify(ingredients)} */}
                    {newPostForm()}
                    {VariationComponent()}
                    {redirectUser()}
                </div>
                
            </div>
            <div className="row">
                
                
               
            </div>
        </Layout>
    );
};

export default UpdateProduct;
