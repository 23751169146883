import React, { useState, useEffect } from 'react'
// import Layout from '../core/Layout'
// import AdminLinks from "../AdminLinks";

const ProductUpdate = ({}, ...props) => {
    const [values, setValues] = useState({
        text: "",
        error: "",
        success: "",
        loading: false,
        showText: false
    })

    let {
        text,
        error,
        success,
        loading,
        showText
    } = values

    const init = () => {
        console.log(`Made it to ProductUpdate! Here is loading: loading, error: error, success: success, and props: ${JSON.stringify(props)}`)
    }

    useEffect(() => {
        init()
    }, [])

    const handleChange = name => e => {
        console.log(`Made it to handleChange!`)
        let value = e.target.value
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleClick = e => {
        e.preventDefault()
        setValues({
            ...values,
            showText: true
        })
    }

    const handleClear = e => {
        e.preventDefault()
        setValues({
            ...values,
            showText: false,
            text: ""
        })
    }

    const showProcessedText = text => {
        return(
            <>
                <div className="container">
                    <p>Here is your text</p>
                    <pre>
                        <code>
                            {JSON.stringify(values.text)}
                        </code>
                    </pre>
                </div>
            </>
        )
    }

    const exampleForm = () => {
        return(
            <>
                <form>
                    <div className="form-group">
                        <input
                            className="form-control" 
                            value={text}
                            onChange={handleChange("text")}
                        />
                    </div>
                    <div className="form-group">
                        <div className="btn-group">
                            <button className="btn btn-primary" onClick={handleClick}>Submit</button>
                            <button className="btn btn-danger" onClick={handleClear}>Clear</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    const introText = () => {
        return(
            <>
                <div className="container" style={{}}>
                    <div className="row">
                        <div className="col-12">
                            <h5>introText</h5>
                            <p>Change me when ready...</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {exampleForm()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                showText ? 
                                showProcessedText()
                                : null 
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return(
        <>
            <div className="container py-3" style={{ borderTop: "1px solid black"}}>
                <div className="row">
                    <div className="col-12">
                        <h3>Product Update</h3>
                        {introText()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductUpdate