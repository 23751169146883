import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../api/auth";
import { Link, Redirect } from "react-router-dom";
import { createProduct, getCategories } from "./apiAdmin";
import {  
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Row,
    Col
 } from "reactstrap";

const AddProduct = () => {
    const [addVar, setAddVar] = useState(0)
    const [addIngredient, setAddIngredient] = useState(0)
    const [ingredientsModal, setIngredientsModal] = useState(false);
    
    const [values, setValues] = useState({
        name: "",
        description: "",
        categories: [],
        photo: "",
        loading: false,
        error: "",
        createdProduct: "",
        redirectToShop: false,
        variations: [],
        ingredients: [],
        formData: ""
    });

    const { user, token } = isAuthenticated();
    const {
        name,
        description,
        price,
        categories,
        photo,
        loading,
        error,
        createdProduct,
        redirectToShop,
        variations,
        ingredients,
        formData
    } = values;

    const toggleIngredientsModal = () => setIngredientsModal(!ingredientsModal)


    // load categories and set form data
    const init = () => {
        getCategories().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    categories: data,
                    ingredients: [],
                    formData: new FormData()
                })

                // setValues({...values, 
                //     ingredients: ingredients.map((item, i) => {
                //         let newIngredient = { 
                //             number: 0,
                //             ingredient: "",
                //             product: name
                //         }
                //         return newIngredient
                //     }) } )
                } })
            }
    //     });
    // };

    useEffect(() => {
        init();
    }, []);

    const handleChange = name => event => {
        const value =
            name === "photo" ? event.target.files[0] : event.target.value;
        formData.set(name, value);
        setValues({ ...values, [name]: value });
    };

    const handleVariationChange = (name, num) => event => {
        // num is the iteration number
        // name is the variation property which can be vname, vprice, vshipping, vquantity
        // these are tested next in the following if statements
        const value = event.target.value;
        console.log(`num: `, num);
        event.preventDefault()
        let newV = Array.from(variations)
                
        if(name === "vname") { 
            newV[num].vname = value;
            console.log(`newVariations[numberVal].vname value: `, newV)
        }

        if(name === "vprice") { 
            newV[num].vprice = value;
            console.log(`newVariations[numberVal].vprice value: `, newV)
        }

        if(name === "vshipping") { 
            newV[num].vshipping = value;
            console.log(`newVariations[numberVal].vshipping value: `, newV)
        }

        if(name === "vquantity") { 
            newV[num].vquantity = value;
            console.log(`newVariations[numberVal].vquantity value: `, newV)            
        }
        
        setValues({...values, variations: newV})
        formData.set("variations", JSON.stringify(variations));
    };


    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });

        createProduct(user._id, token, formData).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    name: "",
                    description: "",
                    photo: "",
                    price: "",
                    quantity: "",
                    variations: [],
                    ingredients,
                    redirectToShop: true,
                    loading: false,
                    createdProduct: data.name
                });

                if(redirectToShop) {
                    return <Redirect to='/shop' />
                }
                // 
            }
        });
        
    };

    const addIngredientFunction = (e) => {
        e.preventDefault()
        setAddIngredient(addIngredient + 1)
        let oldI = Array.from(ingredients); // gets current variations
        let o = oldI.length; // get current array position
        console.log(`Current number of ingredients is: ${o}`);
        let iPost = [{ 
            number: o,
            ingredient: "",
            product: name
        }]  
        let newI = oldI.concat(iPost);         
        setValues({
            ...values,
            ingredients: newI,
            error: ""
        })
    }
    

    const addVariation = (e) => {
        e.preventDefault()
        setAddVar(addVar + 1)
        let oldV = Array.from(variations); // gets current variations
        let n = oldV.length; // get current array position
        console.log(`Current number of variations is: ${n}`);
        let vPost = [{ 
            number: n,
            vname: "",
            vprice: "",
            vquantity: "",
            vshipping: ""
        }]  
        let newV = oldV.concat(vPost);         
        setValues({
            ...values,
            variations: newV,
            error: ""
        })
    }
    
    const removeVariation = (e, num) => {
        e.preventDefault();
      
        setValues({
          ...values,
          variations: variations.filter(item => item.number !== num),
          error: ''
        })
        
        formData.set("variations", JSON.stringify(variations));
      };



    const newPostForm = () => (
        <>
        <div className="row">
    {ingredients.length > 0 ? <div><h6>{`Ingredients: `}</h6><br /><ul>{ingredients.map((item) => <li>{item.ingredient}</li>)}</ul></div> : null}
        </div>
        <form className="mb-3" onSubmit={clickSubmit}>
            <h4>Main Photo</h4>
            <div className="form-group">
                <label className="btn btn-secondary">
                    <input
                        onChange={handleChange("photo")}
                        type="file"
                        name="photo"
                        accept="image/*"
                    />
                </label>
            </div>

            <div className="form-group">
                <label className="text-muted">Main Product Name</label>
                <input
                    onChange={handleChange("name")}
                    type="text"
                    className="form-control"
                    value={name}
                    placeholder="Add main product name"
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Description</label>
                <textarea
                    onChange={handleChange("description")}
                    className="form-control"
                    value={description}
                    placeholder="Add description"
                />
            </div>
            
            <div className="form-group">
                <label className="text-muted">Category</label>
                <select
                    onChange={handleChange("category")}
                    className="form-control"
                >
                    <option>Please select</option>
                    {categories &&
                        categories.map((c, i) => (
                            <option key={i} value={c._id}>
                                {c.name}
                            </option>
                        ))}
                </select>
            </div>
            
            <div>
                {/* <button onClick={addIngredient}>Add ingredient</button> */}
                {ingredients.length >= 0 ? ingredientsModalComponent() : null}
            </div>
            <div>
                {(variations.length === 0) ? <div><button onClick={addVariation}>Add variation</button>
                </div> : null }
            </div>
            {variations ? VariationComponent() : null}
            
            <br />
            <br />
            <button type="submit" className="btn btn-outline-primary">Create Product</button>
        </form>
        </>
    );

    /*
    Begin ingredients component /AddProduct
    */

    const showIngredients = () => {
        return(
            <>
                <ul>
                    {ingredients.map((item, i) => {
                        return(
                            <li>
                                {item.ingredient}
                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }

    const ingredientsCheck = () => {
        if(ingredients.length === 0) {
            return (
            <div>
            <button 
            className="btn btn-primary"
            onClick={addIngredientFunction}
            >Add ingredient</button>
            </div>)
        }

        return null
    }

   const removeIngredient = (e, num) => {
    e.preventDefault();
  
    if(num === 1) {
        setValues({
            ...values,
            ingredients: ingredients.filter(item => item.number !== num),
            error: ''
          })
    }
    else {
        setValues({
            ...values,
            ingredients: ingredients.filter(item => item.number !== num),
            error: ''
          })
          
          formData.set("ingredients", JSON.stringify(ingredients));
    }
    
  };


    const ingredientsModalComponent = (props) => {
        return(
            <>
            <Button 
            className="btn btn-outline-light"
            id="subscription0"
            onClick={() => {
                // plan0()
                toggleIngredientsModal()
                }
            }
            >Manage Ingredients</Button>
                <Modal 
                isOpen={ingredientsModal}
                toggle={toggleIngredientsModal}
                size="lg"
                id="subModal"
                >
                    <ModalHeader toggle={toggleIngredientsModal}>INGREDIENTS</ModalHeader>
                    <ModalBody>
                        {showIngredients()}
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md="10">
                                        {ingredientsCheck()}
                                        {/* {showIngredientModalContent()} */}
                                        {showIngredientModalContent()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
              <ModalFooter>
               {' '}
              </ModalFooter>
                </Modal>
            </>
        )
    }

    const showIngredientModalContent = () => {
        // first, you must create an empty variation item mapped to the state variations array
        return ingredients.map((item, i) => {
            let count = i + 1
            return(
                <>
                <div key={count} className="mb-3" >
            <h4>{`Ingredient ${count}`}</h4>
            <div  className="form-group">
                        <label className="text-muted">{`Ingredient ${count} Name`}</label>
                        <input
                            onChange={handleIngredientChange("ingredient", i)}
                            type="text"
                            className="form-control"
                            value={ingredients[i].ingredient}
                            placeholder="Add ingredient name (example: Damiana)"
                        />
                    </div>
            {/* <p>{`${variations[i].number}`}</p> */}
            <button onClick={addIngredientFunction}>Add ingredient</button>
            <button onClick={(e) => removeIngredient(e, ingredients[i].number)} className="btn-danger">{`Remove ingredient ${count}`}</button>
            </div>
            </>
            )
        })
    }



    const handleIngredientChange = (name, num) => event => {
        // num is the iteration number
        // name is the variation property which can be vname, vprice, vshipping, vquantity
        // these are tested next in the following if statements
        const valueI = event.target.value;
        console.log(`num: `, num);
        event.preventDefault()
        let newI = Array.from(ingredients)
                
        if(name === "ingredient") { 
            newI[num].ingredient = valueI;
            console.log(`newVariations[numberVal].vname value: `, newI)
        }

        if(name === "number") { 
            newI[num].number = valueI;
            console.log(`newI[num].number value: `, newI)
        }

        if(name === "product") { 
            newI[num].product = valueI;
            console.log(`newI[num].product value: `, newI)
        }

        setValues({...values, ingredients: newI})
        formData.set("ingredients", JSON.stringify(ingredients));


    }
   
   const IngredientComponent = () => {
    // first, you must create an empty variation item mapped to the state variations array
    return ingredients.map((item, i) => {
        let count = i + 1
        return(
        <div key={count} className="mb-3" >
        {/* <h4>{`Variation ${count}`}</h4> */}
        <div  className="form-group">
            <label className="text-muted">{`Ingredient ${count} Name`}</label>
            <input
                onChange={handleIngredientChange("vname", i)}
                type="text"
                className="form-control"
                value={variations[i].vname}
                placeholder="Add variation name (example: small)"
            />
        </div>
        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Price`}</label>
            <input
                onChange={handleIngredientChange("vprice", i)}
                type="number"
                className="form-control"
                value={variations[i].vprice}
                placeholder="Add price"
            />
        </div>


        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Shipping`}</label>
            <select
                onChange={handleIngredientChange("vshipping", i)}
                className="form-control"
                value={variations[i].vshipping}
            >
                <option>Please select</option>
                <option value="0">No</option>
                <option value="1">Yes</option>
            </select>
        </div>

        <div  className="form-group">
            <label className="text-muted">{`Variation ${count} Quantity`}</label>
            <input
                onChange={handleIngredientChange("vquantity", i)}
                type="number"
                className="form-control"
                value={variations[i].vquantity}
            />
        </div>
        {/* <p>{`${variations[i].number}`}</p> */}
        <button onClick={addIngredientFunction}>Add variation</button>
        <button onClick={(e) => removeVariation(e, variations[i].number)} className="btn-danger">{`Remove Variation ${count}`}</button>
        </div>
          
        )
    })
} 


    /*
    End ingredients component /AddProduct
    */

    const VariationComponent = () => {
        // first, you must create an empty variation item mapped to the state variations array
        return variations.map((item, i) => {
            let count = i + 1
            return(
			<div key={count} className="mb-3" >
            {/* <h4>{`Variation ${count}`}</h4> */}
            <div  className="form-group">
                <label className="text-muted">{`Variation ${count} Name`}</label>
                <input
                    onChange={handleVariationChange("vname", i)}
                    type="text"
                    className="form-control"
                    value={variations[i].vname}
                    placeholder="Add variation name (example: small)"
                />
            </div>
            <div  className="form-group">
                <label className="text-muted">{`Variation ${count} Price`}</label>
                <input
                    onChange={handleVariationChange("vprice", i)}
                    type="number"
                    className="form-control"
                    value={variations[i].vprice}
                    placeholder="Add price"
                />
            </div>


            <div  className="form-group">
                <label className="text-muted">{`Variation ${count} Shipping`}</label>
                <select
                    onChange={handleVariationChange("vshipping", i)}
                    className="form-control"
                    value={variations[i].vshipping}
                >
                    <option>Please select</option>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
            </div>

            <div  className="form-group">
                <label className="text-muted">{`Variation ${count} Quantity`}</label>
                <input
                    onChange={handleVariationChange("vquantity", i)}
                    type="number"
                    className="form-control"
                    value={variations[i].vquantity}
                />
            </div>
            {/* <p>{`${variations[i].number}`}</p> */}
            <button onClick={addVariation}>Add variation</button>
            <button onClick={(e) => removeVariation(e, variations[i].number)} className="btn-danger">{`Remove Variation ${count}`}</button>
            </div>
              
            )
        })
    } 

	

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: createdProduct ? "" : "none" }}
        >
            <h2>{`${createdProduct}`} is created!</h2>
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>Loading...</h2>
            </div>
        );

    return (
        <Layout
            title="Add a new product"
            description={`G'day ${user.name}, ready to add a new product?`}
        >
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {/* {`${JSON.stringify(values)}`} */}
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                    {newPostForm()}
                    
                </div>
            </div>
        </Layout>
    );
};

export default AddProduct;
