import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import ReactWOW from 'react-wow'

import menugalleryimg1 from '../../../assets/img/menu/menu-gallery-1.jpg';
import menugalleryimg2 from '../../../assets/img/menu/menu-gallery-2.jpg';
import menugalleryimg3 from '../../../assets/img/menu/menu-gallery-3.jpg';

import menuimg1 from '../../../assets/img/menu/01.jpg';
import menuimg2 from '../../../assets/img/menu/02.jpg';
import menuimg3 from '../../../assets/img/menu/03.jpg';
// import menuimg4 from '../../../assets/img/menu/04.jpg';
import menuimg4 from '../../../assets/img/menu/cancelled.png';
// import menuimg5 from '../../../assets/img/menu/05.jpg';
import menuimg5 from '../../../assets/img/menu/quantities.png';
import menuimg6 from '../../../assets/img/menu/06.jpg';
// import menuimg7 from '../../../assets/img/menu/07.jpg';
import menuimg7 from '../../../assets/img/menu/reorder.png';
import menuimg8 from '../../../assets/img/menu/08.jpg';
// import menuimg9 from '../../../assets/img/menu/09.jpg';
import menuimg9 from '../../../assets/img/menu/ordercancellation.png';
import menuimg10 from '../../../assets/img/menu/10.jpg';
import './styles.css'

const menugalleryPosts = [
  {
      photo: menugalleryimg1,
  },
  {
      photo: menugalleryimg2,
  },
  {
      photo: menugalleryimg3,
  },
  {
      photo: menugalleryimg1,
  },
  {
      photo: menugalleryimg2,
  },
  {
    photo: menugalleryimg3,
  },
];
const menuPosts1 = [
  {
      menuanimation: '.3s',
      photo: menuimg1,
      url:'#',
      title:'Liability of Lost Packages',
      desc:'We will make every effort to ensure your items are not lost, stolen  or damaged during shipping.  You would have to contact the postal service your items were shipped with.  WE ARE NOT LIABLE FOR ANY LOST PACKAGES.'
  },
  {
      menuanimation: '.4s',
      photo: menuimg2,
      url:'/faq/productionschedule',
      title:'Production Schedule',
      desc: `1. You send us your artwork/design for review with relevant details about your order. 
      2. All customers orders must be reviewed and approved by a manager before we accept it for printing.
      Garments must be 90%-100% cotton. For known garment manufacturers and brands, garment is sufficient.
      
      3. We will send you a emailed quote outlining the cost once we receive all of the information we need.                                      4. After review and approval from the customer, submit a deposit of 50% of total cost to start your order.                            5. Once received payment, we send you a  confirmation email.                                                 6. Your order is sent to production.     -Typical production times are 10 business days. - Additional time is required to complete jobs with multiple decoration types, locations or finishing services.            7. Production is finished-Submit the remaining 50% total cost payment to complete your payment in full and your order will be shipped, delivered, or pick up.                                                                           -We will notify you by email when your order is ready for pickup or has been shipped to you. - Please allow an additional 1-7 business days transit time for orders that are shipped.   `
  },
  {
      menuanimation: '.5s',
      photo: menuimg3,
      url:'#',
      title:'Shipping',
      desc:'All orders are shipped when production and payments are complete. Depending on quantity and destination determines the shipping price and delivery date. After order has been shipped you will receive a shipment tracking email. Please allow 1-7 business days transit time.'
  },
  {
      menuanimation: '.6s',
      photo: menuimg4,
      url:'#',
      title:'No Returns and No Exchanges',
      desc:'-NO RETURNS and NO EXCHANGES-             ALL SALES ARE FINAL.                        (please note, dimensions are subject to 3/4s of an inch off placement).                         -Certain circumstances are beyond our control. Please note that we cannot be responsible for:                                                • Spelling, punctuation or grammatical errors made by the customer.                        • Inferior quality or low-resolution of uploaded images.                                            • Design errors introduced by the customer in the design creation process.      • Errors in costumes order such as product type or size.                                           If, for any reason, you think there is a problem with your order, please feel free to contact our customer service department to try and resolve any issues.'
  },
  {
      menuanimation: '.7s',
      photo: menuimg5,
      url:'#',
      title:'Quantities',
      desc:'Minimum 10 units per designs                                             Minimum 10 -T-Shirts (toddlers and babies included) -Long Sleeve T-Shirts -Sweat Shirts -Hoodies- Crop Tops -Tank Tops -Tote Bags.                                           RUGS (details apply).'
  },
];
const menuPosts2 = [
  {
      menuanimation: '.3s',
      photo: menuimg6,
      url:'#',
      title:'Payments and Fees',
      desc: `Payments: deposit 50% of total cost.                                     -                                  50% of final cost.              We accept the following payment methods:  -PayPal                                                                                                -                   -Cash App                                           -                   -Venmo                                              -                   -Zelle                                                                                                                                *Service fees do apply. `
  },
  {
      menuanimation: '.4s',
      photo: menuimg7,
      url:'#',
      title:'Reorders',
      desc:'At G&K, we make it as simple as possible to place a reorder with us. We save all details from any order you place with us including artwork/designs and mock ups in our database. You can do this by signing into your online account or by emailing us at info@gkapparelprints.com Give us a call at (310) 461-8847 to place a reorder today!'
  },
  {
      menuanimation: '.5s',
      photo: menuimg8,
      url:'#',
      title:'Graphics Art/Design Requirements',
      desc:`Send design/art to email info@gkapparelprints.com. 
      With a full description of Customer and design details. 
      Please examine your designs carefully and correct any mistakes prior to placing your order. 
      In an effort to keep costs down.
      All Images must be VECTOR FILES 12 INCHES IS OUR MAX WIDTH AND 15 INCHES IS OUR MAX HEIGHT-MEDIUM GRAPHICS ARE APPROXIMATELY 8 INCHES WIDE AND SMALLER GRAPHICS ARE APPROXIMATELY 5 INCHES WIDE. GRAPHIC SIZES MAY VARY AND CAN BE SCALED UP OR DOWN.
      `
  },
  {
      menuanimation: '.6s',
      photo: menuimg9,
      url:'#',
      title:'Order Cancellations',
      desc:'All orders can only be canceled and fully refunded before production starts. If order has been sent and started by production, there will be NO refund.'
  },
  // {
  //     menuanimation: '.7s',
  //     photo: menuimg10,
  //     url:'#',
  //     title:'Terms and Conditions',
  //     desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.'
  // },
];
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      showText: false,
      fullText: "",
      position: null,
      clickedOne: null
     };
  }
  render() {
    const gallerysettings = {
      slidesToShow: 3,
				slidesToScroll: 1,
				fade: false,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 4000,
				arrows: false,
				dots: false,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 500,
						settings: {
							slidesToShow: 1,
						},
					},
				],
    }; 

    const truncateText = ( text, i ) =>
    {
      if ( text.length > 150 ) 
      {
        return <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
           {/* {text.substring(0, 150)} */}
           <>
            <div
              onClick={ e => {
                this.setState({
                  showText: !this.state.showText,
                  clickedOne: i,
                  fullText: text
                })
              }}
            >
              {
                this.state.showText && this.state.clickedOne === i ?
                text
                :
                text.substring( 0, 150 ) + '...'
              }

            </div>
          </>
        </span>
      }
    }



  
  const clickMoreAction = () => {
    return(
      <>
            <div className="container-fluid">
                <button 
                id="aspect-modal-button" 
                className="btn btn-outline-primary btn-sm"
                onClick={ e => {
                  e.preventDefault()
                  this.state.setState( { showModal: !this.state.showModal } )
                    // setS({ ...s, show_item_specifics_bulk_modal: !s.show_item_specifics_bulk_modal })
                }}
                >Add Item Specific</button>
                <div 
                    id="aspect-modal" 
                    className="modal"
                    style={{
                        // display: s.show_item_specifics_bulk_modal ? 'block' : 'none',
                        position: 'fixed',
                        zIndex: 1,
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        overflow: 'auto',
                        backgroundColor: 'rgb(0,0,0)',
                        backgroundColor: 'rgba(0,0,0,0.4)'
                    }}
                    >
                    <div 
                        style={{
                            backgroundColor: '#fefefe',
                            margin: "15% auto",
                            padding: '20px',
                            border: '1px solid #888',
                            width: '80%'
                        }}
                        className="modal-content"
                        >
                        <div class="modal-header">
                            <span 
                                onClick={ e => {
                                    // setShowItemSpecificsModal( false )
                                    // setS({ ...s, show_item_specifics_bulk_modal: false })
                                    this.state.setState( { showModal: false } )
                                }}
                                class="close">&times;</span>
                            <h2>Modal Header</h2>
                        </div>
                        <div class="modal-body">
                            
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <p>Aspect Modal</p>
                                </div>
                            </div>
                        </div>
                            <p>Some text in the Modal Body</p>
                            <p>Some other text...</p>
                        </div>
                        <div class="modal-footer">
                            <h3>Modal Footer</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }

    return (
      <section className="menu-area pt-115 pb-115">
        <div className="container">
          {/* Menu Looop */}
          <div className="menu-loop">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10">
              {menuPosts1.map((item, i) => (
                <ReactWOW key={i} animation="fadeInUp" data-wow-delay={item.menuanimation}>
                <div className="single-menu-box" title={ item.desc }>
                  <div 
                    className="menu-img" style={{backgroundImage: "url("+ item.photo + ")"}}
                    >
                  </div>
                  <div className="menu-desc">
                    <h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
                    <p>
                      {
                        item.desc && item.desc.length > 300 ? truncateText( item.desc, i ) : item.desc
                      }
                    </p>
                    <Link to={item.url} className="menu-link"><i className="fal fa-long-arrow-right" /></Link>
                  </div>
                </div>
                </ReactWOW>
              ))}
              </div>
              <div className="col-lg-6 col-md-10">
              {menuPosts2.map((item, i) => (
                <ReactWOW key={i} animation="fadeInUp" data-wow-delay={item.menuanimation}>
                <div className="single-menu-box" title={ item.desc }>
                  <div className="menu-img" style={{backgroundImage: "url("+ item.photo + ")"}}>
                  </div>
                  <div className="menu-desc">
                    {/* <h4><Link to={item.url}>{item.title}</Link></h4> */}
                    <h4><a href={item.url} target="_blank" rel="noreferrer">{item.title}</a></h4>
                    <p>
                        {
                          item.desc && item.desc.length > 300 ? truncateText( item.desc, i ) : item.desc
                          // item.desc
                        }
                    </p>
                    <Link to={item.url} className="menu-link"><i className="fal fa-long-arrow-right" /></Link>
                  </div>
                </div>
                </ReactWOW>
              ))}
              </div>
            </div>
          </div>
          {/* Gallery */}
          <div className="gallery-wrap mt-100">
            <div className="container">
              <Slider className="row gallery-slider text-center" {...gallerysettings}>
              {menugalleryPosts.map((item, i) => (
                <div key={i} className="col-lg-12">
                  <Link to={item.photo} className="gallery-popup">
                    <img src={item.photo} alt="" />
                  </Link>
                </div>
              ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Menu;
