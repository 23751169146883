import React, { useState, useEffect } from 'react';
import { isAuthenticated } from "../api/auth";
import {
    Col, 
    Row, 
    Card, 
    CardBody,
    CardTitle, 
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Container,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter
} from 'reactstrap'

const PurchasesModal = ({history, getOrder, user, token }) => {
    const [modal, setModal] = useState(false)
    // const { user, token } = isAuthenticated()
    const toggle = () => setModal(!modal)
    // const [values, setValues] = useState({
    //     title: '',
    //     details: '',
    //     history: [],
    //     error: '',
    //     success: '',
    //     slug: '',
    //     formData: ''
    // });

    // const { 
    //     title,
    //     details,
    //     history,
    //     error, 
    //     success,
    //     slug, 
    //     formData 
    // } = values;


    useEffect(() => {
    }, []);

    return (
        <div>
      <Button size="xl" color="secondary" onClick={toggle}>{`View purchases`}</Button>
      <Modal isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
        <ModalHeader toggle={toggle}>Purchases</ModalHeader>
        <ModalBody>
        {history.map((item, i) => {
                      let currentOrder = getOrder(user._id, token, item._id)
                      return(
                          <div
                          style={{
                              font: "normal"
                          }}
                          >
                              <p>Order Date: {item.createdAt}</p>
                              <p>Database ID: {item._id}</p>
                              <p>Variation name: {item.name}</p>
                              <p>Transaction id: {item.transaction_id}</p>
                              <p>Quantity: {item.quantity}</p>
                              {JSON.stringify(currentOrder)}
                              <hr />
                          </div>
                      )
                  })}
        
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </div>
       
    );
};

export default PurchasesModal