import React from "react";
var API = process.env.REACT_APP_API

const ShowImage = ({ item, url, productId }) => {
    
    return (
    <div className="product-img">
        <a href={`/product/${productId}`}>
        <img
            src={`${API}/${url}/photo/${item._id}`}
            // src={`https://www.cookingclassy.com/wp-content/uploads/2019/06/lemonade-2.jpg`}
            alt={item.name}
            className="img-fluid mb-3"
            style={{ maxHeight: "667px", maxWidth: "750px" }}
        />
        </a>
    </div>
)};

export default ShowImage;
