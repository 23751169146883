import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import Breadcrumb from '../sections/menu/Breadcrumb';
import Menuarea from '../sections/menu/Menu';
import FAQContent from '../sections/menu/FAQContent';
import Roomgallery from '../sections/menu/Roomgallery';

import background from '../../assets/img/faq/ties_background.jpg';


const FAQ = () =>
{
    return(
      <div>
        <Header />
        <br />
        <br />
        <br />
        {/*====== BREADCRUMB PART START ======*/}
        {/* <Breadcrumb/> */}
        
        <section className="breadcrumb-area" style={{ backgroundImage: `url(${background})` }}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>The ultimate shirt</span>
              <h2 className="page-title">FAQ/Disclaimers</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Home</Link></li>
                <li className="active">FAQ</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-12">
                <div>
                    <p style={{ textAlign: 'center', fontSize: '28', fontWeight: 'bold' }}>Please review all disclaimers thoroughly before placing an order.</p>
                </div>
            </div>
          </div>
        </div>
        {/*====== MENU AREA START ======*/}
        <FAQContent/>
        {/*====== MENU AREA END ======*/}
        {/*====== ROOM Gallery CTA START ======*/}
        {/* <Roomgallery/> */}
        {/*====== ROOM Gallery CTA END ======*/}
        <Footer />
      </div>
    )
}


export default FAQ;
