import React from 'react'

export const showLeafsAndSubcategories = (leafsAndSubCategories, chooseLeaf2) => {
    console.log(`Made it to showLeafsAndSubcategories!`)
    let _subcategories = leafsAndSubCategories.length > 0 && leafsAndSubCategories[0].subcategories ? leafsAndSubCategories[0].subcategories : []
    let collector = []
    for (let i = 0; i < _subcategories.length; i++) {
        const sub = _subcategories[i];
        collector.push({ code: sub.code, name: sub.name, leaf: false })            
    }
    return <>
        <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
            SUBCATEGORIES ({`${collector.length}`})
            <ul style={{listStyle: "none"}}>
                {collector && collector.length > 0 ? 
                collector.map((c, i) => {
                    return (
                        <li style={{ backgroundColor: "white" }} key={`category-${i}`} className="list-group-item" onClick={chooseLeaf2(c.code)}>
                            {c.code} - {c.name}
                        </li>
                        )
                    }) 
                    : null}
            </ul>
            
        </div>
    </>
}

export const showSubcategories1 = (subCategories1, getLeaf, chooseLeaf) => {
    return(
        <>
            <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
                <ul style={{listStyle: "none"}}>
                    {subCategories1 && subCategories1.length > 0 ? subCategories1.map((c, i) => {
                        return(
                            <li key={`cat-${i}`} style={{backgroundColor: "white"}} onClick={chooseLeaf(c.code)} className="list-group-item">
                                {c.code} - {c.name}
                            </li>
                        )
                    }) 
                    : null}
                </ul>
            </div>            
        </>
    )
}

export const showSubcategories2 = (subCategories2, getLeaf, chooseLeaf) => {
    return(
        <>
            <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
                <ul style={{listStyle: "none"}}>
                    {subCategories2 && subCategories2.length > 0 ? subCategories2.filter(cat => cat.type === 'Subcategory').map((c, i) => {
                        return(
                            <li key={`cat-${i}`} style={{backgroundColor: "white"}} onClick={chooseLeaf(c.code)} className="list-group-item">
                                {c.code} - {c.name}
                            </li>
                        )
                    }) 
                    : null}
                </ul>
            </div>            
        </>
    )
}

export const showSubcategories3 = (subCategories3, getLeaf, chooseLeaf3) => {
    return(
        <>
            <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
                <ul style={{listStyle: "none"}}>
                    {subCategories3 && subCategories3.length > 0 ? subCategories3.filter(cat => cat.type === 'Subcategory').map((c, i) => {
                        return(
                            <li key={`cat-${i}`} style={{backgroundColor: "white"}} onClick={chooseLeaf3(c.code)} className="list-group-item">
                                {c.code} - {c.name}
                            </li>
                        )
                    }) 
                    : null}
                </ul>
            </div>            
        </>
    )
}
export const showSubcategories4 = (subCategories4, getLeaf, chooseLeaf4) => {
    return(
        <>
            <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
                <ul style={{listStyle: "none"}}>
                    {subCategories4 && subCategories4.length > 0 ? subCategories4.filter(cat => cat.type === 'Subcategory').map((c, i) => {
                        return(
                            <li key={`cat-${i}`} style={{backgroundColor: "white"}} onClick={chooseLeaf4(c.code)} className="list-group-item">
                                {c.code} - {c.name}
                            </li>
                        )
                    }) 
                    : null}
                </ul>
            </div>            
        </>
    )
}

export const leafsGreenShow = (leafs, getLeaf, chooseLeaf) => {
    return(
        <>
        {/* <div>leafs... {JSON.stringify(leafs)}</div> */}
            <div style={{overflowY: "scroll", maxHeight: "30vh", width: "300px"}}>
            <ul style={{listStyle: "none"}}>
                {leafs && leafs.length > 0 ? leafs.filter(cat => cat).map((c, i) => {
                    return(
                        <li key={`cat-${i}`} style={{backgroundColor: "lightgreen"}} onClick={getLeaf(c.code)} className="list-group-item">
                            {c.code} - {c.name}
                        </li>
                    )
                }) 
                : null}
            </ul>
            </div>            
        </>
    )
}

export const showNoLeafResults = () => {
    return(
        <div className="container-fluid" style={{border: "1px solid black", backgroundColor: "white"}}>
            <div className="row my-2">
                <div className="col-12">
                    <main>
                        <h3>NO RESULTS!</h3>
                        <p>Choose below how to proceed</p>
                        <section>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{padding: "2px"}}>Scrape leaf</td>
                                        <td style={{padding: "2px"}}><button className="btn btn-primary">Scrape</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    )
}
