import React, { useState } from 'react'
import { createItem } from '../../api/apiAdmin'
import { isAuthenticated } from "../../api/auth";

const FormOfDatatable = ({loading}) => {
    const [values, setValues] = useState({
        name: '',
        description: '',
        error: '',
        success: false
    })

    const { name, description, error, success } = values
    
    const { user, token } = isAuthenticated();

    const handleChange = name => e => {
        let value = e.target.value
        setValues({...values, [name]: value})
    }

    const handleSubmit = () => {
        let item = {
            name,
            description,
            user: user._id
        }
        setValues({...values, success: true})
        createItem(token, item)
        .then(response => {
            console.log(`Line 30 - src/core/FormOfDatatable - response: `, response)
            setTimeout(setValues({
                name: '',
                description: '',
                error: '',
                success: false
            }), 3000)

        })
        .catch(err => {
            console.log(`Line 33 - src/core/FormOfDatatable - err: `, err)
            setValues({
                name: '',
                description: '',
                success: false,
                error: err
            })
        })
    }

    const showSuccess = () => {
        if (success) {
            return(
            <>
                <div style={{
                    backgroundColor: "lightcyan",
                    color: "black"
                }}>
                    <span>Item submitted successfully!</span>
                </div>
            </>
        )}
    }

    const showError = () => {
        if (error) {
            return(
            <>
                <div style={{
                    backgroundColor: "lightred",
                    color: "black"
                }}>
                    <span>Error!  <br/>{error}</span>
                </div>
            </>
        )}
    }

    return(
        <div className="container">
            <div className="row">
                <div className="col-9">
                    {showSuccess()}
                    {showError()}
                    <form onSubmit={handleSubmit}>
                        <div className="form-control">
                            {/* <label for="name">Name</label> */}
                            <input 
                                type="text"
                                onChange={handleChange("name")}
                                value={name}
                                id="name"
                                placeholder="Enter item name"
                                />
                        </div>
                        <div className="form-control">
                            {/* <label for="description">Description</label> */}
                            <textarea 
                                type="text"
                                onChange={handleChange("description")}
                                value={description}
                                id="description"
                                placeholder="Enter item description"
                                rows={7}
                                cols={27}
                                />
                        </div>
                        <div>
                            <button className="btn btn-primary-outline">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default  FormOfDatatable