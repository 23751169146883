import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ShowImage from "../core/ShowImage";
import moment from "moment";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "../styles.css";

const UpdateProductCard = ({
    updateProductPage = false,
    priorProductValues = false,
    currentProductValues = false,
    relatedProduct = false,
    productPage = false,
    cartPage = false,
    shopPage = false,
    showImageFile = null,
    imageFile,
    showImageSignal = false,
    productPrior,
    productNew,
    showImageFunction,
    category,
    categoryArray,
    ingredients,
    showViewProductButton = true,
    showAddToCartButton = true,
    cartUpdate = false,
    hidePrice = false,
    showVariations = true,
    showRemoveProductButton = false,
    defaultPricing = true,
    setRun = f => f, // default value of function
    run = undefined, // default value of undefined
    lines = 3,
    more = 'Read more',
    less = 'Show less'
  }) => {
    // const [redirect, setRedirect] = useState(false);
    // const [count, setCount] = useState(product.count);
    const [variationsArray, setVariations] = useState([]);
    const [ingredientsArray, setIngredients] = useState([]);
    const [currentStock, setCurrentStock] = useState({})
    const [currentVariation, setCurrentVariation] = useState([])
	const [options, setOptions] = useState([])
    const [value, setValue] = useState("?")


 
	const getVarNames = (variations) => {
		return variations.map((item, i) => {
			return {
				name: item.vname,
				value: i
			}
		})
    }


    useEffect(() => {
            // // console.log(`YO YO YO HERE'S THE productNew IN UPDATEPRODUCTCARD: `, productNew)
            // if(productNew) {
            //     setCurrentValues({
            //         ...currentValues,
            //         _id: productNew._id,
            //         name: productNew.name,
            //         description: productNew.description,            
            //         category: productNew.category,
            //         ingredients: productNew.ingredients,
            //         variations: productNew.variations,
            //         photo: productNew.photo,
            //         shipping: productNew.shipping,
            //     })
            // }

            
            // if(variations.length === 0) {
            //     let emptyVariations = []
            //     setVariations(emptyVariations)
            // }
            // if (variations.length > 0 ) {
            //     let v = [...variations]
            //     v.sort((a, b) => a.vprice - b.vprice)
            //     let preOptions = v.map((item, i) => {
            //         return {name: item.vname, value: item.number}
            //     })
            //     let newArray = [{
            //         name: 'Select…',
            //         value: null,
            //         }]
                
            //     setOptions(newArray.concat(preOptions))
            //     setCurrentStock(v[0].vquantity)
            //     setVariations(variations)
            //     setCurrentVariation(v[0])
            // }

            // // handle ingredients
            // setIngredients(ingredients)

        // console.log(`variations: `, variations);
        // console.log(`preOptions: `, preOptions)
        }, [])
  

    const showViewButton = showViewProductButton => {
        return (
            showViewProductButton && (
                <div>
                    <Link to={`#`} className="mr-2">
                    <button 
                    // className="cardbutton btn btn-outline-primary mt-2 mb-2"
                    >
                        View Product
                </button>
                </Link>
                    
                </div>
            )
        );
    };


 

    const handleVariationChange = e => {
        let value = e.target.value
        // console.log(`value: `, value)
        setValue(value)
		variationsArray.filter((item, i) => {
            // console.log(`item: `, item);
			if(item.vname === value){
                // console.log(`found a match!`, item);
                setCurrentVariation(item)
                setCurrentStock(item.vquantity)
			}
		})
        
    }

    const addVariations = () => {
        return (
				<> 
                <form>
                <label className="text-muted">{`Choose variation`}</label>
                    <select
                    onChange={handleVariationChange}
                    className="form-control"
                    value={value}
                    >
                        {options.map(item => (
				            <option key={item.value} value={item.name}>
				              {item.name}
				            </option>
							))}
                    </select>
                </form>
                
                </>
        )
        
    }

    const showPrice = (product) => {
        if(currentVariation) { return currentVariation.vprice }
        else { 
            // console.log(`product.variations[0].variationPrice: `, product.variations[0].vprice)
            return product.variations[0].vprice}
    }

    const showIngredients = () => {
        ingredientsArray && ingredientsArray.map((ing, i) => {
            return(
                <div>{ing}</div>
            )
        })
    }

    const showCurrentProduct = () => {
        if(currentProductValues === true) {
            return (
                <div className="card">
                    <div className="card-header name">{productNew.name}</div>
                    <div className="card-body">
                    {imageFile ? <><img 
            src={window.URL.createObjectURL(imageFile)} 
            className="img-fluid mb-3"
            style={{ maxHeight: "667px", maxWidth: "750px" }}
        />
        {console.log(`imageFile: `, imageFile)}
        </> : <><ShowImage 
                            item={productNew} 
                            url="product"
                            productId={productNew._id}
                        /></>}
                        
                        <div>
                        <p className="black-8">
                            Ingredients
                        </p>
                                {ingredients.map((item, i) => {
                                            return <>{item.ingredient}<br /> </> })}
                            </div>
                            
                            <p className="black-9">Description</p>
                            <div>{productNew.description}</div>
                        
                        {showViewButton(showViewProductButton)}
                        {hidePrice ? null : <p className="black-10">${defaultPricing ? showPrice(productNew) : `view product for details`}</p>}
                        <p className="black-9">
                            Category: {category._id ? categoryArray.filter((c, i) => {
                                if(c._id === category) {
                                    
                                    return <div>{c.name}</div>
                                }
                            }) : null}
                                        
                        </p>
                        <p className="black-8">
                            Added {moment(productNew.createdAt).fromNow()}
                        </p>
                        {/* {showStock(currentStock)} */}
                        <br />
                        {showVariations ? addVariations() : null}
                        <br />
                    </div>
                </div>
            )
        }
    
    }

    // prior_id,
    // priorName,
    // priorDescription,
    // priorCategory,
    // priorIngredients,
    // priorVariations,
    // priorPhoto,
    // priorShipping,
    const showPriorProduct = () => {
        if(priorProductValues === true) {
            return (
                <div className="card">
                    <div className="card-header name">{productPrior.priorName}</div>
                    <div className="card-body">
                        {/* {imageFile && showImageSignal ? <><img 
            src={imageFile} 
            className="img-thumbnail"
            style={{
                width: "200px"
            }}
        />
        </> : <ShowImage 
                            item={productPrior} 
                            url="product"
                            productId={productPrior.priorItemId}
                        />} */}
                        {showImageFunction()}
                        <p>
                        {updateProductPage === true && priorProductValues === true ? <>
                            <div>
                                {productPrior.priorIngredients ? productPrior.priorIngredients.map((item, i) => {
                                            return <>{item.ingredient}<br /> </> }) : null}
                            </div>
                            <hr/>
                            <div>{productPrior.priorDescription}</div>
                                    </> : null}
                        </p>
                        {showViewButton(showViewProductButton)}
                        <p className="black-9">
                            Category: {productPrior.priorCategory}
                        </p>
                        <p className="black-8">
                            Added on {moment(productPrior.priorCreatedAt).fromNow()}
                        </p>
                        {/* {showStock(currentStock)} */}
                        <br />
                        {showVariations ? addVariations() : null}
                        <br />
                    </div>
                </div>
            )
        }
    }

    return(
        <div>
            {showCurrentProduct()}
            {showPriorProduct()}
        </div>
    )

};

export default UpdateProductCard;