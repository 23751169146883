// import { API } from "../config";
var API = process.env.REACT_APP_API

export const getData = (userId, token) => {
    return fetch(`${API}/users/datatable`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const createItem = (token, item) => {
    return fetch(`${API}/users/datatable`, {
        method: "POST",
        body: JSON.stringify(item),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateItem = (token, item) => {
    return fetch(`${API}/users/update-datatable`, {
        method: "PUT",
        body: JSON.stringify(item),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const loadMore = (limit, page) => {
    return fetch(`http://localhost:8000/api/datatable/loadmore`, {
        method: "POST",
        body: JSON.stringify({
          limit,
          page}),
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            //   Authorization: `Bearer ${token}`
          }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    });
}