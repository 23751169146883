import React, {useState} from "react";
import Menu from "./Menu";
import "../styles.css";
import {withRouter} from 'react-router'
import { signout, isAuthenticated } from "../api/auth"; 


const Layout = ({
    title = "Title",
    description = "Description",
    className,
    children
}) => {
    
const [redirect, setRedirect] = useState(false);

    return(
    <div>
        
        <div 
            className="jumbotron"
            >
            <div className=''>
                <div 
                    style={{
                    height: "200px"
                    }}
                    className="row"
                    >
                        <a 
                                    className="mx-auto d-block"
                                    href="/" 
                                    alt="G&K Apparel Print Logo"
                                    >
                            <div className=" logo1"
                            style={{
                                height: "200px"
                            }}>
                        </div>
                        </a>
                </div>
            </div>
            

        </div><header
          style={{
            gridColumn: "container-start / container-end",
            background:
              "linear-gradient(var(--color-primary-m-light), var(--color-primary-m-light)), url('./core/Assets/hero.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          id="header"
          >
          {/* <!-- Navigation --> */}
          <nav
            className="header__main-nav"
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              zIndex: "6",
            }}
          >
            <input
              type="checkbox"
              style={{
                position: "absolute",
                top: "3rem",
                left: "3rem",
                height: "5rem",
                width: "5rem",
                opacity: "0",
                cursor: "pointer",
                zIndex: "3",
              }}
            />
            <div
              className="header__main-nav--hamburger"
              style={{
                position: "absolute",
                top: "3rem",
                left: "3rem",
                height: "5rem",
                width: "5rem",
                padding: "1rem",
                backgroundColor: "var(--color-secondary)",
                zIndex: "2",

                /* display: flex;
            justify-content: center;
            align-items: center; */

                display: "grid",
                placeItems: "center",

                animation: "main-nav 2s",
              }}
            >
              <div
                style={{
                  position: "relative",
                  height: "0.3rem",
                  width: "100%",
                  backgroundColor: "var(--color-quaternary)",

                  display: "grid",
                  placeItems: "center",
                  transition: "all var(--transition-duration) ease-in-out",
                }}
              ></div>
            </div>
            <div className="header__main-nav--menu">
              <div>
                <div>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    {!isAuthenticated() && (
                      <>
                        <li>
                          <a href="/signin">Login</a>
                        </li>
                        <li>
                          <a href="/signup">Register</a>
                        </li>
                      </>
                    )}

                    <li>
                      <a href="/shopnow">Shop Now</a>
                    </li>
                    <li>
                      <a href="/gallery">Gallery</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                    {isAuthenticated() && (
                      <li >
                        <a href="#"
                          className="nav-link"
                          style={{
                            cursor: "pointer",
                            color: "#ffffff",
                            width: "200px",
                          }}
                          onClick={() => {
                            signout(() => {
                              setRedirect(true);
                            });
                          }}
                        >
                          Signout
                        </a>
                      </li>
                    )}
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          {/* <!-- Logo and Content Container --> */}

        </header>
        
        <div className={className}>{children}</div>
    </div>
)};

export default Layout;

