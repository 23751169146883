import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import Card from "./Card";
import { getCategories, getFilteredProducts } from "./apiCore";
import Checkbox from "./Checkbox";
import RadioBox from "./RadioBox";
import { prices } from "./fixedPrices";
import "../styles.css";
import MediaQuery from "react-responsive";
import img1 from "./Assets/pics/shopNow/01.jpeg";
import img2 from "./Assets/pics/shopNow/02.jpeg";
import img3 from "./Assets/pics/shopNow/03.jpeg";
import img4 from "./Assets/pics/shopNow/04.jpeg";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.css';
// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/scrollbar/scrollbar.scss";

import 'swiper/swiper-bundle.css'

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCube]);

const ShopNow = () => {
  const [myFilters, setMyFilters] = useState({
    filters: { category: [], price: [] },
  });
  const [categories, setCategories] = useState([]);

  // const [ingredientsArray, setIngredients] = useState([{ingredient: 'Lemon'}, {ingredient: 'Orange'}]);
  const [error, setError] = useState(false);
  const [limit, setLimit] = useState(6);
  const [skip, setSkip] = useState(0);
  const [size, setSize] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);

  const init = () => {
    getCategories().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setCategories(data);
      }
    });
  };

  const loadFilteredResults = (newFilters) => {
    // console.log(newFilters);
    getFilteredProducts(skip, limit, newFilters).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setFilteredResults(data.data);
        setSize(data.size);
        setSkip(0);
      }
    });
  };

  const loadMore = () => {
    let toSkip = skip + limit;
    // console.log(newFilters);
    getFilteredProducts(toSkip, limit, myFilters.filters).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setFilteredResults([...filteredResults, ...data.data]);
        setSize(data.size);
        setSkip(toSkip);
      }
    });
  };

  const loadMoreButton = () => {
    return (
      size > 0 &&
      size >= limit && (
        <button onClick={loadMore} className="btn btn-warning mb-5">
          Load more
        </button>
      )
    );
  };

  //   useEffect(() => {
  //     init();
  //     loadFilteredResults(skip, limit, myFilters.filters);
  //   }, []);

  const handleFilters = (filters, filterBy) => {
    // console.log("SHOP", filters, filterBy);
    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;

    if (filterBy === "price") {
      let priceValues = handlePrice(filters);
      newFilters.filters[filterBy] = priceValues;
    }
    loadFilteredResults(myFilters.filters);
    setMyFilters(newFilters);
  };

  const handlePrice = (value) => {
    const data = prices;
    let array = [];

    for (let key in data) {
      if (data[key]._id === parseInt(value)) {
        array = data[key].array;
      }
    }
    return array;
  };

  const nextSwiper = () => {
    return (
      <>
        <Swiper
          style={{
            width: "300px",
            height: "300px",
            left: "50%",
            top: "50%",
            margin: 0,
            padding: 0,
            backgroundColor: "#fff",
            position: "absolute",
            marginLeft: "-150px",
            marginTop: "-150px",
          }}
          slidesPerView={1}
          navigation
          effect={"cube"}
          grabCursor={true}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide
            style={{ backgroundPosition: "center", backgroundSize: "cover" }}
          >
            <div style={{position: "relative"}}>
            <img style={{ height: "300px", width: "300px" }} src={img1} />
            <p style={{position: 'absolute'}}>T-shirts</p>
            </div>
          </SwiperSlide>
          <SwiperSlide
            style={{ backgroundPosition: "center", backgroundSize: "cover" }}
          >
            <img style={{ height: "300px", width: "300px" }} src={img2} />
          </SwiperSlide>
          <SwiperSlide
            style={{ backgroundPosition: "center", backgroundSize: "cover" }}
          >
            <img style={{ height: "300px", width: "300px" }} src={`${img3}`} />
          </SwiperSlide>
          <SwiperSlide
            style={{ backgroundPosition: "center", backgroundSize: "cover" }}
          >
            <img style={{ height: "300px", width: "300px" }} src={img4} />
          </SwiperSlide>
        </Swiper>
      </>
    );
  };

  return (
    <>
      <Layout
        title="ShopNow Page"
        description="Search and find items of your choice"
        className="container-fluid"
      >
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div
              className="col-5 py-5"
              style={{ height: "500px", backgroundColor: "" }}
            >
              <h1>Shop Now</h1>
              <br />
              <h4>Men</h4>
              {nextSwiper()}
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row">
            <div className="col-4"></div>
            <div
              className="col-5 py-5"
              style={{ height: "500px", backgroundColor: "" }}
            >
              <br />
              <h4>Women</h4>
              {nextSwiper()}
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row">
            <div className="col-4"></div>
            <div
              className="col-5 py-5"
              style={{ height: "500px", backgroundColor: "" }}
            >
              <br />
              <h4>Youth</h4>
              {nextSwiper()}
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row">
            <div className="col-4"></div>
            <div
              className="col-5 py-5"
              style={{ height: "500px", backgroundColor: "" }}
            >
              <br />
              <h4>Unisex</h4>
              {nextSwiper()}
            </div>
            <div className="col-4"></div>
          </div>


        </div>
      </Layout>
    </>
  );
};

export default ShopNow;
