import { 
    LOGGED_IN_USER,
} from "../constants/action-types"

const initialState = {
    userData: {},
};

const userReducer = (state = initialState, action) => {
    console.log(`L11/src/reducers/user.js "user" action: `, action)
    let { ebayToken } = state
    switch( action.type ) {
        case LOGGED_IN_USER:
            return{
                ...state,
                userData: action.payload
            }

            default:
                return state;
        }
    };


export default userReducer