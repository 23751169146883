import {
    createQuote,
    getQuotes,
    getQuote,
    changeQuote,
    removeQuote,
} from '../api'
import {
    signin,
    signup,
    signout,
    update,
    authenticate
} from '../api/auth'
import * as types from '../constants/action-types'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/* User Begin */

export const getUser = user => ({
    type: types.LOGGED_IN_USER,
    payload: user
})

export const loginUser = user => dispatch => {
    signin(user).then(userData => {
        dispatch( getUser( userData ))
        authenticate(userData, () => {})
        return userData
    })
}
/* User End */


// READ
// recieve quotes
export const receiveQuotes = quotes => ( {
    type: types.RECEIVE_QUOTES,
    payload: quotes
} );

export const getAllQuotes = () => dispatch => {
    getQuotes().then( quotes => {
        dispatch( receiveQuotes( quotes ) );
        return quotes;
    } )
}
// recieve quote
export const receiveQuote = quote => ( {
    type: types.SINGLE_QUOTE,
    payload: quote
} );

export const getSingleQuote = (_id) => dispatch => {
    getQuote(_id).then( quote => {        
        dispatch( receiveQuote( quote ) );
        return quote;
    } )
}


// CREATE
// create quote
export const addQuote = quotes => ( {
    type: types.CREATE_QUOTE,
    payload: quotes
} );

export const addNewQuote = (token, userId, quote) => dispatch => {
    createQuote(token, userId, quote).then( quotes => {
        // console.log(`Line 41-src/actions/index.js "addQuote" quotes: `, quotes) // getting 43 quotes
        dispatch( addQuote( quotes ) );
        return quotes;
    } )
}




// DELETE
// delete quote
export const deleteQuote = quotes => ( {
    type: types.DELETE_QUOTE,
    payload: quotes
} );

export const removeOneQuote = (token, userId, quote) => dispatch => {
    removeQuote(token, userId, quote).then( quotes => {
        // console.log(`Line 55-src/actions/index.js "removeQuote" quotes: `, quotes) // getting 43 quotes
        dispatch( deleteQuote( quotes ) );
        return quotes;
    } )
}


// UPDATE
// update quote
export const updateQuote = quotes => ( {
    type: types.UPDATE_QUOTE,
    payload: quotes
} );

export const updateOneQuote = (token, userId, quoteId, updatedQuote) => dispatch => {
    changeQuote(token, userId, quoteId, updatedQuote).then( quotes => {
        // console.log(`Line 27-src/actions/index.js "getAllquotes" quotes: `, quotes) // getting 43 quotes
        dispatch( updateQuote( quotes ) );
        return quotes;
    } )
}
