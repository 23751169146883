import React, { Component } from 'react'
import Header from '../layouts/Header';
import Headertwo from '../layouts/Headertwo';
import Footer from '../layouts/Footer';
import Mainbanner from '../sections/homepage-one/Banner';
import BookingformGk from '../sections/homepage-one/BookingformGk';
import About from '../sections/homepage-one/About';
import Roomtype from '../sections/homepage-one/Roomtype';
import Clothingtype from '../sections/homepage-one/Clothingtype';
import Cta from '../sections/homepage-one/Cta';
import Roomslider from '../sections/homepage-one/Roomslider';
import Textblock from '../sections/homepage-one/Textblock';
import Testimonial from '../sections/homepage-one/Testimonials';
import Roomgallery from '../sections/homepage-one/Roomgallery';
import News from '../sections/homepage-one/News';


class Home extends Component {
  render() {
    return (
      <div>
        <Header/>
        {/*====== BANNER PART START ======*/}
        <Mainbanner />
        {/*====== BANNER PART ENDS ======*/}
        {/*====== BOOKING FORM START ======*/}
        <BookingformGk />
        {/*====== BOOKING FORM EN ======*/}
        {/*====== ABOUT PART START ======*/}
        <About />
        {/*====== ABOUT PART END ======*/}
        {/*====== ROOM TYPE START ======*/}
        {/* <Clothingtype /> */}
        {/*====== ROOM TYPE END ======*/}
        {/*====== CALL TO ACTION END ======*/}

        <Footer/>
      </div>
    );
  }
}
        // <Cta/>
        // {/*====== CALL TO ACTION END ======*/}
        // {/*====== ROOM SLIDER START ======*/}
        // <Roomslider/>
        // {/*====== ROOM SLIDER END ======*/}
        // {/*====== TEXT BLOCK START ======*/}
        // <Textblock/>
        // {/*====== TEXT BLOCK END ======*/}
        // {/*====== TESTIMONIAL SLIDER START ======*/}
        // <Testimonial/>
        // {/*====== TESTIMONIAL SLIDER END ======*/}
        // {/*====== ROOM Gallery CTA START ======*/}
        // <Roomgallery/> 
        // {/*====== ROOM Gallery CTA END ======*/}
        // {/*====== LATEST NEWS START ======*/}
        // <News/>
        // {/*====== LATEST NEWS END ======*/}
export default Home;
