import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../core/Layout";
import { signin, authenticate, isAuthenticated } from "../api/auth";
import { getUser, loginUser } from "../actions";
import store from '../store'
import Header from '../components/layouts/Header';

const Signin = (props) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
    // user: []
  });

  const { email, password, loading, error, redirectToReferrer } = values;
  const { user } = isAuthenticated();

  // const init = () => {
  //     const { user } = isAuthenticated();
  //     setValues({
  //         ...values,
  //         user: [user]
  //     })
  // }

  // useEffect(() => {
  //     init()
  // }, [])

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    // setValues({
    //     ...values,
    //     redirectToReferrer: true
    // });
    // props.loginUser({ email, password })

    signin({ email, password }).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        console.log(`data (is this user?): `, data)
        store.dispatch( props.getUser( data ))
        authenticate(data, () => {
          setValues({
            ...values,
            redirectToReferrer: true,
          });
        });
      }
    });
  };

  const signUpForm = () => (
    <>
      <div className="container">
        <form>
          <div className="row">
            <table>
              <thead>
                <tr>
                  <td><h1>Sign in...</h1></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pt-2">
                    <input
                    onChange={handleChange("email")}
                    type="email"
                    className="form-control p-2"
                    placeholder="Email"
                    value={email}
                    style={{width: "25rem"}}
                  />
                  </td>
                </tr>
                <tr>
                  <td className="pt-2">
                  <input
                    onChange={handleChange("password")}
                    type="password"
                    className="form-control p-2"
                    value={password}
                    placeholder="Password"
                    style={{width: "25rem"}}
                  />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="pt-2">
                    <button onClick={clickSubmit} className="btn btn-primary">
                      Submit
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          
        </form>
      </div>
    </>
  );

  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  const redirectUser = () => {
    if (redirectToReferrer) {
      if (user && user.role === 1) {
        return <Redirect to="/admin/dashboard" />;
      } else {
        return <Redirect to="/user/dashboard" />;
      }
    }
    if (isAuthenticated()) {
      return <Redirect to="/" />;
    }
  };

  return (
    <div className="container-fluid">
        <div className="row" style={{height: "220px"}}>
            <Header />
        </div>
        <div className="row">
        {showLoading()}
        {showError()}
        {signUpForm()}
        {redirectUser()}
        </div>
     
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.userInfo.userData ? state.userInfo.userData : {},
  };
};

export default connect(mapStateToProps, { getUser, loginUser })(Signin);
