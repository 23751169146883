import {
    CREATE_QUOTE,
    RECEIVE_QUOTES,
    SINGLE_QUOTE,
    UPDATE_QUOTE,
    DELETE_QUOTE
} from "../constants/action-types"

const initialState = {
    quotes: [],
    quote: {}
};

const quoteReducer = ( state = initialState, action ) => {
    console.log(`L15/src/reducers/quote.js "quoteReducer" action: `, action)
    switch ( action.type ) {
        case CREATE_QUOTE:
            return {
                ...state,
                quotes: action.payload
            };
        
        case RECEIVE_QUOTES:
            return {
                ...state,
                quotes: action.payload
            };
        
        case SINGLE_QUOTE:
            return {
                ...state,
                quote: action.payload
            };
        
        case UPDATE_QUOTE:
            return {
                ...state,
                quote: action.payload
            };
        
        case DELETE_QUOTE:
            return {
                ...state,
                quotes: action.payload
            };
        
        default:
            return state
        }
}

export default quoteReducer