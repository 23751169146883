import React from "react";
import Header from '../components/layouts/Header'
import { isAuthenticated } from "../api/auth";
import { Link } from "react-router-dom";
import AdminQuotes from '../quotes/AdminQuotes'
import FormOfDatatable from '../core/FormOfDatatable'

const AdminDashboard = () => {
    const {
        user: { _id, name, email, role }
    } = isAuthenticated();

    const adminLinks = () => {
        return (
            <div className="card">
                <h4 className="card-header">Admin Links</h4>
                <ul className="list-group">
                    <li className="list-group-item">
                        <Link className="nav-link" to="#">
                            <span style={{color: "black"}}>Manage Users</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/profile/${_id}`}>
                            <span style={{color: "black"}}>Update Profile</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/user/dashboard`}>
                            <span style={{color: "black"}}>View User Dashboard</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/user/datatables`}>
                            <span style={{color: "black"}}>User View Quotes Page</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/admin/managegalleries`}>
                            <span style={{color: "black"}}>Manage Galleries</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/admin/subscriptions`}>
                            <span style={{color: "black"}}>Manage Subscriptions</span>
                        </Link>
                    </li>
                </ul>
            </div>
        );
    };

    const adminInfo = () => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">User Information</h3>
                <ul className="list-group">
                    <li style={{color: "black"}} className="list-group-item">{name}</li>
                    <li style={{color: "black"}} className="list-group-item">{email}</li>
                    <li style={{color: "black"}} className="list-group-item">
                        {role === 1 ? "Admin" : "Registered User"}
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div
            className="container-fluid"
        >
            <div className="row" style={{height: "220px"}}>
                <Header />
            </div>
            <div className="row">
                <div className="col-3">{adminLinks()}</div>
                <div className="col-9">{adminInfo()}
                    
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-3 col-lg-2">                    
                {/* <h4 className="pl-5">Datatable Form</h4> */}
                {/* <FormOfDatatable /> */}
                </div>
                <div className="col-sm-9 col-lg-10">
                    <AdminQuotes />
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
