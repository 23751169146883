import React, { useState, useEffect } from 'react';
import { isAuthenticated } from "../api/auth";
import {
    Col, 
    Row, 
    Card, 
    CardBody,
    CardTitle, 
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Container,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter
} from 'reactstrap'

const SubscriptionModal = ({braintreeSubscriptions, user, token }) => {
    const [modal, setModal] = useState(false)
    // const { user, token } = isAuthenticated()
    const toggle = () => setModal(!modal)
    // const [values, setValues] = useState({
    //     title: '',
    //     details: '',
    //     history: [],
    //     error: '',
    //     success: '',
    //     slug: '',
    //     formData: ''
    // });

    // const { 
    //     title,
    //     details,
    //     history,
    //     error, 
    //     success,
    //     slug, 
    //     formData 
    // } = values;


    useEffect(() => {
    }, []);

    const planIdResolver = (p) => {
        if(p === "gb66") { 
            return(<div>
            <p><strong>WEEKLY OPTION<br/>
            9 JUICES OR 6 JUICES AND 3 SMOOTHIES</strong></p>
        </div>)
        }
        if(p === "8bz2") { 
            return(
                <>
                <p><strong>WEEKLY OPTION<br/>
                -2 OUNCES (OR) 10 TEA BAGS OF YOUR CHOICE OF "SIMPLISEDE" HERBAL TEA BLENDS AND -7 JUICES/SMOOTHIES (MIX & MATCH)</strong></p>
                </>
            )
        }
        if(p === "p9cb") { 
            return(
                <>
                <p><strong>WEEKLY OPTION<br />
                2 OUNCES (OR) 10 TEA BAGS OF YOUR CHOICE OF "SIMPLISEDE" HERBAL TEA BLENDS AND 7 JUICES/SMOOTHIES (MIX & MATCH)</strong></p></>
            )
        }
        if(p === "5cdg") { 
            return(
                <>
                <p><strong>BI-WEEKLY OPTION<br />
                4 OUNCES (OR) 15 TEA BAGS OF YOUR CHOICE OF "SIMPLISEDE" HERBAL TEA BLENDS AND 12 JUICES/SMOOTHIES (MIX & MATCH)</strong></p>
                </>
            )
        }
     
    }

    const showModalContent = () => {
        return(            
            <>
            {(braintreeSubscriptions.length > 0) ? braintreeSubscriptions.map((item, i) => {
                let {
                    id,
                    firstBillingDate,
                    planId,
                    price,
                    status,
                    nextBillingDate
                } = item.subscription
              
                if(item.subscriptionItems && item.subscriptionItems.length != 0) {
                    let subscriptionItems = item.subscriptionItems
                    console.log(`subscriptionItems: `, subscriptionItems)
                    return(
                        <>
                        
                        <h4 >Subscription {`${i+1}`}</h4>
                        {status === 'Active' ? <div className="alert alert-success">{status}</div> 
                    : <div className="alert alert-danger">{status}</div>}
                        <div className="row">
                            <Col md={9}>{planIdResolver(planId)}</Col>
                            {/* <Col md={1} sm={12}>{(status === 'Active') ? showCancel(id) : <a href="/membership">Resubscribe</a>}</Col> */}
                        </div>
                        <div className="row">
                            <div 
                            className="col-6"
                            style={{
                                borderStyle: "dotted"
                            }}
                            >
                                <h5>Subscription Items</h5>
                                
                                {subscriptionItems.map((item, i) => {
                                    return <p>{`${i + 1}. ${item}`}</p>
                                })}
                                
                            </div>
                            <div className="col-4">
                                <p>Price: {price}
                                <br />
                                First Billing Date: {firstBillingDate}<br />
                                Next Billing Date: {nextBillingDate}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div 
                            style={{
                                minHeight: "40px"
                            }}></div>
                        </div>
                        </>
                        )
                } else {
                    console.log(`item: `, item)
                    return(
                        <>
                        <h4>Subscription {`${i+1}`}</h4>
                        {status === 'Active' ? <div className="alert alert-success">{status}</div> 
                    : <div className="alert alert-danger">{status}</div>}
                        <div className="row">
                            <Col md={9}>{planIdResolver(planId)}</Col>
                            {/* <Col md={1} sm={12}>{(status === 'Active') ? showCancel(id) : <a href="/membership">Resubscribe</a>}</Col> */}
                        </div>
                        <div className="row">
                            <div className="col-4">
                                {/* <label>Subscription Items</label>
                                {subscriptionItems.map((item, i) => {
                                    <p>{item}</p>
                                })} */}
                            </div>
                            <div className="col-4">
                                <p>Price: {price}
                                <br />
                                First Billing Date: {firstBillingDate}<br />
                                Next Billing Date: {nextBillingDate}
                                </p>
                            </div>
                        </div>
                        </>
                        )
                }
                
            }).reverse() : <p>{`No current subscriptions.`}</p>}
            </>
        )
    }


    return (
        <div>
      <Button size="xl" color="secondary" onClick={toggle}>{`View purchases`}</Button>
      <Modal isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
        <ModalHeader toggle={toggle}>Subscription</ModalHeader>
        <ModalBody>
            {showModalContent()}   
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </div>
       
    );
};

export default SubscriptionModal