const API = process.env.REACT_APP_API

export const getCategories = async (setCategories, skip, limit) => {
    try {
        let _categories = await fetch(`${API}/productcategories/rootcategories`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({skip: skip, limit: limit})
        })
        _categories = await _categories.json()
        setCategories(_categories)
    } catch (e) { console.log(`Got an error in getCategories...`, e) } 
}

export const persistSubcategoryChooseLeafs = async (code, setSubcategories2, setLeafs) => {
    console.log(`Made it to persistSubcategoryChooseLeafs!  Here's the category code...`, code)
    try {
        let leafs = await fetch(`${API}/productcategories/leafs/${code}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        leafs = await leafs.json()
        console.log(`leafs...`, leafs)
        let _subcategories = leafs[0].subcategories
        setSubcategories2(_subcategories)
        let _leafs = leafs[0].leafs
        console.log('inside getLeafsAndSubcategories, _leafs...', _leafs)
        setLeafs(_leafs)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const persistSubcategoryChooseLeafs2 = async (code, setSubcategories3, setLeafs) => {
    console.log(`Made it to persistSubcategoryChooseLeafs2!  Here's the category code...`, code)
    try {
        let leafs = await fetch(`${API}/productcategories/leafs/${code}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        leafs = await leafs.json()
        console.log(`leafs...`, leafs)
        let _subcategories = leafs[0].subcategories
        setSubcategories3(_subcategories)
        let _leafs = leafs[0].leafs
        console.log('inside getLeafsAndSubcategories, _leafs...', _leafs)
        setLeafs(_leafs)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const persistSubcategoryChooseLeafs3 = async (code, setSubcategories4, setLeafs) => {
    console.log(`Made it to persistSubcategoryChooseLeafs3!  Here's the category code...`, code)
    try {
        let leafs = await fetch(`${API}/productcategories/leafs/${code}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        leafs = await leafs.json()
        console.log(`leafs...`, leafs)
        let _subcategories = leafs[0].subcategories
        setSubcategories4(_subcategories)
        let _leafs = leafs[0].leafs
        console.log('inside getLeafsAndSubcategories, _leafs...', _leafs)
        setLeafs(_leafs)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const getLeafsAndSubcategories = async (code, setLeafsAndSubCategories, setLeafs, setSubcategories1) => {
    console.log(`Made it to getLeafsAndSubcategories!  Here's the category code...`, code)
    try {
        let leafs = await fetch(`${API}/productcategories/leafs/${code}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        leafs = await leafs.json()
        console.log(`leafs...`, leafs)
        // setLeafsAndSubCategories(leafs)
        let _leafs = leafs[0].leafs
        console.log('inside getLeafsAndSubcategories, _leafs...', _leafs)
        setLeafs(_leafs)
        let _subcategories = leafs[0].subcategories
        setSubcategories1(_subcategories)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const getInventoryItems = async (categoryId, setLeafResults) => {
    console.log(`Made it to getInventoryItems!  Here's the category categoryId...`, categoryId)
    try {
        let endpoint = `${API}/productcategories/${categoryId}`
        let headers = { Accept: "application/json", "Content-Type": "application/json"}
        let method = `POST`
        let inventoryItems = await fetch(endpoint, { method, headers })
        inventoryItems = await inventoryItems.json()
        console.log(`inventoryItems...`, inventoryItems)
        setLeafResults(inventoryItems)
    } catch (e) { console.log(`Got an error in getInventoryItems...`, e) }
}

export const getInitialInventory = async (setInitialInventory) => {
    console.log(`Made it to getInitialInventory!`)
    try {
        let endpoint = `${API}/product/getallitems`
        let headers = { Accept: "application/json", "Content-Type": "application/json"}
        let method = `POST`
        let inventoryItems = await fetch(endpoint, { method, headers })
        inventoryItems = await inventoryItems.json()
        console.log(`inventoryItems...`, inventoryItems)
        setInitialInventory(inventoryItems)
    } catch (e) { console.log(`Got an error in getInventoryItems...`, e) }
}

export const getBestSellingItems = async (categoryId, setLeafResults) => {
    console.log(`Made it to getBestSellingItems!  Here's the category categoryId...`, categoryId)
    try {
        let bestItems = await fetch(`${API}/bestsellingebay/${categoryId}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        bestItems = await bestItems.json()
        console.log(`bestItems...`, bestItems)
        setLeafResults(bestItems)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const getItemIdCheckItems = async (categoryId, setItemIdCheckResults) => {
    console.log(`Made it to getItemIdCheckItems!  Here's the category categoryId...`, categoryId)
    try {
        let itemIdCheckItems = await fetch(`${API}/itemidcheck/${categoryId}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        itemIdCheckItems = await itemIdCheckItems.json()
        console.log(`itemIdCheckItems...`, itemIdCheckItems)
        setItemIdCheckResults(itemIdCheckItems)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const getEbayCategoryScrapesItems = async (categoryId, setEbayCategoryScrapesResults) => {
    console.log(`Made it to getEbayCategoryScrapesItems!  Here's the category categoryId...`, categoryId)
    try {
        let ebayCategoryScrapesItems = await fetch(`${API}/ebaycategoryscrapes/${categoryId}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        ebayCategoryScrapesItems = await ebayCategoryScrapesItems.json()
        console.log(`ebayCategoryScrapesItems...`, ebayCategoryScrapesItems)
        setEbayCategoryScrapesResults(ebayCategoryScrapesItems)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}

export const getProductsItems = async (categoryId, setProductsResults) => {
    console.log(`Made it to getProductsItems!  Here's the category categoryId...`, categoryId)
    try {
        let productsItems = await fetch(`${API}/product/${categoryId}`, {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        productsItems = await productsItems.json()
        console.log(`productsItems...`, productsItems)
        setProductsResults(productsItems)
    } catch (e) { console.log(`Got an error in getCategoryLeafs...`, e) }
}


export const getWizardResults = async (url) => {
    console.log(`getWizardResults url... `, url)
    try {
        let scrapeUrl = `${API}/wizard/resultspage`
        let payload = JSON.stringify({url})
        let result = await fetch(`${scrapeUrl}`, {
            method: 'POST',
            body: payload,
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        result = await result.json()
        return result
    } catch (e) { console.log(`Got an error in getWizardResults...`, e) }
}

export const getWizardResults1 = async (url) => {
    console.log(`getAmazonItem url: `, url)
    try {
        let scrapeUrl = `${API}/wizard/resultspage1`
        let payload = JSON.stringify({url})
        let result = await fetch(`${scrapeUrl}`, {
            method: 'POST',
            body: payload,
            headers: { Accept: "application/json", "Content-Type": "application/json" }
        })
        result = await result.json()
        return result
    } catch (e) { console.log(`Got an error in getWizardResults1... `, e) }
}
